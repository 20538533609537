import { post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenDoor/list`, {}, { ...data });
  },
  getCombOpenList: () => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenPerson/getCombOpenList`, {});
  },

  selectDoorlist: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenDoor/selectDoorlist`, data, {});
  },

  validBackgroundVerify: (data: string) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/accCombOpenDoor/validBackgroundVerify?doorId=${data}`,
      {},
    ),

  checkPersonCount: (data: string) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/accCombOpenPerson/checkPersonCount?groupId=${data}`,
      {},
    ),

  saveMultiPersonOpeningDoor: (data: any, params: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accCombOpenDoor/save`,
      { ...params },
      data,
    );
  },

  deleteMultiPerson: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenDoor/delete?ids=${id}`, {});
  },

  editMultiPerson: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenDoor/edit`, {}, data);
  },
};
