import React from 'react';

// Use common modules
import { Navigate, Route, Routes } from 'react-router-dom';

// Use Pages
import Layout from '../layout';
import Login from '../login';
import ForgotPassword from '../forgotpassword';
import Department from '../department';
import Dashboard from '../dashboard';
import Position from '../position';
import DismissedPersonnel from '../dismissed-personnel';
import PendingReview from '../pending-review';
import CustomAttributes from '../custom-attributes';
import ListLibrary from '../list-library';
import Parameters from '../parameters';
import CardManagement from '../card-management';
import WiegandFormat from '../wiegand-format';
import IssueCardRecord from '../issue-card-record';
import Person from '../person';
import AddPerson from '../person/components/add-person';
import AuthGuard from './auth-guard';
import EditPerson from '../person/components/edit-person';
import Device from '../devices';
import IOBoard from '../ioboard';
import Reader from '../reader';
import Door from '../door';
import AuxiliaryInput from '../auxiliary-input';
import AuxiliaryOutput from '../auxiliary-output';
import RealTimeMonitoring from '../realTime-monitoring';
import DayLightSavingTime from '../daylight-saving-time';
import EvenType from '../event-type';
import AlarmMonitoring from '../alarm-monitoring';
import AccessLevels from '../access-levels';
import Map from '../map';
import Holidays from '../holidays';
import SetAccessByPerson from '../set-access-by-person';
import SetAccessByLevels from '../access-by-levels';
import TimeZones from '../timeZones';
import LinkageOutputPoint from '../linkage';
import Interlock from '../interlock';
import SetAccessByDepartment from '../access-by-department';
import AntiPassBack from '../anti-passback';
import FirstPersonNormallyOpen from '../first-person-normally-open';
import MultiPersonGroup from '../multi-person-group';
import AccRuleParameters from '../acc-rule-parameters';
import MultiPersonOpeningDoor from '../multi-person-openning-door';
import VerificationModeGroup from '../verification-mode-group';
import VerificationMode from '../verification-mode';
import ReaderDefine from '../advanced-functions/reader-define';
import AreaHeadCount from '../advanced-functions/areaHeadCount';
import GlobalAnitPassback from '../advanced-functions/globalAntiPassback';
import ZoneList from '../advanced-functions/zone';
import GlobalInterlock from '../advanced-functions/globalInterlock';
import OccupancyControl from '../advanced-functions/occupancyControl';
import GlobalLinkageOutputPoint from '../advanced-functions/globalLinkage';
import GlobalInterlockGroup from '../advanced-functions/globalInterlockGroup';
import PersonAvailability from '../advanced-functions/personAvailability';
import MusterPointReport from '../advanced-functions/musterPointReport';
import MusterPoint from '../advanced-functions/musterPoint';
import AllTransactions from '../accessControlReports/allTransactions';
import EventsFromToday from '../accessControlReports/eventsFromToday';
import AllExceptionEvents from '../accessControlReports/allExceptionEvents';
import AlarmLog from '../accessControlReports/alarmLog';
import AlarmProcessingHistory from '../accessControlReports/alarmProcessingHistory';
import FirstInLastOut from '../accessControlReports/firstInLastOut';
import AccessRightsByPersonnel from '../accessControlReports/accessRightsByPersonnel';
import AccessRightsByDoor from '../accessControlReports/accessRightsByDoor';
import OperationLog from '../system/system-management/operation-log';
import DatabaseManagement from '../system/system-management/database-management';
import SystemParameter from '../system/system-management/system-Parameter';
import EmailManagement from '../system/system-management/email-management';
import ResourceFile from '../system/system-management/resource-file';
import CloudSettings from '../system/system-management/cloud-settings';
import DictionaryManagement from '../system/system-management/directory-management';
import CertificateType from '../system/system-management/certificate-type';
import AreaSettings from '../system/system-management/area-settings';
import DataCleaning from '../system/system-management/data-cleaning';

function Router() {
  const routes = [
    {
      path: '',
      children: [
        {
          id: 1,
          path: '',
          element: <Navigate to="dashboard" />,
        },
      ],
    },
    {
      path: 'dashboard',
      children: [
        {
          id: 1,
          path: '',
          element: <Dashboard />,
        },
      ],
    },
    {
      path: 'personnel',
      children: [
        {
          id: 1,
          path: '',
          element: <Navigate to="personnel/person" />,
        },
        {
          id: 2,
          path: 'personnel/person',
          element: <Person />,
        },
        {
          id: 3,
          path: 'personnel/person/add',
          element: <AddPerson />,
        },
        {
          id: 4,
          path: 'personnel/person/edit/:id',
          element: <EditPerson />,
        },
        {
          id: 5,
          path: 'personnel/department',
          element: <Department />,
        },
        {
          id: 6,
          path: 'personnel/position',
          element: <Position />,
        },
        {
          id: 7,
          path: 'personnel/dismissed-personnel',
          element: <DismissedPersonnel />,
        },
        {
          id: 8,
          path: 'personnel/pending-review',
          element: <PendingReview />,
        },
        {
          id: 9,
          path: 'personnel/custom-attributes',
          element: <CustomAttributes />,
        },
        {
          id: 10,
          path: 'personnel/list-library',
          element: <ListLibrary />,
        },
        {
          id: 11,
          path: 'personnel/parameters',
          element: <Parameters />,
        },
        {
          id: 12,
          path: 'card-management/card',
          element: <CardManagement />,
        },
        {
          id: 13,
          path: 'card-management/wiegand-format',
          element: <WiegandFormat />,
        },
        {
          id: 14,
          path: 'card-management/issued-card-record',
          element: <IssueCardRecord />,
        },
      ],
    },
    {
      path: 'acc',
      children: [
        {
          id: 1,
          path: '',
          element: <Navigate to="acc/device" />,
        },
        {
          id: 2,
          path: 'accessDevice/device',
          element: <Device />,
        },
        {
          id: 3,
          path: 'accessDevice/iOBoard',
          element: <IOBoard />,
        },
        {
          id: 4,
          path: 'accessDevice/door',
          element: <Door />,
        },
        {
          id: 5,
          path: 'accessDevice/reader',
          element: <Reader />,
        },
        {
          id: 6,
          path: 'accessDevice/auxiliaryinput',
          element: <AuxiliaryInput />,
        },
        {
          id: 7,
          path: 'accessDevice/auxiliaryoutput',
          element: <AuxiliaryOutput />,
        },
        {
          id: 8,
          path: 'accessDevice/eventType',
          element: <EvenType />,
        },
        {
          id: 9,
          path: 'accessDevice/daylightsavingtime',
          element: <DayLightSavingTime />,
        },
        {
          id: 10,
          path: 'accessDevice/realtime-monitoring',
          element: <RealTimeMonitoring />,
        },
        {
          id: 11,
          path: 'accessDevice/alarm-monitoring',
          element: <AlarmMonitoring />,
        },
        {
          id: 12,
          path: 'accessDevice/map',
          element: <Map />,
        },
        {
          id: 13,
          path: 'accessRule/timezones',
          element: <TimeZones />,
        },
        {
          id: 14,
          path: 'accessRule/holidays',
          element: <Holidays />,
        },
        {
          id: 15,
          path: 'accessRule/accessLevels',
          element: <AccessLevels />,
        },
        {
          id: 16,
          path: 'accessRule/setaccessByLevels',
          element: <SetAccessByLevels />,
        },
        {
          id: 17,
          path: 'accessRule/setAccessByPerson',
          element: <SetAccessByPerson />,
        },
        {
          id: 18,
          path: 'accessRule/setAccessByDepartment',
          element: <SetAccessByDepartment />,
        },
        {
          id: 19,
          path: 'accessRule/interlock',
          element: <Interlock />,
        },
        {
          id: 20,
          path: 'accessRule/linkage',
          element: <LinkageOutputPoint />,
        },
        {
          id: 21,
          path: 'accessRule/antiPassback',
          element: <AntiPassBack />,
        },
        {
          id: 22,
          path: 'accessRule/firstPersonNormallyOpen',
          element: <FirstPersonNormallyOpen />,
        },
        {
          id: 23,
          path: 'accessRule/multi-person-group',
          element: <MultiPersonGroup />,
        },
        {
          id: 24,
          path: 'accessRule/multi-person-opening-door',
          element: <MultiPersonOpeningDoor />,
        },
        {
          id: 25,
          path: 'accessRule/verification-mode',
          element: <VerificationMode />,
        },
        {
          id: 26,
          path: 'accessRule/verification-mode-group',
          element: <VerificationModeGroup />,
        },
        {
          id: 27,
          path: 'accessRule/parameters',
          element: <AccRuleParameters />,
        },
        {
          id: 28,
          path: 'advancedFunctions/zone',
          element: <ZoneList />,
        },
        {
          id: 29,
          path: 'advancedFunctions/reader-define',
          element: <ReaderDefine />,
        },
        {
          id: 30,
          path: 'advancedFunctions/areaHeadCount',
          element: <AreaHeadCount />,
        },
        {
          id: 31,
          path: 'advancedFunctions/globalAntiPassback',
          element: <GlobalAnitPassback />,
        },
        {
          id: 32,
          path: 'advancedFunctions/globalLinkage',
          element: <GlobalLinkageOutputPoint />,
        },
        {
          id: 33,
          path: 'advancedFunctions/globalInterlockGroup',
          element: <GlobalInterlockGroup />,
        },
        {
          id: 34,
          path: 'advancedFunctions/globalInterlock',
          element: <GlobalInterlock />,
        },
        {
          id: 35,
          path: 'advancedFunctions/person-Availability',
          element: <PersonAvailability />,
        },
        {
          id: 36,
          path: 'advancedFunctions/occupancyControl',
          element: <OccupancyControl />,
        },
        {
          id: 37,
          path: 'advancedFunctions/musterPoint',
          element: <MusterPoint />,
        },
        {
          id: 38,
          path: 'advancedFunctions/musterPoint-report',
          element: <MusterPointReport />,
        },
        {
          id: 39,
          path: 'accControlReports/allTransactions',
          element: <AllTransactions />,
        },
        {
          id: 40,
          path: 'accControlReports/events-from-today',
          element: <EventsFromToday />,
        },
        {
          id: 41,
          path: 'accControlReports/all-exception-events',
          element: <AllExceptionEvents />,
        },
        {
          id: 42,
          path: 'accControlReports/alarm-log',
          element: <AlarmLog />,
        },
        {
          id: 43,
          path: 'accControlReports/alarm-processing-history',
          element: <AlarmProcessingHistory />,
        },
        {
          id: 44,
          path: 'accControlReports/access-rights-by-door',
          element: <AccessRightsByDoor />,
        },
        {
          id: 45,
          path: 'accControlReports/access-rights-by-personnel',
          element: <AccessRightsByPersonnel />,
        },
        {
          id: 46,
          path: 'accControlReports/firstIn-and-lastOut',
          element: <FirstInLastOut />,
        },
      ],
    },
    {
      path: 'system',
      children: [
        {
          id: 1,
          path: '',
          element: <Navigate to="system/system-management" />,
        },
        {
          id: 2,
          path: 'system-management/operation-log',
          element: <OperationLog />,
        },
        {
          id: 3,
          path: 'system-management/database-management',
          element: <DatabaseManagement />,
        },
        {
          id: 4,
          path: 'system-management/area-settings',
          element: <AreaSettings />,
        },
        {
          id: 5,
          path: 'system-management/system-Parameter',
          element: <SystemParameter />,
        },
        {
          id: 6,
          path: 'system-management/email-management',
          element: <EmailManagement />,
        },
        {
          id: 7,
          path: 'system-management/dictionary-management',
          element: <DictionaryManagement />,
        },
        {
          id: 8,
          path: 'system-management/data-cleaning',
          element: <DataCleaning />,
        },
        {
          id: 9,
          path: 'system-management/resource-file',
          element: <ResourceFile />,
        },
        {
          id: 10,
          path: 'system-management/cloud-settings',
          element: <CloudSettings onChangeFunction={() => {}} values={{}} />,
        },
        {
          id: 11,
          path: 'system-management/certificate-type',
          element: <CertificateType />,
        },
      ],
    },
  ];

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/admin" element={<Layout />}>
        {routes.map((route) => (
          <Route key={route.path} path={route.path}>
            {route.children.map((subRoute) => (
              <Route
                key={subRoute?.id}
                path={subRoute?.path}
                element={<AuthGuard Component={subRoute?.element} />}
              />
            ))}
          </Route>
        ))}
      </Route>
    </Routes>
  );
}

export default Router;
