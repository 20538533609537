import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ParameterInterface,
  ParameterReduxInterface,
} from '../../../../../interface/parameter.interface';
import { useAppSelector } from '../../../../../redux/store';
import SelectBoxField from '../../../../../shared-components/selectbox-field/selectbox-field';
import { AttentionIcon } from '../../../../../assets/svgicons/svgicon';

function Records({ onChangeFunction }: ParameterInterface) {
  const { t } = useTranslation();
  const { parameters }: ParameterReduxInterface = useAppSelector((state: any) => state.parameters);
  const [localValues, setLocalValues] = useState({
    access: {
      number: '1',
      period: 'day',
      startTime: '00:00:00',
    },
    attendance: {
      number: '1',
      period: 'day',
      startTime: '00:00:00',
    },
    elevator: {
      number: '1',
      period: 'day',
      startTime: '00:00:00',
    },
    visitor: {
      number: '1',
      period: 'day',
      startTime: '00:00:00',
    },
    parking: {
      number: '1',
      period: 'day',
      startTime: '00:00:00',
    },
    ...parameters,
  });

  const onChange = (section: string, field: string, changeValue: string | number) => {
    setLocalValues((prev: any) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: changeValue,
      },
    }));
  };

  const executionTimeOptions = [
    { label: '00:00:00', id: '00:00:00' },
    { label: '01:00:00', id: '01:00:00' },
    { label: '02:00:00', id: '02:00:00' },
    { label: '03:00:00', id: '03:00:00' },
  ];

  const sections = [
    {
      title: 'Access Transactions',
      key: 'access',
      retentionName: 'accReportDataCleanKeptMonth',
      periodName: 'accReportDataCleanKeptType',
      timeName: 'accReportDataCleanTime',
    },
    {
      title: 'Attendance Transactions',
      key: 'attendance',
      retentionName: 'attReportDataCleanKeptMonth',
      periodName: 'attReportDataCleanKeptType',
      timeName: 'attReportDataCleanTime',
    },
    {
      title: 'Elevator Transactions',
      key: 'elevator',
      retentionName: 'eleReportDataCleanKeptMonth',
      periodName: 'eleReportDataCleanKeptType',
      timeName: 'eleReportDataCleanTime',
    },
    {
      title: 'Visitor Transactions',
      key: 'visitor',
      retentionName: 'visReportDataCleanKeptMonth',
      periodName: 'visReportDataCleanKeptType',
      timeName: 'visReportDataCleanTime',
    },
    {
      title: 'Parking Transactions',
      key: 'parking',
      retentionName: 'parkReportDataCleanKeptMonth',
      periodName: 'parkReportDataCleanKeptType',
      timeName: 'parkReportDataCleanTime',
    },
  ];

  const retentionOptions = [
    { label: t('1'), id: '1' },
    { label: t('2'), id: '2' },
    { label: t('3'), id: '3' },
  ];

  const periodOptions = [
    { label: t('day'), id: 'day' },
    { label: t('week'), id: 'week' },
    { label: t('month'), id: 'month' },
  ];
  useEffect(() => {
    const transformedValues = sections.reduce((acc, section) => {
      return {
        ...acc,
        [section.retentionName]: localValues?.[section.key]?.number || '1',
        [section.periodName]: localValues?.[section.key]?.period || 'day',
        [section.timeName]: localValues?.[section.key]?.startTime || '00:00:00',
      };
    }, {});

    onChangeFunction(transformedValues);
  }, [localValues, onChangeFunction]);

  return (
    <div className="data-setting">
      <div className="data-setting-form">
        <div className="column">
          {sections.slice(0, 3).map((section) => (
            <div key={section.key} className="item item-1">
              <div className="record-title">{t(section.title)}</div>
              <div className="sub-title">{t('Retains the Recent')}</div>
              <div className="main-container">
                <SelectBoxField
                  id={section.retentionName}
                  name={section.retentionName}
                  value={localValues?.[section.key]?.number}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, 'number', selectBoxValue.id)
                  }
                  data={retentionOptions}
                />
                <SelectBoxField
                  id={section.periodName}
                  name={section.periodName}
                  value={localValues?.[section.key]?.period}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, 'period', selectBoxValue.id)
                  }
                  data={periodOptions}
                />
              </div>
              <div className="form-start">
                <div className="sub-title">{t('Execution Time')}</div>
                <SelectBoxField
                  id={section.timeName}
                  name={section.timeName}
                  value={localValues?.[section.key]?.startTime}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, 'startTime', selectBoxValue.id)
                  }
                  data={executionTimeOptions}
                />
                <div className="item text-danger">
                  <div className="note">(Carefully Cleanup)</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="column">
          {sections.slice(3).map((section) => (
            <div key={section.key} className="item item-1">
              <div className="record-title">{t(section.title)}</div>
              <div className="sub-title">{t('Retains the Recent')}</div>
              <div className="main-container">
                <SelectBoxField
                  id={section.retentionName}
                  name={section.retentionName}
                  value={localValues?.[section.key]?.number}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, 'number', selectBoxValue.id)
                  }
                  data={retentionOptions}
                />
                <SelectBoxField
                  id={section.periodName}
                  name={section.periodName}
                  value={localValues?.[section.key]?.period}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, 'period', selectBoxValue.id)
                  }
                  data={periodOptions}
                />
              </div>
              <div className="form-start">
                <div className="sub-title">{t('Execution Time')}</div>
                <SelectBoxField
                  id={section.timeName}
                  name={section.timeName}
                  value={localValues?.[section.key]?.startTime}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, 'startTime', selectBoxValue.id)
                  }
                  data={executionTimeOptions}
                />
                <div className="item text-danger">
                  <div className="note">(Carefully Cleanup)</div>
                </div>
              </div>
            </div>
          ))}
          <div className="text-danger flex-row center">
            <AttentionIcon />
            {t(
              'Cleaning frequency is executed once every day and clean up data before the set number of reserved months. Execution Time refers to the time when the system starts to perform a data clean-up. When you click OK, the system will automatically clean expired system data according to the users settings.',
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Records;
