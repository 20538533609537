import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import AddPersonSelectedDevice from './AddPersonSelectdevice';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import firstPersonNormallyOpen from '../../../utils/api/AccessRule/firstPersonNormallyOpen';
import { fetchFirstPersonNormallyOpen } from '../../../redux/slices/FirstPersonNormallyOpenSlices';

interface AddFirstPersonProps {
  closeModal: () => void;
  data?: FirstPersonDevice;
  searchFilter?: {
    pageIndex: 1;
    pageSize: 10;
  };
}
interface FirstPersonDevice {
  id?: string;
  doorName: string;
  deviceAlias: string;
  doorId: string;
  deviceName: string;
  deviceId: string;
  timeSegName: string;
  ownedDevice: string;
  passageModeTimeZone: string;
  serialNumber: string;
  noOfDoors: any;
}
interface DropdownItem {
  label: string;
  id: string | number;
}
function AddFirstPerson({ closeModal, data, searchFilter }: AddFirstPersonProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [devices, setDevices] = useState<FirstPersonDevice[]>();
  const [disabled, setDisabled] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState<FirstPersonDevice[]>([]);
  const [levelDetails, setLevelDetails] = useState(
    data || {
      doorName: '',
      deviceAlias: '',
      deviceName: '',
      deviceId: '',
      doorId: '',
      passageModeTimeZone: '',
      id: '',
    },
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [dropdownData, setDropdownData] = useState<DropdownItem[]>([]);
  const [modalView, setModalView] = useState({
    title: t('addPersonSelectedDevice'),
    content: <>hi</>,
  });
  const onChangeValue = (doorName: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [doorName]: value,
    }));
  };
  const onTableSwap = (
    newAvailableData: FirstPersonDevice[],
    newSelectedData: FirstPersonDevice[],
  ) => {
    setDevices(newAvailableData); // Update available data
    setSelectedDevices(newSelectedData); // Update selected data
  };
  const tableColumns = [
    { label: t('doorName'), id: 'doorName' },
    { label: t('ownedDevice'), id: 'deviceAlias' },
    { label: t('serialNumber'), id: 'deviceSn' },
  ];
  const changeSearch = () => {
    console.log('search');
  };

  const getSelectedDevice = (selected: any[]) => {
    setLevelDetails((prev: any) => ({
      ...prev,
      doorId: selected[0].id,
      deviceAlias: selected[0].deviceAlias,
    }));
  };
  const addDoor = () => {
    setModalOpen(true); // Open door selection modal
    setModalView({
      title: t('selectDoor'),
      content: (
        <AddPersonSelectedDevice
          searchValue=""
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          tableData={devices}
          selectedTableData={selectedDevices}
          onTableSwap={onTableSwap}
          getSelected={getSelectedDevice}
          closeModal={() => setModalOpen(false)}
        />
      ),
    });
  };
  const submit = () => {
    const request = {
      doorName: levelDetails?.doorName,
      deviceAlias: levelDetails?.deviceAlias,
      doorId: levelDetails?.doorId,
      timeSegId: levelDetails.passageModeTimeZone,
      id: levelDetails?.id,
    };
    firstPersonNormallyOpen
      .saveFirstPerson(request)
      .then((response) => {
        if (response?.data) {
          const message = data?.id
            ? t('firstPersonUpdatedSuccessfully')
            : t('firstPersonAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          setDisabled(false);
          dispatch(
            fetchFirstPersonNormallyOpen({
              pageNo: searchFilter?.pageIndex || 1,
              pageSize: searchFilter?.pageSize || 10,
            }),
          );
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await firstPersonNormallyOpen.getTimeSegList();
        const formattedData: DropdownItem[] = response.data.data.map(
          (item: { text: string; value: string | number }) => ({
            label: item.text,
            id: item.value,
          }),
        );

        setDropdownData(formattedData);
      } catch (error: unknown) {
        if (error instanceof Error) {
          dispatch(setNotificationError(error.message || t('errorFetchingData')));
        } else {
          dispatch(setNotificationError(t('errorFetchingData')));
        }
      }
    };

    fetchDropdownData();
  }, []);

  return (
    <div className="add-level-modal" style={{ width: '676px' }}>
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="form-row">
        <InputField
          id="doorName"
          name="doorName"
          label={t('doorName')}
          type="text"
          value={levelDetails.doorName}
          onChangeFunction={onChangeValue}
          required
        />
        <SelectBoxField
          id="passageModeTimeZone"
          name="passageModeTimeZone"
          label={t('passageModeTimeZone')}
          value={levelDetails.passageModeTimeZone}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={dropdownData}
        />
      </div>

      <div className="select-device-wrapper">
        {!levelDetails?.doorId && (
          <Button onClickFunction={addDoor} title={t('selectDevice')} className="btn-default" />
        )}
        {levelDetails?.doorId && (
          <div
            className={`device-name ${data ? 'disabled' : ''}`}
            onClick={addDoor}
            role="presentation">
            <h4 className="label">{levelDetails?.deviceAlias}</h4>
          </div>
        )}
      </div>
      <div className="form-buttons-right">
        {Object.keys(data || {}).length === 0 && (
          <Button onClickFunction={submit} title={t('save&New')} className="btn-primary" />
        )}
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          disabled={disabled}
          className="btn-primary btn-bold"
        />
      </div>
    </div>
  );
}
AddFirstPerson.defaultProps = {
  data: {},
  searchFilter: {
    pageIndex: '',
    pageSize: '',
  },
};
export default AddFirstPerson;
