import React, { useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, StaticDateTimePicker } from '@mui/x-date-pickers';
import { createTheme, TextField, ThemeProvider } from '@mui/material';
import { InputDateInterFace } from '../../interface/common.interface';

function InputDateTime({
  label,
  value = null,
  disabled,
  readonly = false,
  message,
  errorStatus,
  disablePast = false,
  disableFuture = false,
  okFunction = () => {},
  onChangeFunction = () => {},
  actions = true,
  monthFormat = false,
  ampm = true,
}: InputDateInterFace) {
  const theme = createTheme({
    typography: {
      fontFamily: 'Urbanist, sans-serif',
    },
  });
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const changeValue = (newValue: Dayjs | null) => {
    onChangeFunction(newValue);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  const formatDate = (date: any) => {
    if (!date) return '';
    const day = date.date().toString().padStart(2, '0');
    const month = (date.month() + 1).toString().padStart(2, '0'); // month() is 0-indexed
    const year = date.year().toString().slice(-2); // Get last two digits of the year
    const hours = date.hour().toString().padStart(2, '0');
    const minutes = date.minute().toString().padStart(2, '0');
    return `${month}${day}${year}${hours}${minutes}`;
  };

  const handleAccept = (newValue: any) => {
    if (newValue && typeof newValue.valueOf === 'function') {
      if (monthFormat) {
        const formattedDate = formatDate(newValue);
        okFunction(formattedDate); // Use the formatted date
      } else {
        okFunction(newValue);
      }
    } else {
      console.warn('Invalid date value:', newValue);
    }
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="form-item" ref={ref}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TextField
            label={label}
            value={value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : ''}
            onClick={() => setOpen(true)}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            sx={{
              position: 'relative',
              fontFamily: 'Urbanist',
              '& .MuiOutlinedInput-input': {
                padding: '12.5px 14px',
                borderRadius: '8px !important',
              },
            }}
            disabled={disabled}
          />
          {open && (
            <StaticDateTimePicker
              disablePast={disablePast}
              disableFuture={disableFuture}
              disabled={disabled}
              readOnly={readonly}
              onAccept={handleAccept}
              onClose={() => setOpen(false)}
              value={value ? dayjs(value) : null}
              onChange={changeValue}
              ampm={ampm}
              slotProps={{
                layout: {
                  sx: {
                    boxShadow: '0px 1px 8px 1px #0000001A',
                  },
                },
                toolbar: {
                  hidden: true,
                },
                actionBar: {
                  actions: actions ? ['cancel', 'accept'] : [], // Hide OK and Cancel buttons
                },
              }}
              sx={{
                position: 'absolute',
                zIndex: 10,
                '& .MuiDateCalendar-root': {
                  maxHeight: '300px',
                },
              }}
            />
          )}
        </LocalizationProvider>
      </ThemeProvider>
      {errorStatus && <small className="input-error-message">{message}</small>}
    </div>
  );
}

export default InputDateTime;
