/* eslint-disable */
const verificationModes: any = {
  common_verifyMode_entiy: 'Verification Mode',
  common_verifyMode_cardOrFpOrPwd: 'Automatic Identification',
  common_verifyMode_onlyFp: 'Only Fingerprint',
  common_verifyMode_onlyPin: 'Only Pin',
  common_verifyMode_onlyPwd: 'Only Password',
  common_verifyMode_onlyCard: 'Only Card',
  common_verifyMode_pwdOrFp: 'Fingerprint/Password',
  common_verifyMode_cardAndFpAndPwd: 'Card+Password+Fingerprint',
  common_verifyMode_pinAndFpAndPwd: 'Pin+Password+Fingerprint',
  common_verifyMode_pinAndFpOrCardAndFp: 'Pin+Fingerprint/Card+Fingerprint',
  common_verifyMode_adminVerify: 'The administrator authentication',
  common_verifyMode_adminOrGeneralUser: 'The administrator/user authentication',
  common_verifyMode_doubleVerify: 'The two-step verification',
  common_verifyMode_other: 'Other',
  common_verifyMode_undefined: 'Verify Mode Undefined',
  common_verifyMode_entiyReverse: 'Verification Mode Reverse Selection',
  common_newVerify_mode_face: 'Face',
  common_newVerify_mode_pv: 'Palm',
  common_newVerify_mode_fv: 'Finger Vein',
  common_newVerify_mode_fp: 'Fingerprint',
  common_newVerify_mode_voice: 'Voice Print',
  common_newVerify_mode_iris: 'Iris',
  common_newVerify_mode_retina: 'Retina',
  common_newVerify_mode_pw: 'Password',
  common_newVerify_mode_pin: 'Work ID',
  common_newVerify_mode_rf: 'Card',
  common_newVerify_mode_idcard: 'ID Card',
  common_newVerify_mode_logic: 'Logic bit',
  common_newVerify_mode_logicOr: 'Or',
  common_newVerify_mode_logicAnd: 'And',
  common_newVerify_mode_manualConfig: 'Manual Configuration',
  acc_verify_mode_onlyface: 'Face',
  acc_verify_mode_facefp: 'Face+Fingerprint',
  acc_verify_mode_facepwd: 'Face+Password',
  acc_verify_mode_facecard: 'Face+Card',
  acc_verify_mode_facefpcard: 'Face+Fingerprint+Card',
  acc_verify_mode_facefppwd: 'Face+Fingerprint+Password',
  acc_verify_mode_fv: 'Finger Vein',
  acc_verify_mode_fvpwd: 'Finger Vein+Password',
  acc_verify_mode_fvcard: 'Finger Vein+Card',
  acc_verify_mode_fvpwdcard: 'Finger Vein+Password+Card',
  acc_verify_mode_pv: 'Palm',
  acc_verify_mode_pvcard: 'Palm+Card',
  acc_verify_mode_pvface: 'Palm+Face',
  acc_verify_mode_pvfp: 'Palm+Fingerprint',
  acc_verify_mode_pvfacefp: 'Palm+Face+Fingerprint',
};

const eventCodes: any = {
  acc_newEventNo_0: 'Normal Verify Open',
  acc_newEventNo_1: 'Verify During Passage Mode Time Zone',
  acc_newEventNo_2: 'First-Personnel Open',
  acc_newEventNo_3: 'Multi-Personnel Open',
  acc_newEventNo_20: 'Operation Interval too Short',
  acc_newEventNo_21: 'Door Inactive Time Zone Verify Open',
  acc_newEventNo_26: 'Multi-Personnel Authentication Wait',
  acc_newEventNo_27: 'Unregistered Personnel',
  acc_newEventNo_29: 'Personnel Expired',
  acc_newEventNo_30: 'Password Error',
  acc_newEventNo_41: 'Verify Mode Error',
  acc_newEventNo_43: 'Staff Lock',
  acc_newEventNo_44: 'Background Verify Failed',
  acc_newEventNo_45: 'Background Verify Timed Out',
  acc_newEventNo_48: 'Multi-Personnel Verify Failed',
  acc_newEventNo_54: 'The battery voltage is too low',
  acc_newEventNo_55: 'Replace the battery immediately',
  acc_newEventNo_56: 'Illegal Operation',
  acc_newEventNo_57: 'Backup Power',
  acc_newEventNo_58: 'Normally Open Alarm',
  acc_newEventNo_59: 'Illegal Management',
  acc_newEventNo_60: 'Door Locked Inside',
  acc_newEventNo_61: 'Replicated',
  acc_newEventNo_62: 'Prohibit Users',
  acc_newEventNo_63: 'Door Locked',
  acc_newEventNo_64: 'Inactive Exit button Time Zone',
  acc_newEventNo_65: 'Inactive Auxiliary Input Time Zone',
  acc_newEventNo_66: 'Reader Upgrade Failed',
  acc_newEventNo_67: 'Remote Comparison Succeeded (Device Not Authorized)',
  acc_newEventNo_68: 'High Body Temperature - Access Denied',
  acc_newEventNo_69: 'Without Mask - Access Denied',
  acc_newEventNo_70: 'Face comparison server communication exception',
  acc_newEventNo_71: 'The face server is responding erratically',
  acc_newEventNo_73: 'Invalid QR Code',
  acc_newEventNo_74: 'QR Code Expired',
  acc_newEventNo_101: 'Duress Open Alarm',
  acc_newEventNo_104: 'Invalid Card Swipe alarm',
  acc_newEventNo_105: 'Cannot connect to server',
  acc_newEventNo_106: 'Main power down',
  acc_newEventNo_107: 'Battery power down',
  acc_newEventNo_108: 'Can not connect to the master device',
  acc_newEventNo_109: 'Reader Tamper Alarm',
  acc_newEventNo_110: 'Reader Offline',
  acc_newEventNo_159: 'The remote control to open the door',
  acc_newEventNo_214: 'Connected to the server',
  acc_newEventNo_217: 'Connected to the master device successfully',
  acc_newEventNo_218: 'ID Card Verification',
  acc_newEventNo_222: 'Background Verify Success',
  acc_newEventNo_223: 'Background Verify',
  acc_newEventNo_224: 'Ring the bell',
  acc_newEventNo_227: 'Double open the door',
  acc_newEventNo_228: 'Double close the door',
  acc_newEventNo_229: 'Auxiliary Output Timed Normally Open',
  acc_newEventNo_230: 'Auxiliary Output Timed Close',
  acc_newEventNo_232: 'Verify Success',
  acc_newEventNo_233: 'Activate Lockdown',
  acc_newEventNo_234: 'Deactivate Lockdown',
  acc_newEventNo_235: 'Reader Upgrade Success',
  acc_newEventNo_236: 'Reader Tamper Alarm Cleared',
  acc_newEventNo_237: 'Reader Online',
  acc_newEventNo_239: 'Device call',
  acc_newEventNo_240: 'Call ended',
  acc_newEventNo_4008: 'Mains recovery',
  acc_newEventNo_6005: 'Record Capacity is reaching upper limit',
  acc_newEventNo_6006: 'Line short circuit (RS485)',
  acc_newEventNo_6007: 'Short circuit in the circuit (Wiegand)',
  'acc_eventNo_-1': 'None',
  acc_eventNo_0: 'Normal Swipe Open',
  acc_eventNo_1: 'Swipe during Passage Mode Time Zone',
  acc_eventNo_2: 'First-Card Normal Open(Swipe Card)',
  acc_eventNo_3: 'Multi-Person Open(Swipe Card)',
  acc_eventNo_4: 'Emergency Password Open',
  acc_eventNo_5: 'Open during Passage Mode Time Zone',
  acc_eventNo_6: 'Linkage Event Triggered',
  acc_eventNo_7: 'Cancel Alarm',
  acc_eventNo_8: 'Remote Opening',
  acc_eventNo_9: 'Remote Closing',
  acc_eventNo_10: 'Disable Intraday Passage Mode Time Zone',
  acc_eventNo_11: 'Enable Intraday Passage Mode Time Zone',
  acc_eventNo_12: 'Auxiliary Output Remotely Open',
  acc_eventNo_13: 'Auxiliary Output Remotely Close',
  acc_eventNo_14: 'Normal Fingerprint Open',
  acc_eventNo_15: 'Multi-Person Open(Fingerprint)',
  acc_eventNo_16: 'Press Fingerprint during Passage Mode Time Zone',
  acc_eventNo_17: 'Card plus Fingerprint Open',
  acc_eventNo_18: 'First-Card Normal Open(Press Fingerprint)',
  acc_eventNo_19: 'First-Card Normal Open(Card plus Fingerprint)',
  acc_eventNo_20: 'Operate Interval too Short',
  acc_eventNo_21: 'Door Inactive Time Zone(Swipe Card)',
  acc_eventNo_22: 'Illegal Time Zone',
  acc_eventNo_23: 'Access Denied',
  acc_eventNo_24: 'Anti-Passback',
  acc_eventNo_25: 'Interlock',
  acc_eventNo_26: 'Multi-Person Authentication(Swipe Card)',
  acc_eventNo_27: 'Disabled Card',
  acc_eventNo_28: 'Extended Open Door Timeout',
  acc_eventNo_29: 'Card Expired',
  acc_eventNo_30: 'Password Error',
  acc_eventNo_31: 'Press Fingerprint Interval too Short',
  acc_eventNo_32: 'Multi-Person Authentication(Press Fingerprint)',
  acc_eventNo_33: 'Fingerprint Expired',
  acc_eventNo_34: 'Disabled Fingerprint',
  acc_eventNo_35: 'Door Inactive Time Zone(Press Fingerprint)',
  acc_eventNo_36: 'Door Inactive Time Zone(Press Exit Button)',
  acc_eventNo_37: 'Failed to Close during Passage Mode Time Zone',
  acc_eventNo_38: 'Card Reported Lost',
  acc_eventNo_39: 'Access Disabled',
  acc_eventNo_40: 'Multi-Person Authentication Failed(Press Fingerprint)',
  acc_eventNo_41: 'Verify Mode Error',
  acc_eventNo_42: 'Wiegand Format Error',
  acc_eventNo_43: 'Anti-Passback Verification Timeout',
  acc_eventNo_44: 'Background Verify Failed',
  acc_eventNo_45: 'Background Verify Timeout',
  acc_eventNo_47: 'Failed to Send the Command',
  acc_eventNo_48: 'Multi-Person Authentication Failed(Swipe Card)',
  acc_eventNo_49: 'Door Inactive Time Zone(Password)',
  acc_eventNo_50: 'Press Password Interval too Short',
  acc_eventNo_51: 'Multi-Person Authentication(Password)',
  acc_eventNo_52: 'Multi-Person Authentication Fails(Password)',
  acc_eventNo_53: 'Password Expired',
  acc_eventNo_100: 'Tamper Alarm',
  acc_eventNo_101: 'Duress Password Open',
  acc_eventNo_102: 'Door Opened Forcefully',
  acc_eventNo_103: 'Duress Fingerprint Open',
  acc_eventNo_200: 'Door Opened Correctly',
  acc_eventNo_201: 'Door Closed Correctly',
  acc_eventNo_202: 'Exit Button Open',
  acc_eventNo_203: 'Multi-Person Open(Card plus Fingerprint)',
  acc_eventNo_204: 'Passage Mode Time Zone Over',
  acc_eventNo_205: 'Remote Normal Opening',
  acc_eventNo_206: 'Device Started',
  acc_eventNo_207: 'Password Open',
  acc_eventNo_208: 'Superuser Open Doors',
  acc_eventNo_209: 'Exit Button triggered(Without Unlock)',
  acc_eventNo_210: 'Start the fire door',
  acc_eventNo_211: 'Superuser Close Doors',
  acc_eventNo_212: 'Enable elevator control function',
  acc_eventNo_213: 'Disable elevator control function',
  acc_eventNo_214: 'Multi-Person Open(Password)',
  acc_eventNo_215: 'First-Card Normal Open(Password)',
  acc_eventNo_216: 'Password during Passage Mode Time Zone',
  acc_eventNo_220: 'Auxiliary Input Disconnected (Open)',
  acc_eventNo_221: 'Auxiliary Input Shorted (Closed)',
  acc_eventNo_222: 'Background Verification Succeeded',
  acc_eventNo_223: 'Background Verification',
  acc_eventNo_225: 'Auxiliary Input Normal',
  acc_eventNo_226: 'Auxiliary Input Trigger',
  acc_eventNo_undefined: 'Event Number Undefined',
  acc_advanceEvent_500: 'Global Anti-Passback(logical)',
  acc_advanceEvent_501: 'Person Availability(use the Date)',
  acc_advanceEvent_502: 'Person Number of Control',
  acc_advanceEvent_503: 'Global Interlock',
  acc_advanceEvent_504: 'Route Control',
  acc_advanceEvent_505: 'Global Anti-Passback(timed)',
  acc_advanceEvent_506: 'Global Anti-Passback(timed logical)',
  acc_advanceEvent_507: 'Person Availability(after the first use of valid days)',
  acc_advanceEvent_508: 'Person Availability(use number of times)',
  acc_advanceEvent_509: 'Background verification fails (unregistered personnel)',
  acc_advanceEvent_510: 'Background verification fails (data exception)',
  acc_alarmEvent_701: 'DMR Violation(setting rules: {0})',
  common_verifyMode_entiy: 'Verification Mode',
  common_verifyMode_cardOrFpOrPwd: 'Automatic Identification',
  common_verifyMode_onlyFp: 'Only Fingerprint',
  common_verifyMode_onlyPin: 'Only Pin',
  common_verifyMode_onlyPwd: 'Only Password',
  common_verifyMode_onlyCard: 'Only Card',
  common_verifyMode_pwdOrFp: 'Fingerprint/Password',
  common_verifyMode_cardOrFp: 'Card/Fingerprint',
  common_verifyMode_cardOrPwd: 'Card/Password',
  common_verifyMode_pinAndFp: 'Pin+Fingerprint',
  common_verifyMode_fpAndPwd: 'Fingerprint+Password',
  common_verifyMode_cardAndFp: 'Card+Fingerprint',
  common_verifyMode_cardAndPwd: 'Card+Password',
  common_verifyMode_cardAndFpAndPwd: 'Card+Password+Fingerprint',
  common_verifyMode_pinAndFpAndPwd: 'Pin+Password+Fingerprint',
  common_verifyMode_pinAndFpOrCardAndFp: 'Pin+Fingerprint/Card+Fingerprint',
  common_verifyMode_adminVerify: 'The administrator authentication',
  common_verifyMode_adminOrGeneralUser: 'The administrator/user authentication',
  common_verifyMode_doubleVerify: 'The two-step verification',
  common_verifyMode_other: 'Other',
  common_verifyMode_undefined: 'Verify Mode Undefined',
  common_verifyMode_entiyReverse: 'Verification Mode Reverse Selection',
  common_newVerify_mode_face: 'Face',
  common_newVerify_mode_pv: 'Palm',
  common_newVerify_mode_fv: 'Finger Vein',
  common_newVerify_mode_fp: 'Fingerprint',
  common_newVerify_mode_voice: 'Voice Print',
  common_newVerify_mode_iris: 'Iris',
  common_newVerify_mode_retina: 'Retina',
  common_newVerify_mode_pw: 'Password',
  common_newVerify_mode_pin: 'Work ID',
  common_newVerify_mode_rf: 'Card',
  common_newVerify_mode_idcard: 'ID Card',
  common_newVerify_mode_logic: 'Logic bit',
  common_newVerify_mode_logicOr: 'Or',
  common_newVerify_mode_logicAnd: 'And',
  common_newVerify_mode_manualConfig: 'Manual Configuration',
};

const rtMonitorOptions: Record<string, string> = {
  acc_rtMonitor_openDoor: 'Open',
  acc_rtMonitor_closeDoor: 'Close',
  acc_rtMonitor_remoteNormalOpen: 'Remote Normally Open',
  acc_rtMonitor_realTimeEvent: 'Real-Time Events',
  acc_rtMonitor_photoMonitor: 'Photo Monitoring',
  acc_rtMonitor_alarmMonitor: 'Alarm Monitoring',
  acc_rtMonitor_doorState: 'Door State',
  acc_rtMonitor_auxOutName: 'Auxiliary Output Name',
  acc_rtMonitor_nonsupport: 'Unsupported',
  acc_rtMonitor_lock: 'Locked',
  acc_rtMonitor_unLock: 'Unlocked',
  acc_rtMonitor_disable: 'Disabled',
  acc_rtMonitor_noSensor: 'No Door Sensor',
  acc_rtMonitor_alarm: 'Alarm',
  acc_rtMonitor_openForce: 'Opened Forcefully',
  acc_rtMonitor_tamper: 'Tamper',
  acc_rtMonitor_duressPwdOpen: 'Duress Password Open',
  acc_rtMonitor_duressFingerOpen: 'Duress Fingerprint Open',
  acc_rtMonitor_duressOpen: 'Duress Open',
  acc_rtMonitor_openTimeout: 'Opening Timeout',
  acc_rtMonitor_unknown: 'Unknown',
  acc_rtMonitor_noLegalDoor: 'There is no door that meets the condition.',
  acc_rtMonitor_noLegalAuxOut: 'No auxiliary output meets the condition!',
  acc_rtMonitor_curDevNotSupportOp: 'Current device status does not support this operation!',
  acc_rtMonitor_curNormalOpen: 'Currently normal open',
  acc_rtMonitor_whetherDisableTimeZone: 'The current state of the door is always open.',
  acc_rtMonitor_curSystemNoDoors:
    'The current system has not added any door or cannot find any door that meets your requirements.',
  acc_rtMonitor_cancelAlarm: 'Cancel Alarm',
  acc_rtMonitor_openAllDoor: 'Open all current doors',
  acc_rtMonitor_closeAllDoor: 'Close all current doors',
  acc_rtMonitor_confirmCancelAlarm: 'Are you sure to cancel this alarm?',
  acc_rtMonitor_calcelAllDoor: 'Cancel all alarms',
  acc_rtMonitor_initDoorStateTip: 'Obtaining all doors authorized to users in the system...',
  acc_rtMonitor_alarmEvent: 'Alarm Event',
  acc_rtMonitor_ackAlarm: 'Acknowledge',
  acc_rtMonitor_ackAllAlarm: 'Acknowledge All',
  acc_rtMonitor_ackAlarmTime: 'Acknowledge Time',
  acc_rtMonitor_sureToAckThese:
    'Are you sure you confirm this {0} alarm? After your confirmation, all alarms will be canceled.',
  acc_rtMonitor_sureToAckAllAlarm:
    'Are you sure you want to cancel all alarm? After your confirmation, all alarms will be canceled.',
  acc_rtMonitor_noSelectAlarmEvent: 'Please choose to confirm the alarm event!',
  acc_rtMonitor_noAlarmEvent: 'There are no alarm events in the current system!',
  acc_rtMonitor_forcefully: 'Cancel Alarm (Door Opened Forcefully)',
  acc_rtMonitor_addToRegPerson: 'Added to the registered person',
  acc_rtMonitor_cardExist: 'This card has been assigned by {0} and cannot be issued again.',
  acc_rtMonitor_opResultPrompt: 'Send {0} request successfully, failed {1}.',
  acc_rtMonitor_doorOpFailedPrompt:
    'Failed to send requests to the following doors, please try again!',
  acc_rtMonitor_remoteOpen: 'Remote Open',
  acc_rtMonitor_remoteClose: 'Remote Close',
  acc_rtMonitor_alarmSoundClose: 'Audio is closed',
  acc_rtMonitor_alarmSoundOpen: 'Audio is opened',
  acc_rtMonitor_playAudio: 'Sounds Reminder',
  acc_rtMonitor_isOpenShowPhoto: 'Enable Display Photos Function',
  acc_rtMonitor_isOpenPlayAudio: 'Enable Audio Alert Function',
  acc_rtm_open: 'Remote Open the Button',
  acc_rtm_close: 'Remote Close the Button',
  acc_rtm_eleModule: 'Elevator',
  acc_cancelAlarm_fp: 'Cancel Alarm (Duress Fingerprint Open)',
  acc_cancelAlarm_pwd: 'Cancel Alarm (Duress Password Open)',
  acc_cancelAlarm_timeOut: 'Cancel Alarm (Extended Open Door Timeout)',
  common_none: 'None',
  common_close: 'Close',
  common_open: 'Open',
};

export { verificationModes, eventCodes, rtMonitorOptions };
