import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import AddAntiPassbackSelectedDevice from './Add-AntiPassbackSelectedDevice';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import antiPassback from '../../../utils/api/AccessRule/antiPassback';
import { useAppDispatch } from '../../../redux/store';
import { DeviceProps } from '../../../interface/accessByDepartment.interface';
import { fetchAntiPassBack } from '../../../redux/slices/AntiPassBackSlices';

interface AntiPassbackProps {
  closeModal: () => void;
  data?: FirstPersonDevice;
  searchFilter?: {
    pageIndex: number;
    pageSize: number;
  };
}

interface FirstPersonDevice {
  id?: string;
  name: string;
  deviceId: string;
  deviceName: string;
  antiPassbackRule: string;
  apbRuleShow: string;
  noOfDoors: number;
  // apbRule: any;
}

interface SelectItemInterface {
  label: string;
  id: string;
  value?: string;
}

function AddAntiPassback({ data, closeModal, searchFilter }: AntiPassbackProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [devices, setDevices] = useState<DeviceProps[]>();
  const [selectedDevices, setSelectedDevices] = useState<DeviceProps[]>([]);
  const [antiPassbacks, setAntiPassback] = useState('');

  const [antiPassbackRule, setAntiPassbackRule] = useState<SelectItemInterface[]>([]);
  const [levelDetails, setLevelDetails] = useState<FirstPersonDevice>(
    data || {
      id: '',
      name: '',
      deviceId: '',
      deviceName: '',
      antiPassbackRule: '',
      apbRuleShow: '',
      noOfDoors: 0,
    },
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('accessinterlock'),
    content: <>hi</>,
  });

  const onTableSwap = (newAvailableData: DeviceProps[], newSelectedData: DeviceProps[]) => {
    setDevices(newAvailableData);
    setSelectedDevices(newSelectedData);
  };

  const tableColumns = [
    { label: t('deviceName'), id: 'deviceName' },
    { label: t('serialNumber'), id: 'deviceSn' },
  ];

  const onChangeValue = (name: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const changeSearch = (event: any) => {
    setAntiPassback(event.target.value);
  };

  const getProtocols = (id: string) => {
    antiPassback
      .getRule(id)
      .then((response) => {
        const antiPassbackList: SelectItemInterface[] = response.data.data.map((item: any) => ({
          label: item.text,
          id: item.value,
        }));
        setAntiPassbackRule(antiPassbackList);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const getSelectedDevice = (selected: DeviceProps[]) => {
    setLevelDetails((prev) => ({
      ...prev,
      deviceId: selected[0].id,
      deviceName: selected[0].deviceName,
    }));
    getProtocols(selected[0].id);
  };

  const addDoor = () => {
    setModalOpen(true);
    setModalView({
      title: t('selectDevice'),
      content: (
        <AddAntiPassbackSelectedDevice
          searchValue={antiPassbacks}
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          tableData={devices}
          selectedTableData={selectedDevices}
          getSelected={getSelectedDevice}
          onTableSwap={onTableSwap}
          closeModal={() => setModalOpen(false)}
        />
      ),
    });
  };

  const submit = () => {
    const request = {
      id: levelDetails?.id,
      deviceName: levelDetails?.deviceName,
      deviceId: levelDetails?.deviceId,
      name: levelDetails?.deviceName,
      apbRule: levelDetails?.apbRuleShow,
    };
    antiPassback
      ?.saveAntiPassback(request)
      .then((response) => {
        if (response?.data?.success) {
          const message = data?.id
            ? t('antiPassBackUpdatedSuccessfully')
            : t('antiPassBackAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
          dispatch(
            fetchAntiPassBack({
              pageNo: searchFilter?.pageIndex || 1,
              pageSize: searchFilter?.pageSize || 10,
            }),
          );
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    if (data) {
      setLevelDetails({
        ...levelDetails,
        deviceId: data.deviceId,
        deviceName: data.deviceName,
      });
      getProtocols(data.deviceId);
    }
  }, [data]);

  return (
    <div className="add-antiPassBack">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="form-row">
        <InputField
          id="deviceName"
          name="deviceName"
          label={t('deviceName')}
          type="text"
          value={levelDetails.deviceName}
          onChangeFunction={(name, value) => onChangeValue(name, value)}
          disabled={Object.keys(data || {}).length > 0}
          required
        />
        <SelectBoxField
          id="apbRuleShow"
          name="apbRuleShow"
          label={t('antiPassbackRule')}
          value={levelDetails.apbRuleShow}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={antiPassbackRule}
          required
        />
      </div>

      <div className="select-device-wrapper">
        {!levelDetails.deviceId && (
          <Button onClickFunction={addDoor} title={t('selectDevice')} className="btn-default" />
        )}
        {levelDetails.deviceId && (
          <div
            className={`device-name ${data ? 'disabled' : ''}`}
            onClick={addDoor}
            role="presentation">
            <h4 className="label">{levelDetails.deviceName}</h4>
          </div>
        )}
      </div>
      <div className="form-buttons-right">
        {Object.keys(data || {}).length === 0 && (
          <Button onClickFunction={submit} title={t('save&New')} className="btn-primary" />
        )}
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button onClickFunction={submit} title={t('ok')} className="btn-primary btn-bold" />
      </div>
    </div>
  );
}

AddAntiPassback.defaultProps = {
  data: {},
  searchFilter: {},
};

export default AddAntiPassback;
