import React, { useEffect, useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

// Use redux functions
import { fetchAllPositionList, fetchPositionList } from '../../../redux/slices/PositionSlices';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';

// Use interface
import {
  AddPositionInterFace,
  PositionReduxInterface,
} from '../../../interface/position.interface';
// Use api's calls
import positionApi from '../../../utils/api/position';
import { nameValidation } from '../../../shared-components/form/validation';

function AddPosition({ closeModal, data }: AddPositionInterFace) {
  let sortTime = true;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { allPositions }: PositionReduxInterface = useAppSelector((state: any) => state.positions);

  const [disableButton, setDisableButton] = useState(true);
  const [positionValue, setPositionValue] = useState(
    data || {
      code: '',
      name: '',
      parentCode: '',
      sortNo: '9999',
      update: false,
    },
  );
  const [checkExistName, setCheckExistName] = useState(true);

  const onChangeValue = (name: string, value: any) => {
    setPositionValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onBlurCode = async (name: string, value: any) => {
    if (name === 'code' && value !== '' && value !== data?.code) {
      const responseData = await positionApi.isExist({ code: value });
      setCheckExistName(responseData?.data);
    }
  };

  const submit = async () => {
    const request = {
      code: positionValue.code,
      name: positionValue.name,
      parentId: positionValue.parentCode,
      sortNo: positionValue.sortNo,
      id: positionValue?.id,
    };
    positionApi
      .addPosition(request)
      .then((response) => {
        if (response.data.code === 0) {
          const message = data ? t('positionUpdatedSuccessfully') : t('positionAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          dispatch(fetchPositionList({ pageNo: 1, pageSize: 10 }));
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    let i = 0;
    if (
      positionValue.code === '' ||
      positionValue.name === '' ||
      positionValue.sortNo === '' ||
      !checkExistName
    ) {
      i += 1;
    }
    if (i > 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [positionValue, checkExistName]);

  useEffect(() => {
    if (allPositions.length === 0 && sortTime) {
      sortTime = false;
      dispatch(fetchAllPositionList({}));
    }
  }, [allPositions, dispatch]);

  return (
    <div className="add-position">
      <div className="add-position-form">
        <div className="row row-3">
          <div className="col">
            <InputField
              name="code"
              label={t('positionNumber')}
              id="code"
              type="text"
              value={positionValue.code}
              required
              onBlurFunction={onBlurCode}
              errorStatus={positionValue.code !== '' && !checkExistName}
              message={t('objectIsAlreadyExist')}
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
              disabled={data && Object.keys(data)?.length > 0}
            />
          </div>
          <div className="col">
            <InputField
              name="sortNo"
              label={t('sort')}
              id="sortNo"
              type="number"
              value={positionValue.sortNo}
              required
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col">
            <SelectBoxField
              name="parentCode"
              label={t('parentPositionNumber')}
              id="parentCode"
              data={allPositions}
              value={positionValue.parentCode}
              className=""
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            />
          </div>
          <div className="col">
            <InputField
              name="name"
              label={t('positionName')}
              id="name"
              type="text"
              value={positionValue.name}
              required
              innerIconStatus={false}
              errorStatus={positionValue.name !== '' && !nameValidation(1, 50, positionValue.name)}
              message={t('positionNameInvalid')}
              onChangeFunction={onChangeValue}
            />
          </div>
        </div>
      </div>
      <div className="add-position-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={submit}
            title={t('done')}
            className="btn-primary btn-bold"
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
}

export default AddPosition;
