import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon } from '../../../../assets/svgicons/svgicon';
import DeleteModalBox from '../../../../shared-components/delete-modal-box/delete-modal-box';
import AddResource from './components/AddCertificate';
import {
  deleteCertificateType,
  fetchCertificateType,
  setSearchFilter,
} from '../../../../redux/slices/system-management/CertificateTypeSlices';
import certificateType from '../../../../utils/api/SystemManagement/certificateType';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';

function CertificateType() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { certificateList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.certificate,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleEditOpen = (editData: any) => {
    const updateData = {
      ...editData,
      id: editData?.id,
      dictValue: editData?.dictValue,
      code: editData?.code,
    };
    setModalOpen(true);
    setModalView({
      title: t('editDevice'),
      content: <AddResource closeModal={() => setModalOpen(false)} data={{ ...updateData }} />,
    });
  };
  const columnsField = [
    {
      accessorKey: 'code',
      header: t('certificatesNumber'),
      Cell: ({ row }: any) => (
        <span
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.code}
        </span>
      ),
      size: 150,
    },
    {
      accessorKey: 'dictValue',
      header: t('certificatesName'),
    },
  ];

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchCertificateType({ ...params }));
    }
  };

  const deleteRecord = (id: any) => {
    certificateType
      .delCerTypeByIds(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('certificateTypeDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteCertificateType(id));
          setModalOpen(false);
          fetchData({ pageNo: 1 }); // Refresh the list after successful deletion
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      disabled: true,
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteCertificate'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };
  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('system')}
        broadCram={[
          {
            title: t('systemManagement'),
          },
          {
            title: t('certificateType'),
          },
        ]}
      />
      <CardBox
        title={t('listOfCertificate')}
        rightSide={
          <CardRightButtonGroup
            // actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddResource closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          checkBoxActive
          enablePagination
          isFetching={status === 'loading'}
          values={certificateList}
          fixedColumn={['zone']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default CertificateType;
