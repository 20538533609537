import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon, FullScreenIcon, ZoomInIcon, ZoomOutIcon } from '../../assets/svgicons/svgicon';
import ModalBox from '../../shared-components/modal-box/modal-box';
import pin from '../../assets/images/pin.png';
import AddMap from './components/add-map';
import DragDropImages from './components/dragAndDrop/dragdrop-Images';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import AddDoor from './components/add-door';
import DataTable from '../../shared-components/data-table/data-table';
import LatestEventsPopup from '../realTime-monitoring/components/latest-events-popup';
import {
  AddDoorReq,
  MapImageInterface,
  MapPositionInterface,
  TreeNodeInterface,
} from '../../interface/map.interface';
import map from '../../utils/api/Devices/map';
import { fetchMapArea } from '../../redux/slices/MapSlices';
import TreeViewDropdown from '../../shared-components/tree-view/TreeViewDropdown';
import { isExtractBase64, isIdNotInEnd } from '../../shared-components/form/validation';

function Map() {
  const { t } = useTranslation();
  const { mapArea, mapId } = useAppSelector((state: any) => state.mapSlice);
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('title'),
    content: <>hi</>,
  });
  const [zoomLevel, setZoomLevel] = useState<number>(1);
  const [mapImage, setMapImage] = useState<MapImageInterface>({
    imageSrc: '',
    mapHeight: 0,
    mapWidth: 0,
    posArray: '',
    mapId: '',
    mapLabel: '',
  });
  const [draggableImages, setDraggableImages] = useState([]);

  const handleSavePosition = async () => {
    try {
      const request = {
        mapHeight: mapImage.mapHeight,
        mapWidth: mapImage.mapWidth,
        mapId: mapImage.mapId,
        posArray: mapImage.posArray,
      };
      const response = await map.saveMapPosition(request);
      if (response.data.success) {
        dispatch(
          setNotificationError({
            error: t('mapPositionSavedSuccessfully'),
            status: 200,
          }),
        );
        setModalOpen(false);
        dispatch(fetchMapArea());
      } else {
        dispatch(
          setNotificationError({
            error: response.data.message,
            status: response.data.code,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const handleClickEvent = () => {
    setModalView({
      title: t('queryTheLatestEventsFromTheDoor'),
      content: <LatestEventsPopup />,
    });
    setModalOpen(true);
  };

  const getMapList = async (areaId: string) => {
    if (areaId) {
      try {
        const response = await map.getMap(areaId);

        if (response) {
          // const ids: any = response.data.mapId.split(',');
          /**
           * @regex - use a regular expression to find and extract the base64 part.
           */
          const base64Regex = /data:image\/[a-zA-Z]+;base64,([a-zA-Z0-9+/=]+)/;
          const tempMap = response?.data?.tempMap;
          let base64String: string;

          // Check if the string includes the prefix
          if (isExtractBase64(tempMap)) {
            // Extract the base64 string from a data URI
            const matchString = tempMap.match(base64Regex);
            base64String = matchString && matchString[1] ? matchString[0] : null; // Get the full match including prefix
          } else {
            base64String = tempMap;
          }
          const idMatch = tempMap.match(/id=([^,]+)/);
          const nameMatch = tempMap.match(/name=([^,]+)/);
          const widthMatch = tempMap.match(/width=([^,]+)/);
          const heightMatch = tempMap.match(/height=([^,]+)/);
          const id = idMatch ? idMatch[1] : null;
          const mapLabel = nameMatch ? nameMatch[1] : null;
          const mapWidth = widthMatch ? Math.round(widthMatch[1]) : 0;
          const mapHeight = heightMatch ? Math.round(heightMatch[1]) : 0;
          setMapImage((prev: MapImageInterface) => ({
            ...prev,
            imageSrc: base64String,
            id,
            mapLabel,
            mapHeight,
            mapWidth,
          }));
          const mappedDraggableImages = response.data.mapPosList.map((image: any) => ({
            id: image.id,
            src: pin,
            position: { x: image.leftX, y: image.topY },
            handleClickEvent,
            dragStart: false,
            status: 'Online',
            data: {
              serialNumber: '822223330568',
              number: '1',
              doorSensor: 'Unknown',
              relay: 'Unknown',
              alarm: 'None',
            },
          }));

          setDraggableImages(mappedDraggableImages);
        }
      } catch (error: any) {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      }
    }
  };

  const saveMap = async (req: any) => {
    try {
      const response1 = await map.saveMap(req);
      if (response1?.data?.success) {
        // const mapTreeRes = await map.getMapTree();
        // if (mapTreeRes) {
        if (response1?.data?.success) {
          setMapImage((prev: MapImageInterface) => ({
            ...prev,
            mapId: response1.data.data,
          }));
          dispatch(fetchMapArea());
          getMapList(response1.data.data);
          dispatch(
            setNotificationError({
              error: t('mapSavedSuccessfully'),
              status: 200,
            }),
          );
          // }
          setModalOpen(false);
        }
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const handleDelete = () => {
    map
      .deleteMap({ ids: mapImage?.mapId })
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('mapDeletedSuccessfully'),
              status: 200,
            }),
          );
          setModalOpen(false);
          dispatch(fetchMapArea());
          getMapList(mapId);
          setMapImage((prev) => ({
            ...prev,
            mapId,
          }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const saveDoor = async (req: AddDoorReq) => {
    try {
      const response1 = await map.addDoor(req);
      if (response1?.data?.success) {
        // const response = await map.getMap(mapId);
        // if (response) {
        dispatch(
          setNotificationError({
            error: t('doorSavedSuccessfully'),
            status: 200,
          }),
        );
        getMapList(req?.mapId);
        setModalOpen(false);
        // }
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const moreActionButton = [
    {
      title: t('edit'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('edit'),
          content: <AddMap closeModal={() => setModalOpen(false)} update={mapImage} />,
        });
      },
    },
    {
      title: t('delete'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => handleDelete()}
              title={t('areYouSureYouWantToDeleteTheCurrentMap')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
      },
    },
    {
      title: t('savePositions'),
      clickFunction: () => handleSavePosition(),
    },
    {
      title: t('addDoor'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('addDoor'),
          content: (
            <AddDoor
              closeModal={() => setModalOpen(false)}
              mapId={mapImage?.mapId}
              saveDoor={saveDoor}
            />
          ),
        });
      },
    },
    {
      title: t('addCamera'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('addCamera'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => setModalOpen(false)}
              title={t('theSystemDoesNotDetectAVideoDevicePleaseAddVideoDevice')}
              buttonLabel={t('ok')}
            />
          ),
        });
      },
    },
  ];

  const handleClickZoom = (zoom: string) => {
    if (zoom === 'zoomIn') {
      setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 3));
    } else {
      setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.5));
    }
  };

  const mapActions = [
    {
      title: t('zoomIn'),
      icon: ZoomInIcon,
      clickFunction: (): void => handleClickZoom('zoomIn'),
    },
    {
      title: t('zoomOut'),
      icon: ZoomOutIcon,
      clickFunction: (): void => handleClickZoom('zoomOut'),
    },
    {
      title: t('fullScreen'),
      icon: FullScreenIcon,
      clickFunction: (): void => {},
    },
  ];

  // const draggableImages: MapCardInterface[] = [
  //   {
  //     id: 1,
  //     src: pin,
  //     position: { x: 50, y: 50 },
  //     status: 'Online',
  //     serialNumber: '822223330568',
  //     number: '1',
  //     doorSensor: 'Unknown',
  //     relay: 'Unknown',
  //     alarm: 'None',
  //   },
  // ];

  // table
  const columnsField = [
    {
      accessorKey: 'time',
      header: t('time'),
      size: 120,
    },
    {
      accessorKey: 'area',
      header: t('area'),
      size: 100,
    },
    {
      accessorKey: 'device',
      header: t('device'),
      size: 100,
    },
    {
      accessorKey: 'eventPoint',
      header: t('eventPoint'),
      size: 100,
    },
    {
      accessorKey: 'eventDescription',
      header: t('eventDescription'),
      size: 100,
    },
    {
      accessorKey: 'cardNumber',
      header: t('cardNumber'),
      size: 120,
    },
    {
      accessorKey: 'person',
      header: t('person'),
      size: 100,
    },
    {
      accessorKey: 'readerName',
      header: t('readerName'),
      size: 100,
    },
    {
      accessorKey: 'verification',
      header: t('verification'),
      size: 120,
    },
  ];

  const fetchNextData = (): any => {
    console.log('Fetching next page data...');
  };

  const handleSelectArea = (id: any) => {
    if (isIdNotInEnd(id)) {
      getMapList(id);
      setMapImage((prev: MapImageInterface) => ({
        ...prev,
        mapId: id,
      }));
    }
  };

  const handleSelectItem = (val: TreeNodeInterface) => {
    setMapImage((prev: MapImageInterface) => ({
      ...prev,
      mapLabel: val?.text,
    }));
  };

  const handleDragAndDrop = (data: MapPositionInterface[]) => {
    const postPosArray = data
      .map((obj: any) => Object.values(obj))
      .flat()
      .join(',');

    setMapImage((prev: MapImageInterface) => ({
      ...prev,
      posArray: postPosArray,
    }));
  };

  useEffect(() => {
    dispatch(fetchMapArea());
  }, []);

  useEffect(() => {
    if (mapArea) {
      setMapImage((prev: MapImageInterface) => ({
        ...prev,
        mapId,
      }));
      if (mapId) {
        getMapList(mapId);
      }
    }
  }, [mapId]);

  return (
    <div className="device-map">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('map'),
          },
        ]}
      />
      <div className="grid-container">
        <div className="header">
          <CardBox
            title={t('map')}
            rightSide={
              <CardRightButtonGroup
                actions={moreActionButton}
                buttonIcon={<AddIcon color="#ffffff" />}
                buttonLabel={t('new')}
                buttonClass="btn-primary btn-sm"
                buttonStatus
                buttonFunction={(): void => {
                  setModalOpen(true);
                  setModalView({
                    title: t('new'),
                    content: <AddMap closeModal={() => setModalOpen(false)} saveMap={saveMap} />,
                  });
                }}
              />
            }
          />
        </div>
        <div className="left">
          <CardBox title={t('mapIndex')}>
            <div className="area-list-wrap">
              {mapArea?.[0]?.id === '_' ? (
                <span className="title">Please add a map</span>
              ) : (
                <TreeViewDropdown
                  listItems={mapArea}
                  onSelect={handleSelectArea}
                  onSelectValue={handleSelectItem}
                  value={mapImage?.mapId}
                  isExpanded
                />
              )}
            </div>
          </CardBox>
        </div>
        <div className="right-top">
          <div className="map-card">
            <div className="card-header">
              <span className="title">{mapImage?.mapLabel}</span>
              <div className="header-right">
                <div className="button-list">
                  {mapActions.map((item: any) => (
                    <div
                      key={item.title}
                      className="button-item"
                      onClick={item.clickFunction}
                      role="presentation">
                      <item.icon />
                      <span>{item.title}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="map-card-body">
              <div
                className="image-container"
                style={{
                  transform: `scale(${zoomLevel})`,
                  transformOrigin: '0px 0px 0px',
                  transition: 'transform 0.3s ease',
                }}>
                <DragDropImages
                  imgProperties={mapImage}
                  draggableImages={draggableImages}
                  handleClickEvent={handleClickEvent}
                  handleDragAndDrop={handleDragAndDrop}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="right-bottom">
          <CardBox title={t('real-time-events')}>
            <DataTable
              actionButtonStatus={false} // Disable action buttons when modal is open
              columns={columnsField}
              enableGlobalFilter={false}
              checkBoxActive={false}
              fetchNextPage={fetchNextData}
              values={[]} // Use the actual data here
              fixedColumn={['doorName']}
              totalRecords={0}
              height={411}
            />
          </CardBox>
        </div>
      </div>
    </div>
  );
}

export default Map;
