/* eslint-disable */
import React, { useState, useEffect } from 'react';
// Use Translation
import { useTranslation } from 'react-i18next';

import PersonalApis from '../../../utils/api/person';
// Use shared components
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';

// / Import the helper function
import { fileDownload, getCurrentDateTimeWithTimezone } from '../../../utils/helpers'; // Path based on your project structure
// Use interface
import { ExportDataInterFace } from '../../../interface/common.interface';

// Use svg icons
import { EyeCloseIcon, EyeOpenIcon } from '../../../assets/svgicons/svgicon';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices'; // Import the notification action

function ExportBiometricTemplate({ closeModal }: ExportDataInterFace) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [disableButton, setDisableButton] = useState(true);
  const [submitButton, setSubmitButton] = useState(false);
  const [type, setType] = useState('password');
  const [exportValue, setExportValue] = useState({
    fileType: '',
    exportType: 'all',
    fileEncryption: 'no',
    password: '',
  });

  const onChangeValue = (name: string, value: any) => {
    setExportValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    let i = 0;
    if (exportValue.fileType === '' || exportValue.exportType === '') {
      i += 1;
    }
    if (i > 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [exportValue]);

  const submit = async () => {
    setSubmitButton(true);
    setDisableButton(true);
    // Create a new FormData object
    const formData = new FormData();

    // Append the attrs array to the FormData
    formData.append('isEncrypt', exportValue.fileEncryption === 'yes' ? '1' : '0');
    formData.append('reportType', exportValue.fileType.toUpperCase());
    formData.append('tableNameParam', 'Personnel+Biometric+Template');

    // Add logic for recordStart, recordCount, and exportType
    formData.append('exportType', '1');

    try {
      const response: any = await PersonalApis.exportPersBioTemplate(formData);

      if (response.status === 200) {
        const blobData = await response.blob(); // Convert the response to a blob
        const currentDateTime = getCurrentDateTimeWithTimezone(); // Use helper function
        fileDownload(blobData, `All Transactions_${currentDateTime}.${exportValue.fileType}`);
        closeModal();
      } else {
        // Handle error response
        dispatch(
          setNotificationError({
            error: response.message || 'An error occurred',
            status: response.status,
          }),
        );
        throw new Error(`The data is empty and cannot be exported!`);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message || 'An unexpected error occurred',
          status: error.status || 500,
        }),
      );
    } finally {
      setDisableButton(false); // Re-enable the button in both success and error cases
    }
  };
  return (
    <div className="export-data">
      <div className="row row-4">
        <div className="col">
          <SelectBoxField
            name="fileType"
            label={t('fileType')}
            id="fileType"
            value={exportValue.fileType}
            required
            errorStatus={exportValue.fileType === '' && submitButton}
            message={exportValue.fileType === '' ? 'File Type is required' : ''}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              {
                label: t('excel'),
                id: 'xls',
              },
            ]}
            className=""
          />
        </div>
        <div className="col">
          <RadioButtonField
            label={t('dataToExport')}
            name="exportType"
            alignment="column"
            required
            data={[
              {
                label: t('all'),
                id: 'all',
                value: 'all',
              },
            ]}
            value={exportValue.exportType}
            onChangeFunction={onChangeValue}
          />
        </div>
        <div className="col" />
        <div className="col" />
        <div className="col">
          <RadioButtonField
            label={t('fileEncryption')}
            name="fileEncryption"
            alignment="column"
            required
            data={[
              {
                label: t('yes'),
                id: 'yes',
                value: 'yes',
              },
              {
                label: t('no'),
                id: 'no',
                value: 'no',
              },
            ]}
            value={exportValue.fileEncryption}
            onChangeFunction={onChangeValue}
          />
        </div>
        {exportValue.fileEncryption === 'yes' && (
          <div className="col">
            <InputField
              name="password"
              label={t('password')}
              id="password"
              type={type}
              value={exportValue.password}
              required
              innerIconStatus
              InnerIcon={type === 'password' ? EyeCloseIcon : EyeOpenIcon}
              innerIconFunction={() => setType(type === 'password' ? 'text' : 'password')}
              errorStatus={exportValue.fileType === '' && submitButton}
              message={exportValue.fileType === '' ? 'Department Number is required' : ''}
              onChangeFunction={onChangeValue}
            />
          </div>
        )}
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={submit}
            title={t('done')}
            className="btn-primary btn-bold"
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
}

export default ExportBiometricTemplate;
