import React, { useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

// Use own components
import AddArea from './components/add-area';
import {
  deleteArea,
  fetchArea,
  setSearchFilter,
} from '../../../../redux/slices/system-management/AreaSettings';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';

// Use api's calls
import areaSettingsApi from '../../../../utils/api/SystemManagement/areaSettings';

// Use shared components
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import AreaExportData from './components/export-data';
import DeleteModalBox from '../../../../shared-components/delete-modal-box/delete-modal-box';
import AreaImportData from './components/import-data';
import DownloadAreaImportTemplate from './components/download-area-import-template';

// Use assets svg icons
import { AddIcon } from '../../../../assets/svgicons/svgicon';

function AreaSettings() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { areaSettingsList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.area,
  );
  const [selectedData, setSelectedData] = useState<any[]>([]);
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'code',
      header: t('areaNumber'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.code}
        </div>
      ),
    },
    {
      accessorKey: 'name',
      header: t('areaName'),
    },
    {
      accessorKey: 'parentCode',
      header: t('parentAreaNumber'),
    },
    {
      accessorKey: 'parentAreaName',
      header: t('parentAreaName'),
    },
    {
      accessorKey: 'remark',
      header: t('remarks'),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('addNewArea'),
    content: <>hi</>,
  });

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchArea({ ...params }));
    }
  };
  const deleteRecord = (id: string) => {
    areaSettingsApi
      .deleteArea(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('areaDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteArea(id));
          setModalOpen(false);
          fetchData({
            pageNo: searchFilter.pageIndex,
            pageSize: searchFilter.pageSize,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.msg,
            status: error.status,
          }),
        );
      });
  };
  handleEditOpen = (editData: any) => {
    setModalOpen(true);
    setModalView({
      title: t('editDevice'),
      content: (
        <AddArea closeModal={() => setModalOpen(false)} data={{ ...editData, update: true }} />
      ),
    });
    setModalOpen(true);
  };
  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      disabled: true,
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const exportData = () => {
    setModalOpen(false);
  };
  const importData = () => {
    setModalOpen(false);
  };
  const moreActionButton = [
    {
      title: t('export'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('export'),
          content: (
            <AreaExportData closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
      },
    },
    {
      title: t('import'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('import'),
          content: (
            <AreaImportData closeModal={() => setModalOpen(false)} submitFunction={importData} />
          ),
        });
      },
    },
    {
      title: t('downloadDepartmentImportTemplate'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('import'),
          content: (
            <DownloadAreaImportTemplate
              closeModal={() => setModalOpen(false)}
              submitFunction={importData}
            />
          ),
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('system')}
        broadCram={[
          {
            title: t('systemManagement'),
          },
          {
            title: t('areaSettings'),
          },
        ]}
      />
      <CardBox
        title={t('listOfArea')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('add')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('add'),
                content: <AddArea closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          enablePagination
          assignFilterValue={searchFilter}
          assignSelectedData={selectedData}
          getSelectedData={(val: any) => setSelectedData(val)}
          checkBoxActive
          values={areaSettingsList}
          fixedColumn={['code']}
          isFetching={status === 'loading'}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default AreaSettings;
