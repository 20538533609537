import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import ListDropdown from '../../shared-components/list-dropdown/list-dropdown';

import { controlOptions, exportOptions, importOptions } from './components/levels-options';
// Use assets svg icons
import ConformationBox from '../../shared-components/conformation-box/conformation-box';

import { AddIcon } from '../../assets/svgicons/svgicon';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import RemoteOpeningModal from './components/remote-opening';
import RemoteClosingModal from './components/remote-closing';
import CancelAlarmModal from './components/cancel-alarm';
import RemoteNormallyOpen from './components/remote-normally-open';
import ActivateLockdown from './components/activate-lockdown';
import DeActivateLockdown from './components/deactivate-lockdown';
import EnableIntradayPassageMode from './components/enable-Intraday-passage-mode';
import DisableIntradayPassageMode from './components/disable-Intraday-passage-mode';

// Import AddLevel component (assuming it's defined elsewhere)
import ExportAccessLevel from './components/export-access-level';
import ExportDoorAccessLevel from './components/export-door-access-levels';
import ImportAccessLevel from './components/import-access-level';
import ImportDoorsAccessLevel from './components/import-doors-access-levels';
import AddLevelModal from './components/add-levels';
import {
  deleteLevelDoor,
  fetchAccessLevels,
  fetchLevelDoor,
  resetLevels,
  setDoorSearchFilter,
  setSearchFilter,
  // deleteLevels,
} from '../../redux/slices/AccessLevelSlices';
import { AddLevelProps, DeleteLevelProps } from '../../interface/accessLevel.interface';
import accessLevel from '../../utils/api/AccessRule/accessLevel';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import AddDoor from './components/add-doors';

interface LevelApiProps {
  accLevelId: string;
}
function AccessLevels() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    levels,
    status,
    totalDataCount,
    searchFilter,
    doors,
    doorStatus,
    doorTotalDataCount,
    doorSearchFilter,
  } = useAppSelector((state: any) => state.accessLevel);
  const [selectedData, setSelectedData] = useState<AddLevelProps[]>([]); // Add state to hold selected cards
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedDoor, setSelectDoor] = useState<string[]>([]);
  const [selectedCards, setSelectedCards] = useState<string[]>([]); // Add state to hold selected cards
  let handleEditOpen: any;
  let submitLevel: any;

  const accessLevelColumnsField = [
    {
      accessorKey: 'name',
      header: t('levelName'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'authAreaName',
      header: t('areaName'),
    },
    {
      accessorKey: 'timeSegName',
      header: t('timeZone'),
    },
    {
      accessorKey: 'doorCount',
      header: t('doorCount'),
    },
  ];

  const doorColumnsField = [
    {
      accessorKey: 'name',
      header: t('doorName'),
    },
    {
      accessorKey: 'devName',
      header: t('ownedDevice'),
    },
  ];

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchAccessLevels({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const fetchDoor = async (params: LevelApiProps) => {
    if (params.accLevelId) {
      dispatch(fetchLevelDoor({ ...params }));
    }
  };
  const getLevelFilter = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setDoorSearchFilter(filter));
      fetchDoor({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const handleSelectPerson = (selectedIds: string[]): void => {
    setSelectedCards(selectedIds);
    // using at() method to get the last index data in string
    const lastSelectedId = selectedIds.at(-1);
    if (selectedIds.length === 1 && lastSelectedId) {
      fetchDoor({ accLevelId: lastSelectedId });
    } else if (selectedIds.length === 0) {
      setSelectedData([]);
      dispatch(resetLevels());
    }
  };

  const deleteLevel = (params: DeleteLevelProps) => {
    const req = {
      ids: params.id,
      pin: params.pin,
    };
    accessLevel
      .deleteLevel(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('levelDeletedSuccessfully'),
              status: 200,
            }),
          );
          setModalOpen(false);
          fetchData({
            pageNo: searchFilter.pageIndex,
            pageSize: searchFilter.pageSize,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };

  const deleteDoor = (params: DeleteLevelProps) => {
    const req = {
      doorIds: params.id,
      levelId: selectedCards?.[0],
    };
    accessLevel
      .deleteDoor(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('doorDeletedSuccessfully'),
              status: 200,
            }),
          );
          setModalOpen(false);
          dispatch(deleteLevelDoor(params.id));
          // fetchData({ pageNo: 1 });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };

  const handleClickControl = (value: string = '') => {
    if (selectedCards.length > 0) {
      if (value === 'remoteOpening') {
        setModalOpen(true);
        setModalView({
          title: t('remoteOpening'),
          content: (
            <RemoteOpeningModal closeModal={() => setModalOpen(false)} selectedId={selectedCards} />
          ),
        });
      } else if (value === 'remoteClosing') {
        setModalOpen(true);
        setModalView({
          title: t('remoteClosing'),
          content: (
            <RemoteClosingModal closeModal={() => setModalOpen(false)} selectedId={selectedCards} />
          ),
        });
      } else if (value === 'cancelAlarm') {
        setModalOpen(true);
        setModalView({
          title: t('cancelAlarm'),
          content: (
            <CancelAlarmModal closeModal={() => setModalOpen(false)} selectedId={selectedCards} />
          ),
        });
      } else if (value === 'remoteNormallyOpen') {
        setModalOpen(true);
        setModalView({
          title: t('remoteNormallyOpen'),
          content: (
            <RemoteNormallyOpen closeModal={() => setModalOpen(false)} selectedId={selectedCards} />
          ),
        });
      } else if (value === 'activateLockdown') {
        setModalOpen(true);
        setModalView({
          title: t('activateLockdown'),
          content: (
            <ActivateLockdown closeModal={() => setModalOpen(false)} selectedId={selectedCards} />
          ),
        });
      } else if (value === 'deActivateLockdown') {
        setModalOpen(true);
        setModalView({
          title: t('deActivateLockdown'),
          content: (
            <DeActivateLockdown closeModal={() => setModalOpen(false)} selectedId={selectedCards} />
          ),
        });
      } else if (value === 'enableIntradayPassageMode') {
        setModalOpen(true);
        setModalView({
          title: t('enableIntradayPassageMode'),
          content: (
            <EnableIntradayPassageMode
              closeModal={() => setModalOpen(false)}
              selectedId={selectedCards}
            />
          ),
        });
      } else if (value === 'disableIntradayPassageMode') {
        setModalOpen(true);
        setModalView({
          title: t('disableIntradayPassageMode'),
          content: (
            <DisableIntradayPassageMode
              closeModal={() => setModalOpen(false)}
              selectedId={selectedCards}
            />
          ),
        });
      }
    } else {
      setModalOpen(true);
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
    }
  };

  const changeControl = (value: any) => {
    handleClickControl(value);
  };

  const exportData = (data: any) => {
    console.log(data);
  };

  const importData = (data: any) => {
    console.log(data);
  };

  const handleClickExport = (value: string = '') => {
    if (selectedCards.length > 0) {
      if (value === 'exportAccessLevel') {
        setModalOpen(true);
        setModalView({
          title: t('exportAccessLevel'),
          content: (
            <ExportAccessLevel closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
      } else if (value === 'exportDoorsOfAccessLevel') {
        setModalOpen(true);
        setModalView({
          title: t('exportDoorsOfAccessLevel'),
          content: (
            <ExportDoorAccessLevel
              closeModal={() => setModalOpen(false)}
              selectedId={selectedCards}
            />
          ),
        });
      }
    } else {
      setModalOpen(true);
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
    }
  };
  const handleClickImport = (value: string = '') => {
    if (selectedCards.length > 0) {
      if (value === 'importAccessLevel') {
        setModalOpen(true);
        setModalView({
          title: t('importAccessLevel'),
          content: (
            <ImportAccessLevel closeModal={() => setModalOpen(false)} submitFunction={importData} />
          ),
        });
      } else if (value === 'importDoorsOfAccessLevel') {
        setModalOpen(true);
        setModalView({
          title: t('importDoorsOfAccessLevel'),
          content: (
            <ImportDoorsAccessLevel
              closeModal={() => setModalOpen(false)}
              submitFunction={exportData}
            />
          ),
        });
      }
    } else {
      setModalOpen(true);
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
    }
  };

  const changeExport = (value: any) => {
    handleClickExport(value);
  };

  const changeImport = (value: any) => {
    handleClickImport(value);
  };

  handleEditOpen = (editData: AddLevelProps) => {
    setModalOpen(true);
    setModalView({
      title: t('editAddLevel'),
      content: (
        <AddLevelModal
          closeModal={() => setModalOpen(false)}
          update={editData}
          submitLevel={submitLevel}
        />
      ),
    });
  };

  const handleAddLevel = (levelId: string) => {
    setModalView({
      title: t('addDoor'),
      content: <AddDoor closeModal={() => setModalOpen(false)} levelId={levelId} />,
    });
    setModalOpen(true);
  };

  submitLevel = async (levelDetails: AddLevelProps, update: AddLevelProps) => {
    try {
      const response = await accessLevel.addLevel(levelDetails);
      if (response?.data?.success) {
        const message =
          update && Object.keys(update).length > 0
            ? t('addLevelUpdatedSuccessfully')
            : t('addLevelCreatedSuccessfully');
        dispatch(
          setNotificationError({
            error: message,
            status: 200,
          }),
        );
        if (update && Object.keys(update).length > 0) {
          setModalOpen(false);
        } else {
          setModalView({
            title: t('prompt'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => handleAddLevel(response?.data?.data?.id)}
                title={t('immediatelyAddDoorsToTheCurrentAccessControlLevel')}
                buttonLabel={t('ok')}
                cancelTrue
              />
            ),
          });
          setModalOpen(true);
        }
        dispatch(
          fetchAccessLevels({
            pageNo: searchFilter?.pageIndex || 1,
            pageSize: searchFilter?.pageSize || 10,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: AddLevelProps) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      disabled: true,
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val}
              okFunction={deleteLevel}
            />
          ),
        });
      },
    },
    {
      label: t('addDoor'),
      function: (val: AddLevelProps) => handleAddLevel(val.id),
    },
  ];

  const doorActionButtons = [
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val}
              okFunction={deleteDoor}
            />
          ),
        });
      },
    },
  ];

  const moreActionButton = [
    {
      dropdown: (
        <ListDropdown
          data={controlOptions}
          label={t('doorControl')}
          changeFunction={changeControl}
          disabled={selectedCards.length > 0}
        />
      ),
      clickFunction: handleClickControl,
    },
    {
      dropdown: (
        <ListDropdown
          data={exportOptions}
          label={t('export')}
          changeFunction={changeExport}
          disabled={selectedCards.length > 0}
        />
      ),
      clickFunction: handleClickExport,
    },
    {
      dropdown: (
        <ListDropdown
          data={importOptions}
          label={t('Import')}
          changeFunction={changeImport}
          disabled={selectedCards.length > 0}
        />
      ),
      clickFunction: handleClickImport,
    },
  ];
  const selectedLevelName = selectedData.slice(selectedData.length - 1);
  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('accessLevels'),
          },
        ]}
      />
      <CardBox
        title={t('listOfAccessLevels')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: (
                  <AddLevelModal closeModal={() => setModalOpen(false)} submitLevel={submitLevel} />
                ),
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={accessLevelColumnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedCards} // Assign selected cards
          getSelectedData={(val: any): any => handleSelectPerson(val)} // Set selected cards
          getSelectedFullData={(val: AddLevelProps[]): any => setSelectedData(val)}
          checkBoxActive
          singleRowSelection
          enablePagination
          isFetching={status === 'loading'}
          values={levels}
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
        <h4>Browse Personnel ({selectedLevelName?.[0]?.name}) From Levels</h4>
        <div className="doorlist">
          <p>List Of Doors</p>
        </div>
        <DataTable
          action={doorActionButtons}
          columns={doorColumnsField}
          values={doors}
          actionButtonStatus={!modalOpen}
          getFilterData={getLevelFilter}
          assignFilterValue={doorSearchFilter}
          assignSelectedData={selectedDoor} // Assign selected cards
          getSelectedData={(val: any): any => setSelectDoor(val)} // Set selected cards
          checkBoxActive
          // fetchNextPage={() => fetchNextData('door')}
          isFetching={doorStatus === 'loading'}
          fixedColumn={['name']}
          totalRecords={doorTotalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default AccessLevels;
