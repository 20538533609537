import React, { useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use redux functions
import {
  deletePosition,
  fetchPositionList,
  setSearchFilter,
} from '../../redux/slices/PositionSlices';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

// Use api's calls
import positionApis from '../../utils/api/position';

// Use own components
import AddPosition from './components/add-position';
import PositionExportData from './components/export-data';
import ImportData from './components/import-data';
import ImportTemplateDownload from './components/import-template-download';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import TabModalBox from '../../shared-components/tab-modal-box/tab-modal-box';

// Use interface
import { PositionReduxInterface } from '../../interface/position.interface';

// Use assets svg icons
import { AddIcon } from '../../assets/svgicons/svgicon';

function Position() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { positions, status, totalDataCount, searchFilter }: PositionReduxInterface =
    useAppSelector((state: any) => state.positions);
  let handleEditOpen: any;

  const columnsField = [
    {
      accessorKey: 'code',
      header: t('positionNumber'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.code}
        </div>
      ),
    },
    {
      accessorKey: 'name',
      header: t('positionName'),
    },
    {
      accessorKey: 'parentCode',
      header: t('parentPositionNumber'),
    },
    {
      accessorKey: 'parentName',
      header: t('parentPositionName'),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [tabModalOpen, setTabModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('addNewPosition'),
    content: <>hi</>,
  });

  const fetchPositionData = (params: any) => {
    if (status === 'success') {
      dispatch(fetchPositionList({ ...params }));
    }
  };

  const deleteRecord = (id: string) => {
    positionApis
      .deletePosition(id)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('positionDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deletePosition(id));
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  handleEditOpen = (editData: any) => {
    const updateData = {
      ...editData,
      parentCode: editData?.parentId,
    };
    setModalOpen(true);
    setModalView({
      title: t('editPosition'),
      content: (
        <AddPosition
          closeModal={() => setModalOpen(false)}
          data={{ ...updateData, update: true }}
        />
      ),
    });
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deletePosition'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const exportData = () => {
    setTabModalOpen(false);
  };

  const moreActionButton = [
    {
      title: t('export'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('exportPosition'),
          content: (
            <PositionExportData
              closeModal={() => setModalOpen(false)}
              submitFunction={exportData}
            />
          ),
        });
      },
    },
    {
      title: t('import'),
      clickFunction: (): void => {
        setTabModalOpen(true);
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchPositionData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  return (
    <div className="position">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TabModalBox
        status={tabModalOpen}
        closeModal={() => setTabModalOpen(false)}
        title={t('import')}
        tabs={[
          {
            title: t('importPosition'),
            content: <ImportData closeModal={() => setTabModalOpen(false)} />,
            key: 'importPosition',
          },
          {
            title: t('downloadPositionImportTemplate'),
            content: <ImportTemplateDownload closeModal={() => setTabModalOpen(false)} />,
            key: 'downloadTemplate',
          },
        ]}
      />
      <TopHeader
        title={t('position')}
        broadCram={[
          {
            title: t('personnel'),
          },
          {
            title: t('position'),
          },
        ]}
      />
      <CardBox
        title={t('listOfPosition')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('add')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('addNewPosition'),
                content: <AddPosition closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          enablePagination
          assignFilterValue={searchFilter}
          assignSelectedData={[]}
          getSelectedData={(val: any): any => console.log(val)}
          checkBoxActive
          isFetching={status === 'loading'}
          values={positions}
          fixedColumn={['code']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default Position;
