import React, { useState, useEffect } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';

//  Import the helper function
import { fileDownload, getCurrentDateTimeWithTimezone } from '../../../utils/helpers'; // Path based on your project structure
// Use assets svg icons
import { EyeCloseIcon, EyeOpenIcon } from '../../../assets/svgicons/svgicon';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices'; // Import the notification action
import accessLevel from '../../../utils/api/AccessRule/accessLevel';

interface ModalType {
  closeModal: () => void;
  selectedId: string[];
}
function ExportDoorAccessLevel({ closeModal, selectedId }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [disableButton, setDisableButton] = useState(true);
  const [submitButton] = useState(false);
  const [type, setType] = useState('password');
  const [exportValue, setExportValue] = useState({
    fileType: 'XLS',
    exportType: 'all',
    startPosition: '',
    endPosition: '',
    fileEncryption: 'no',
    password: '',
  });

  const onChangeValue = (name: string, value: any) => {
    setExportValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // API Integration
  const submit = () => {
    setDisableButton(true);
    const levelIds = selectedId.map((id) => `levelIds=${id}`).join('&');
    const request = {
      levelIds,
      tableNameParam: 'Doors Information of Access Level',
    };
    // Call the API using formData
    accessLevel
      .exportLevelDoor(request)
      .then((response) => {
        if (response.status === 400) {
          dispatch(
            setNotificationError({
              error: 'The data set is empty and cannot be exported!',
              status: response.status,
            }),
          );
          setDisableButton(false);
          return null;
        }

        return response.blob();
      })
      .then((data: Blob | null) => {
        if (!data) return;
        setDisableButton(false);

        // Process the Blob and trigger file download
        const currentDateTime = getCurrentDateTimeWithTimezone(); // Use helper function
        fileDownload(data, `doorAccessLevel_${currentDateTime}.${exportValue.fileType}`);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.msg,
            status: error.status,
          }),
        );
        setDisableButton(false);
      });
  };

  useEffect(() => {
    let i = 0;
    if (exportValue.fileType === '' || exportValue.exportType === '') {
      i += 1;
    }
    if (i > 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [exportValue]);
  return (
    <div className="export-door-data">
      <div className="row rows-4">
        <div className="col">
          <SelectBoxField
            name="fileType"
            label={t('fileType')}
            id="fileType"
            value={exportValue.fileType}
            required
            errorStatus={exportValue.fileType === '' && submitButton}
            message={exportValue.fileType === '' ? 'File Type is required' : ''}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('excel'), id: 'XLS' },
              { label: t('pdf'), id: 'PDF' },
              { label: t('csv'), id: 'CSV' },
            ]}
            className=""
          />
        </div>
        <div className="col">
          <RadioButtonField
            label={t('dataToExport')}
            name="exportType"
            alignment="column"
            data={[
              {
                label: t('all(max 100000 records)'),
                id: 'All (max 100000 records)',
                value: 'All (max 100000 records)',
              },
            ]}
            value={exportValue.exportType}
            onChangeFunction={onChangeValue}
          />
        </div>
      </div>
      <div className="col">
        <RadioButtonField
          label={t('fileEncryption')}
          name="fileEncryption"
          alignment="column"
          required
          data={[
            { label: t('yes'), id: 'yes', value: 'yes' },
            { label: t('no'), id: 'no', value: 'no' },
          ]}
          value={exportValue.fileEncryption}
          onChangeFunction={onChangeValue}
        />
      </div>
      {exportValue.fileEncryption === 'yes' && (
        <div className="col">
          <InputField
            name="password"
            label={t('password')}
            id="password"
            type={type}
            value={exportValue.password}
            innerIconStatus
            InnerIcon={type === 'password' ? EyeCloseIcon : EyeOpenIcon}
            innerIconFunction={() => setType(type === 'password' ? 'text' : 'password')}
            onChangeFunction={onChangeValue}
          />
        </div>
      )}
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button onClickFunction={closeModal} title={t('back')} className="btn-default" />
          <Button
            onClickFunction={submit}
            title={t('done')}
            className="btn-primary"
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
}

export default ExportDoorAccessLevel;
