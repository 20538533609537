import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SwapTable from '../../../shared-components/swapTables/swapTable';
import Button from '../../../shared-components/button/button';
import accessByDepartment from '../../../utils/api/AccessRule/accessByDepartment';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { SelectLevelInterface } from '../../../interface/accessByDepartment.interface';

interface AddToDefaultLevelProps {
  closeModal: () => void;
  deptId: string;
}
function AddToDefaultLevels({ closeModal, deptId }: AddToDefaultLevelProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [timeZone, setTimeZone] = useState<SelectLevelInterface[]>([]); // Available timeZone
  const [selectedTimeZone, setSelectedTimeZone] = useState<SelectLevelInterface[]>([]); // Selected timeZone
  const [search, setSearch] = useState('');
  const tableColumns = [
    { label: t('levelName'), id: 'levelName' },
    { label: t('timeZone'), id: 'timeSegName' },
  ];
  const fetchDeptLevel = (params: any) => {
    accessByDepartment
      .getDeptSelectLevel(params)
      .then((res) => {
        setTimeZone(res.data.data);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };
  const changeSearch = (name: string, val: any) => {
    setSearch(val);
  };
  // Function to handle table swapping
  const onTableSwap = (
    newAvailableData: SelectLevelInterface[],
    newSelectedData: SelectLevelInterface[],
  ) => {
    setTimeZone(newAvailableData); // Update available data
    setSelectedTimeZone(newSelectedData); // Update selected data
  };
  const submit = () => {
    const req = {
      deptId,
      immeUpdate: true,
      levelIds: selectedTimeZone.map((item: any) => item.id).join(','),
    };
    accessByDepartment
      .addToLevel(req)
      .then((response) => {
        if (response.data.success) {
          const message = t('levelAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };
  useEffect(() => {
    fetchDeptLevel({
      deptId,
      type: 'noSelected',
    });
  }, [deptId]);
  return (
    <div className="access-rule-add-personnel flex-row column">
      <SwapTable
        searchValue={search}
        changeSearch={changeSearch}
        tableColumns={tableColumns}
        tableData={timeZone}
        selectedTableData={selectedTimeZone}
        onTableSwap={onTableSwap}
      />
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={selectedTimeZone.length === 0}
        />
      </div>
    </div>
  );
}

export default AddToDefaultLevels;
