/* eslint-disable */
import React, { useState } from 'react';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses, TreeItemProps } from '@mui/x-tree-view/TreeItem';
import { TreeNodeInterface } from '../../interface/map.interface';
import { isIdNotInEnd } from '../form/validation';
// import { DownArrowIcon } from '../../assets/svgicons/svgicon';

const CustomTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} />)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
  },
  // Fix selected state styling
  [`& .${treeItemClasses.content}.Mui-selected`]: {
    backgroundColor: 'lightblue', // Change this color
  },
}));

// function ExpandIcon() {
//   return <DownArrowIcon />;
// }

// function CollapseIcon() {
//   return <DownArrowIcon />;
// }

// function EndIcon() {
//   return <DownArrowIcon />;
// }

// Recursive function to render tree items
// const renderTreeItems = (nodes: any) => {
//   return (
//     <CustomTreeItem itemId={nodes.id} label={nodes.text}>
//       {nodes.item &&
//         nodes.item.length > 0 &&
//         nodes.item.map((child: any) => renderTreeItems(child))}
//     </CustomTreeItem>
//   );
// };

type TreeNode = {
  id: string;
  text: string;
  item?: TreeNode[];
};
const renderTreeItems: any = (nodes: TreeNode[] | TreeNode) => {
  if (Array.isArray(nodes)) {
    return nodes?.map((node) => renderTreeItems(node)); // Map multiple root nodes
  }

  return (
    <CustomTreeItem
      key={nodes?.id}
      itemId={String(nodes?.id)} // Ensure `nodeId` is a string
      label={nodes?.text}>
      {nodes?.item && nodes?.item?.length > 0 ? renderTreeItems(nodes?.item) : null}
    </CustomTreeItem>
  );
};

const theme = createTheme({
  typography: {
    fontFamily: 'Urbanist', // Customize the font family globally
  },
});

export default function TreeViewDropdown({
  listItems,
  onSelect,
  value,
  onSelectValue,
  multiSelect,
  checkboxSelection,
  isExpanded,
}: any) {
  const [selectedNode, setSelectedNode] = useState<any>(multiSelect ? value || [] : value || null);

  function findItemWithFilter(
    listItemsValues: TreeNodeInterface,
    itemId: string,
  ): TreeNodeInterface | null {
    const findInArray = (items: TreeNodeInterface[]): TreeNodeInterface | null => {
      // Use `find` to locate the item at the current level
      const foundItem = items?.find((item) => item?.id === itemId);
      if (foundItem) {
        return foundItem;
      }

      // Use `some` to iterate over children recursively
      let result: TreeNodeInterface | null = null;
      items?.some((item) => {
        result = findInArray(item.item);
        return result !== null; // Stop recursion when an item is found
      });

      return result;
    };

    // Start with the root item's children
    return listItemsValues?.id === itemId ? listItemsValues : findInArray(listItemsValues?.item);
  }

  const handleNodeSelect = (event: React.MouseEvent, itemId: string): void => {
    // Check if the target is a checkbox (or its parent if styled custom)
    const isCheckboxClick =
      event.target instanceof HTMLInputElement && event.target.type === 'checkbox';

    if (isCheckboxClick) {
      if (multiSelect) {
        setSelectedNode((prev: any) => {
          const prevArray = Array.isArray(prev) ? prev : [];
          const newSelection = prevArray.includes(itemId)
            ? prevArray.filter((id) => id !== itemId)
            : [...prevArray, itemId];

          onSelect(newSelection); // Pass array of selected IDs for multiSelect
          return newSelection;
        });
      }
    } else {
      const isParentNode = (id: string) => id.includes('_');
      // ✅ Check if it's a parent node
      if (isParentNode(itemId)) {
        // Parent node clicked, ignoring selection
        return;
      }
      // Handle arrow or other non-checkbox clicks
      event.stopPropagation();
      setSelectedNode(itemId);
      onSelect(itemId);
    }
    if (isIdNotInEnd(itemId)) {
      const matchedItem = findItemWithFilter(listItems, itemId);
      onSelectValue(matchedItem);
    }
  };

  const getDefaultExpandedItems = (nodes: TreeNodeInterface[]): string[] => {
    let expandedItems: string[] = [];

    // Check if nodes is an array
    if (Array.isArray(nodes)) {
      nodes.forEach((node) => {
        expandedItems.push(node?.id); // Add current node ID for expansion
        if (node?.item && node?.item?.length > 0) {
          expandedItems = expandedItems.concat(getDefaultExpandedItems(node?.item)); // Recursively expand children
        }
      });
    }

    return expandedItems;
  };

  const defaultExpandedItems =
    isExpanded && checkboxSelection
      ? getDefaultExpandedItems([listItems])
      : getDefaultExpandedItems(listItems);

  return (
    <ThemeProvider theme={theme}>
      <SimpleTreeView
        multiSelect={multiSelect}
        checkboxSelection={checkboxSelection}
        onItemClick={handleNodeSelect}
        aria-label="customized"
        defaultExpandedItems={defaultExpandedItems}
        expandedItems={defaultExpandedItems}
        selectedItems={selectedNode || value}
        defaultSelectedItems={selectedNode || value}
        sx={{
          overflowX: 'hidden',
          flexGrow: 1,
          maxWidth: 300,
          '& .MuiTreeItem-content.Mui-selected': {
            backgroundColor: checkboxSelection ? 'transparent' : 'inherit', // Change to desired color
          },
        }}>
        {renderTreeItems(listItems)}
      </SimpleTreeView>
    </ThemeProvider>
  );
}
