/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Use apis calls
import positionsApi from '../../utils/api/position';

// Use other slices
import { setNotificationError } from './NotificationSlices';

// Use interface
import { PositionReduxInterface } from '../../interface/position.interface';

// Use helper functions
import { changeDateTimeFormat } from '../../utils/helpers';

// Use get table position list
export const fetchPositionList = createAsyncThunk(
  'position/fetchPositionList',
  async (data: any, { dispatch }) => {
    const response = await positionsApi
      .getPositions(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data.map((val: any) => ({
              ...val,
              id: val.id,
              creationDate: changeDateTimeFormat(val.createTime),
            })),
            size: response1.data.data.total,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

// Use get all position list
export const fetchAllPositionList = createAsyncThunk(
  'position/fetchAllPositionList',
  async (data: any, { dispatch }) => {
    const response = await positionsApi
      .getAllPositions()
      .then((response1) => {
        return {
          ...response1.data,
          data: response1.data.data.map((val: any) => ({
            id: val.id,
            label: val.name,
          })),
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState: PositionReduxInterface = {
  positions: [],
  allPositions: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const positionSlice: any = createSlice({
  name: 'position',
  initialState,
  reducers: {
    addPosition: (state, action: any) => {
      if (state.positions.find((val) => val.code === action.payload.code)) {
        state.positions = state.positions.map((val) => {
          let output = val;
          if (val.code === action.payload.code) {
            output = action.payload;
            output.id = action.payload.code;
            output.creationDate = changeDateTimeFormat(action.payload.createTime);
          }
          return output;
        });

        state.allPositions = state.allPositions.map((val) => {
          let output = val;
          if (val.id === action.payload.id) {
            output = {
              label: action.payload.name,
              id: action.payload.code,
            };
          }
          return output;
        });
      } else {
        state.positions = [
          {
            ...action.payload,
            id: action.payload.code,
            creationDate: changeDateTimeFormat(action.payload.createTime),
          },
          ...state.positions,
        ];
        state.allPositions = [
          ...state.allPositions,
          {
            label: action.payload.name,
            id: action.payload.code,
          },
        ];
        state.totalDataCount += 1;
        if (state.positions.length > 10) {
          state.positions.pop();
        }
      }
    },
    deletePosition: (state, action: any) => {
      state.positions = state.positions.filter((val) => val.code !== action.payload);
      state.totalDataCount -= 1;
      state.allPositions = state.allPositions.filter((val) => val.id !== action.payload);
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchPositionList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchPositionList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          state.positions = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchPositionList.rejected, (state: any) => {
        state.status = 'success';
      });
    builder
      .addCase(fetchAllPositionList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchAllPositionList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.allPositions = action.payload.data;
        }
        state.status = 'success';
      })
      .addCase(fetchAllPositionList.rejected, (state: any) => {
        state.status = 'success';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, addPosition, deletePosition } = positionSlice.actions;

export default positionSlice.reducer;
