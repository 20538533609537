import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon } from '../../../assets/svgicons/svgicon';
import DeleteModalBox from '../../../shared-components/delete-modal-box/delete-modal-box';
import AddZone from './components/AddZone';
import RulesApply from './components/RulesApply';
import { deleteZone, fetchZone, setSearchFilter } from '../../../redux/slices/ZoneSlices';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';
import zone from '../../../utils/api/AdvancedFunctions/zone';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
// import AddHolidays from './components/add-holidays';

function ZoneList() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { zoneList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.zone,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'code',
      header: t('zone'),
      Cell: ({ row }: any) => (
        <span
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.code}
        </span>
      ),
      size: 150,
    },
    {
      accessorKey: 'name',
      header: t('name'),
    },
    {
      accessorKey: 'remark',
      header: t('remarks'),
    },
  ];

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchZone({ ...params }));
    }
  };

  const deleteRecord = (id: any) => {
    zone
      .deleteZone(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('zoneDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteZone(id));
          setModalOpen(false);
          fetchData({
            pageNo: searchFilter.pageIndex,
            pageSize: searchFilter.pageSize,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          setNotificationError({
            error: error.response.data.msg,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  handleEditOpen = (editData: any) => {
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('edit'),
        content: <AddZone closeModal={() => setModalOpen(false)} update={editData} />,
      });
    }
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      disabled: true,
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteZone'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];
  const moreActionButton = [
    {
      title: t('whatRulesApplyInside'),
      clickFunction: (): void => {
        let modalTitle = '';
        let confirmationMessage = '';

        if (selectedValues.length === 0) {
          modalTitle = t('selectObject');
          confirmationMessage = t('pleaseSelectTheObjectsYouWantToOperate');
        } else if (selectedValues.length > 1) {
          modalTitle = t('selectObject');
          confirmationMessage = t('onlyOneObjectCanBeSelectedAtOneTime');
        } else {
          setModalOpen(true);
          setModalView({
            title: t('whatRulesApplyInside'),
            content: <RulesApply closeModal={() => setModalOpen(false)} id={selectedValues[0]} />,
          });
          return;
        }

        setModalOpen(true);
        setModalView({
          title: modalTitle,
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => setModalOpen(false)}
              title={confirmationMessage}
              buttonLabel={t('ok')}
            />
          ),
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('advancedFunctions'),
          },
          {
            title: t('zone'),
          },
        ]}
      />
      <CardBox
        title={t('listOfZones')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddZone closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          enablePagination
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          checkBoxActive
          values={zoneList}
          fixedColumn={['zone']}
          isFetching={status === 'loading'}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default ZoneList;
