import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import realtimeMonitoring from '../../../utils/api/Devices/realtimeMonitoring';

interface RealTimeCommonPopupProps {
  selected: any;
  allDoorIds?: any;
  closeModal: () => void;
  modelName: string;
  message?: string;
  submitFunction?: (val: any) => void;
}

function RealTimeCommonPopup({
  selected,
  allDoorIds,
  closeModal,
  modelName,
  message,
  submitFunction,
}: RealTimeCommonPopupProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [doors, setDoor] = useState<{ openInterval: number }>({ openInterval: 5 });
  const [disable, setDisable] = useState(false);

  const submit = () => {
    setDisable(true);

    const doorIdsToSend = selected.length > 0 ? selected : allDoorIds;
    const idsToSend = selected.length > 0 ? selected : allDoorIds;

    const operations = {
      remoteOpening: () => realtimeMonitoring.openDoor(doorIdsToSend, doors?.openInterval),
      remoteClosing: () => realtimeMonitoring.closeDoor(doorIdsToSend),
      cancelAlarm: () => realtimeMonitoring.cancelAlarm(doorIdsToSend),
      activateLockDown: () => realtimeMonitoring.lockDoor(doorIdsToSend),
      deActivateLockDown: () => realtimeMonitoring.unLockDoor(doorIdsToSend),
      remoteNormallyOpen: () => realtimeMonitoring.normalOpenDoor(doorIdsToSend),
      enableIntraday: () => realtimeMonitoring.enableNormalOpenDoor(doorIdsToSend),
      disableIntraday: () => realtimeMonitoring.disableNormalOpenDoor(doorIdsToSend),
      remoteOpen: () => realtimeMonitoring.openAuxOut(idsToSend, doors?.openInterval),
      remoteClose: () => realtimeMonitoring.closeAuxOut(idsToSend),
      remoteNormalOpen: () => realtimeMonitoring.auxOutNormalOpen(idsToSend),
    };

    const handleSuccess = (res: any) => {
      dispatch(setNotificationError({ error: t('theOperationSucceeded'), status: 200 }));
      closeModal();
      if (submitFunction && modelName === 'remoteOpening') {
        submitFunction(doors?.openInterval);
      }
      if (res?.data?.code === 0) {
        setTimeout(() => setDisable(false), 3500);
      }
    };

    const handleError = (error: any) => {
      setDisable(false);
      dispatch(
        setNotificationError({
          error: error?.response?.data?.msg || error.message,
          status: error?.status,
        }),
      );
    };

    if (operations[modelName as keyof typeof operations]) {
      operations[modelName as keyof typeof operations]().then(handleSuccess).catch(handleError);
    } else {
      setDisable(false);
      dispatch(
        setNotificationError({
          error: t('operationNotSupported'),
          status: 400,
        }),
      );
    }
  };

  useEffect(() => {
    if (doors.openInterval <= 0 || doors.openInterval > 255) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [doors.openInterval]);

  return (
    <div className="door-modals">
      <div className="flex-row column">
        <h4 className="label">
          {t('target')} : <span>{selected.length > 0 ? t('selected') : t('allDoors')}</span>
        </h4>
        {modelName === 'remoteOpening' || modelName === 'remoteOpen' ? (
          <div className="input-wrap">
            <InputField
              name="door"
              label={t('door')}
              id="name"
              type="number"
              value={doors.openInterval}
              innerIconStatus={false}
              onChangeFunction={(field: string, value: any) =>
                setDoor({ ...doors, openInterval: value })
              }
              errorStatus={doors.openInterval <= 0 || doors.openInterval > 255}
              // validation={doors.openInterval < 0 || doors.openInterval > 255}
              message={t('second(1-254)')}
            />
          </div>
        ) : (
          <span className="message">{message}</span>
        )}
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('cancel')}
            className="btn-default"
          />
          <Button
            onClickFunction={submit}
            title={t('ok')}
            className="btn-primary"
            disabled={disable || !doors.openInterval}
          />
        </div>
      </div>
    </div>
  );
}

RealTimeCommonPopup.defaultProps = {
  message: '',
  submitFunction: () => {},
  allDoorIds: [],
};

export default RealTimeCommonPopup;
