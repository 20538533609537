import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../shared-components/table/table';
import allTransaction from '../../../utils/api/AccControlReports/allTransaction';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { changeDateTimeFormat } from '../../../utils/helpers';

interface ModalType {
  data?: string;
}

function LatestEventsPopup({ data }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [transactionList, setTransactionList] = useState([]);
  useEffect(() => {
    allTransaction
      .getTransactionsTodayLast({ eventPointId: data })
      .then((response) => {
        const result = response.data.data.map((item: any) => ({
          ...item,
          eventTime: changeDateTimeFormat(item.eventTime),
        }));
        setTransactionList(Array.isArray(response.data.data) ? result : []);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setTransactionList([]);
      });
  }, []);
  return (
    <div className="latest-events-popup">
      <div className="table-wrapper">
        <Table
          header={[
            { label: t('eventId'), id: 'eventNo' },
            { label: t('time'), id: 'eventTime' },
            { label: t('areaName'), id: 'areaName' },
            { label: t('deviceName'), id: 'devAlias' },
            { label: t('eventPoint'), id: 'eventPointName' },
            { label: t('eventDescription'), id: 'eventName' },
            { label: t('eventLevel'), id: 'levelAndEventPriority' },
            { label: t('media'), id: 'capturePhotoPath' },
            { label: t('personId'), id: 'pin' },
            { label: t('firstName'), id: 'name' },
            { label: t('lastName'), id: 'lastName' },
            { label: t('cardNumber'), id: 'cardNo' },
            { label: t('departmentNumber'), id: 'deptCode' },
            { label: t('departmentName'), id: 'deptName' },
            { label: t('readerName'), id: 'readerName' },
          ]}
          hideHeader={false}
          value={transactionList} // Display the current issued cards
          textWrap
        />
      </div>
    </div>
  );
}

LatestEventsPopup.defaultProps = {
  data: '',
};

export default LatestEventsPopup;
