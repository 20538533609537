import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SwapTable from '../../../shared-components/swapTables/swapTable';
import Button from '../../../shared-components/button/button';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { SelectLevelInterface } from '../../../interface/accessByDepartment.interface';
import { CardNumberProps } from '../../../interface/personnel.interface';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import reader from '../../../utils/api/Devices/reader';

function AddDoor({ closeModal, submitFunction }: CardNumberProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [door, setDoor] = useState<SelectLevelInterface[]>([]); // Available door
  const [selectedDoor, setSelectedDoor] = useState<SelectLevelInterface[]>([]); // Selected door
  const [value, setValue] = useState({
    search: '',
    cardType: '0',
  });

  const tableColumns = [
    { label: t('readerName'), id: 'name' },
    { label: t('doorName'), id: 'doorName' },
    { label: t('ownedDevice'), id: 'deviceAlias' },
  ];

  const options = [
    {
      label: t('Issue Card From Device'),
      id: '0',
      value: '0',
    },
    {
      label: t('Mobile Credentials'),
      id: '1',
      value: '1',
    },
  ];

  const fetchDoor = (params: any) => {
    reader
      .selectReaderList(params)
      .then((res) => {
        setDoor(res?.data?.data?.data);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const onChangeValue = (name: string, val: any) => {
    setValue((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  // Function to handle table swapping
  const onTableSwap = (
    newAvailableData: SelectLevelInterface[],
    newSelectedData: SelectLevelInterface[],
  ) => {
    setDoor(newAvailableData); // Update available data
    setSelectedDoor(newSelectedData); // Update selected data
  };

  const submit = () => {
    const ids = selectedDoor?.map((select) => select?.id);
    submitFunction(ids);
  };

  useEffect(() => {
    fetchDoor({
      type: 'noSelected',
    });
  }, []);

  return (
    <div className="card-number-modal flex-row column">
      <div className="card-type-wrapper">
        <RadioButtonField
          label=""
          name="cardType"
          alignment="row-wrap"
          data={options}
          value={value?.cardType}
          onChangeFunction={onChangeValue}
        />
      </div>
      <SwapTable
        searchValue={value?.search}
        changeSearch={onChangeValue}
        tableColumns={tableColumns}
        tableData={door}
        selectedTableData={selectedDoor}
        onTableSwap={onTableSwap}
      />
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={selectedDoor.length === 0}
        />
      </div>
    </div>
  );
}

export default AddDoor;
