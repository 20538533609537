/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import InputField from '../../../shared-components/input-field/input-field';
import {
  DoorIcon,
  DoorEditIcon,
  Search,
  DoorOpenIcon,
  OfflineDoorIcon,
} from '../../../assets/svgicons/svgicon';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import RealTimeCommonPopup from './realTime-common-popup';
import ListDropdown from '../../../shared-components/list-dropdown/list-dropdown';
import { OptionType } from '../../../interface/common.interface';
import CardStatus from '../../../shared-components/card-status/cardStatus';
import LatestEventsPopup from './latest-events-popup';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import realtimeMonitoring from '../../../utils/api/Devices/realtimeMonitoring';
import useWebSocket from '../../../utils/webSocket/webSocket';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';
import { rtMonitorOptions } from '../options';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import door from '../../../utils/api/door';
import EditDoor from '../../door/components/edit-door';

interface DoorData {
  status: string;
  alarm: string;
  relay: string;
  doorSensor: string;
  number: string;
  serialNumber: string;
  title: string;
  id: number;
  selected: string[];
  icon: any;
}

interface DoorStatesProps {
  devSn: string;
  no: string;
  image: string;
  relay: string;
  opDisplay: string;
  lockDisplay: string;
  areaId: string;
  devAlias: string;
  name: string;
  alarm: string;
  sensor: string;
  id: string;
  audio: string;
  connect: number;
}

function Door() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [search, setSearch] = useState('');
  const [selectedCards, setSelectedCards] = useState<number[]>([]);
  const [doorData, setDoorData] = useState<DoorData[]>([]);
  const [openInterval, setOpenInterval] = useState('');
  const allDoorIds = doorData.map((item) => item.id);
  const options: OptionType[] = [
    {
      value: 'remoteNormallyOpen',
      label: 'remoteNormallyOpen',
    },
    {
      value: 'enableIntraday',
      label: 'enableIntradayPassageModeTimeZone',
    },
    {
      value: 'disableIntraday',
      label: 'disableIntradayPassageModeTimeZone',
    },
  ];

  const changeDropdown = (value: string = '') => {
    const filteredSelected = doorData
      .filter((item: any) => item.selected !== '')
      .map((ids) => ids.selected)
      .flat();
    if (value === 'remoteNormallyOpen') {
      setModalView({
        title: t('remoteNormallyOpen'),
        content: (
          <RealTimeCommonPopup
            allDoorIds={allDoorIds}
            modelName="remoteNormallyOpen"
            selected={filteredSelected}
            closeModal={() => setModalOpen(false)}
            message={t('areYouWantToPerformTheRemoteNormallyOpenOperation')}
          />
        ),
      });
      setModalOpen(true);
    } else if (value === 'enableIntraday') {
      setModalView({
        title: t('enableIntradayPassageModeTimeZone'),
        content: (
          <RealTimeCommonPopup
            allDoorIds={allDoorIds}
            modelName="enableIntraday"
            selected={filteredSelected}
            closeModal={() => setModalOpen(false)}
            message={t('areYouWantToPerformTheEnableIntradayPassageModeTimeZoneOpenOperation')}
          />
        ),
      });
      setModalOpen(true);
    } else if (value === 'disableIntraday') {
      setModalView({
        title: t('disableIntradayPassageModeTimeZone'),
        content: (
          <RealTimeCommonPopup
            allDoorIds={allDoorIds}
            modelName="disableIntraday"
            selected={filteredSelected}
            closeModal={() => setModalOpen(false)}
            message={t('areYouWantToPerformTheDisableIntradayPassageModeTimeZoneOpenOperation')}
          />
        ),
      });
      setModalOpen(true);
    }
  };

  const changeOption = (value: any) => {
    const filteredSelected = doorData
      .filter((item: any) => item.selected !== '')
      .map((ids) => ids.selected)
      .flat();
    if (filteredSelected.length > 0) {
      changeDropdown(value);
    } else {
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
      setModalOpen(true);
    }
  };

  const handleSubmit = (val: any) => {
    setOpenInterval(val);
  };

  const moreActionButton: any = [
    {
      title: t('remoteOpening'),
      clickFunction: (): void => {
        const filteredSelected = doorData
          .filter((item: any) => item.selected !== '')
          .map((ids) => ids.selected)
          .flat();
        if (filteredSelected.length > 0) {
          setModalView({
            title: t('remoteOpening'),
            content: (
              <RealTimeCommonPopup
                allDoorIds={allDoorIds}
                modelName="remoteOpening"
                selected={filteredSelected}
                closeModal={() => setModalOpen(false)}
                submitFunction={(val: any) => handleSubmit(val)}
              />
            ),
          });
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
        setModalOpen(true);
      },
    },
    {
      title: t('remoteClosing'),
      clickFunction: (): void => {
        const filteredSelected = doorData
          .filter((item: any) => item.selected !== '')
          .map((ids) => ids.selected)
          .flat();
        if (filteredSelected.length > 0) {
          setModalView({
            title: t('remoteClosing'),
            content: (
              <RealTimeCommonPopup
                allDoorIds={allDoorIds}
                modelName="remoteClosing"
                selected={filteredSelected}
                closeModal={() => setModalOpen(false)}
                message={t('areYouWantToPerformTheRemoteClosingOperation')}
              />
            ),
          });
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
        setModalOpen(true);
      },
    },
    {
      title: t('cancelAlarm'),
      clickFunction: (): void => {
        const filteredSelected = doorData
          .filter((item: any) => item.selected !== '')
          .map((ids) => ids.selected)
          .flat();
        if (filteredSelected.length > 0) {
          setModalView({
            title: t('cancelAlarm'),
            content: (
              <RealTimeCommonPopup
                allDoorIds={allDoorIds}
                modelName="cancelAlarm"
                selected={filteredSelected}
                closeModal={() => setModalOpen(false)}
                message={t('areYouWantToPerformTheCancelAlarmOperation')}
              />
            ),
          });
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
        setModalOpen(true);
      },
    },
    {
      title: t('activateLockDown'),
      clickFunction: (): void => {
        const filteredSelected = doorData
          .filter((item: any) => item.selected !== '')
          .map((ids) => ids.selected)
          .flat();
        if (filteredSelected.length > 0) {
          setModalView({
            title: t('activateLockDown'),
            content: (
              <RealTimeCommonPopup
                allDoorIds={allDoorIds}
                modelName="activateLockDown"
                selected={filteredSelected}
                closeModal={() => setModalOpen(false)}
                message={t('areYouWantToPerformTheActivateLockdownOperation')}
                submitFunction={(val: any) => handleSubmit(val)}
              />
            ),
          });
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
        setModalOpen(true);
      },
    },
    {
      title: t('deActivateLockDown'),
      clickFunction: (): void => {
        const filteredSelected = doorData
          .filter((item: any) => item.selected !== '')
          .map((ids) => ids.selected)
          .flat();
        if (filteredSelected.length > 0) {
          setModalView({
            title: t('deActivateLockDown'),
            content: (
              <RealTimeCommonPopup
                allDoorIds={allDoorIds}
                modelName="deActivateLockDown"
                selected={filteredSelected}
                closeModal={() => setModalOpen(false)}
                message={t('areYouWantToPerformTheDeActivateLockdownOperation')}
              />
            ),
          });
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
        setModalOpen(true);
      },
    },
    {
      title: t('personnelIn/OutBoard'),
      clickFunction: (): void => {
        const filteredSelected = doorData
          .filter((item: any) => item.selected !== '')
          .map((ids) => ids.selected)
          .flat();
        if (filteredSelected.length > 0) {
          setModalView({
            title: t('personnelIn/OutBoard'),
            content: (
              <RealTimeCommonPopup
                modelName="personnelIn/OutBoard"
                selected={filteredSelected}
                closeModal={() => setModalOpen(false)}
                message={t('areYouWantToPerformThepersonnelIn/OutBoardOperation')}
              />
            ),
          });
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
        setModalOpen(true);
      },
    },

    {
      dropdown: (
        <ListDropdown data={options} label={t('enable&disable')} changeFunction={changeOption} />
      ),
      clickFunction: changeDropdown,
    },
  ];

  const header = [
    {
      label: t('status'),
      key: 'status',
    },
    {
      label: t('serialNumber'),
      key: 'devSn',
    },
    {
      label: t('number'),
      key: 'no',
    },
    {
      label: t('door'),
      key: 'sensor',
    },
    {
      label: t('relay'),
      key: 'relay',
    },
    {
      label: t('alarm'),
      key: 'alarm',
    },
  ];

  const statusData = [
    {
      title: t('online'),
      count: doorData.filter((item) => item.status === 'Online')?.length || 0,
      color: 'success',
    },
    {
      title: t('offline'),
      count: doorData.filter((item) => item.status === 'Offline')?.length || 0,
      color: 'danger',
    },
    {
      title: t('disable'),
      count: doorData.filter((item) => item.status === 'Disable')?.length || 0,
      color: 'warning',
    },
    {
      title: t('unknown'),
      count: doorData.filter((item) => item.status === 'Unknown')?.length || 0,
      color: 'secondary',
    },
    {
      title: t('currentTotal'),
      count: doorData?.length || 0,
    },
  ];

  const handleEditDoor = async (item: any) => {
    const getDoorData = await door.doorGetById(item.id);
    const updateData = {
      ...getDoorData.data.data,
      isDisableAudio: getDoorData.data.data.isDisableAudio ? ['yes'] : [],
    };
    setModalView({
      title: t('edit'),
      content: <EditDoor closeModal={() => setModalOpen(false)} data={{ ...updateData }} />,
    });
    setModalOpen(true);
  };

  const handleCardSelect = (paramsId: any) => {
    setDoorData((prevData) =>
      prevData.map((doors: any) => {
        if (doors.id === paramsId) {
          const isSelected = doors.selected?.includes(paramsId);

          return {
            ...doors,
            selected: isSelected
              ? doors.selected.filter((id: any) => id !== paramsId).length === 0
                ? '' // Set to empty string if no IDs remain
                : doors.selected.filter((id: any) => id !== paramsId) // Deselect
              : [...(doors.selected || []), paramsId], // Select
          };
        }
        return doors;
      }),
    );

    // Update selectedCards state for other functionality
    setSelectedCards((prevSelected) => {
      if (prevSelected.includes(paramsId)) {
        return prevSelected.filter((id) => id !== paramsId);
      }
      return [...prevSelected, paramsId];
    });
  };

  const clickLatestEvents = (item: any) => {
    setModalView({
      title: t('queryTheLatestEventsFromTheDoor'),
      content: <LatestEventsPopup data={item.id} />,
    });
    setModalOpen(true);
  };

  const { initializeWebSocket: stateWebSocket } = useWebSocket({
    type: 'accRTMonitor/getDevState',
    params: {},
    onMessage: (response) => {
      setDoorData((prevData) =>
        prevData.map((item: any) => {
          const matchingDevice = response.doorStates.find(
            (res: DoorStatesProps) => res.id === item.id,
          );

          const icon = matchingDevice?.connect === 1 ? <DoorIcon /> : <OfflineDoorIcon />;

          return {
            ...item,
            sensor: rtMonitorOptions[matchingDevice?.sensor] || item.sensor,
            title: item.name,
            image: matchingDevice?.image || null,
            relay: rtMonitorOptions[matchingDevice?.relay] || item.relay,
            opDisplay: rtMonitorOptions[matchingDevice?.opDisplay] || item.opDisplay,
            alarm: rtMonitorOptions[matchingDevice?.alarm] || item.alarm,
            status: matchingDevice?.connect === 1 ? 'Online' : 'Offline',
            selected: item.selected ? item.selected : '',
            icon,
          };
        }),
      );
    },
  });

  const getDoorData = () => {
    realtimeMonitoring
      .getAllDoor()
      .then((response) => {
        setDoorData(response.data.data);
        if (response.data.data) {
          const getAreaIds = response.data.data.map((item: any) => item.areaId);
          const areaIds = [...new Set(getAreaIds)].join(',');
          stateWebSocket({ areaIds });
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response.data.status,
          }),
        );
      });
  };

  useEffect(() => {
    getDoorData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const getAreaIds = doorData.map((item: any) => item.areaId);
      const areaIds = [...new Set(getAreaIds)].join(',');
      if (areaIds) {
        stateWebSocket({ areaIds });
      }
    }, 600000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let parseInterval = parseInt(openInterval, 10);

    if (parseInterval > 0) {
      const interval = setInterval(() => {
        parseInterval -= 1;
        if (parseInterval <= 0) {
          setOpenInterval(''); // Clear state when countdown reaches 0
          setSelectedCards([]);
          setDoorData((prevData) =>
            prevData.map((item: any) => {
              if (selectedCards.includes(item.id)) {
                return { ...item, icon: <DoorIcon /> };
              }
              return item;
            }),
          );
          clearInterval(interval);
        } else {
          setDoorData((prevData) =>
            prevData.map((item: any) => {
              if (selectedCards.includes(item.id)) {
                const icon =
                  item.status === 'Online' && parseInterval !== 0 ? <DoorOpenIcon /> : <DoorIcon />;
                return { ...item, icon };
              }
              return item; // Unchanged items remain the same
            }),
          );
          setOpenInterval(String(parseInterval));
        }
      }, 1000);

      return () => clearInterval(interval);
    }
    return undefined;
  }, [openInterval, setDoorData]);

  return (
    <div className="rtm-door">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="search-row">
        <div className="search-input">
          <InputField
            name="search"
            label={t('search')}
            id="name"
            type="text"
            value={search}
            innerIconStatus
            InnerIcon={Search}
            onChangeFunction={(name: string, value: any) => setSearch(value)}
          />
        </div>
        <CardRightButtonGroup
          actions={moreActionButton}
          buttonIcon={false}
          buttonLabel=""
          buttonClass=""
          buttonStatus={false}
          buttonFunction={() => {}}
        />
      </div>
      <div className="card-container">
        <div className="card-grid">
          {doorData.map((item, index) => {
            return (
              <div
                className={`card-item ${item?.selected?.length > 0 ? 'active' : ''}`}
                key={item?.id}>
                <div
                  className="card-item-edit"
                  role="presentation"
                  onClick={() => handleEditDoor(item)}>
                  <DoorEditIcon />
                </div>
                <div className={`card-item-checkbox ${item?.selected?.length > 0 ? 'active' : ''}`}>
                  <CheckBoxInputField
                    name="selected"
                    data={[
                      {
                        label: '',
                        id: item?.id,
                        value: item?.id,
                      },
                    ]}
                    value={item?.selected || []}
                    onChangeFunction={() => handleCardSelect(item.id)}
                  />
                </div>
                <div className="card-item-icon">{item.icon}</div>
                <span>{item.title}</span>
                <div className="card-item-status">
                  <CardStatus
                    key={item.title}
                    data={item}
                    header={header}
                    index={index}
                    click={() => clickLatestEvents(item)}
                    footerLabel={t('queryTheLatestEventsFromTheDoor')}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="status-container">
        {statusData.map((status: any) => (
          <div key={status.title} className="status-wrap">
            {status?.color && <div className={`circle-dot ${status?.color}`} />}
            <span style={{ color: '#696C6F' }}>
              {status?.title} : {status?.count}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Door;
