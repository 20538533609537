import { post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/baseCerType/listByPage`, {}, { ...data });
  },

  saveList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/baseCerType/save`, data, {});
  },

  delCerTypeByIds: (id: string = '') => {
    return post(
      `${process.env.REACT_APP_API_URL}Sys/api/baseCerType/delCerTypeByIds?ids=${id}`,
      {},
    );
  },
};
