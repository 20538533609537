import React, { useState, useEffect } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared component
import SelectBoxField from '../../../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../../../shared-components/button/button';

// Use apis calls
import areaSettingsApi from '../../../../../utils/api/SystemManagement/areaSettings';
// / Import the helper function
import { fileDownload, getCurrentDateTimeWithTimezone } from '../../../../../utils/helpers'; // Path based on your project structure
import { useAppDispatch } from '../../../../../redux/store';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices'; // Import the notification action

function DownloadAreaImportTemplate({
  closeModal,
  submitFunction,
}: {
  closeModal: () => void;
  submitFunction: (val: any) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [disableButton, setDisableButton] = useState(true);

  const selectData = [
    {
      label: t('excel'),
      id: 'xls',
    },
  ];
  const [value, setValue] = useState({
    fileType: '',
    fileList: [],
    updateExistingData: '',
  });

  const onChange = (name: string, changeValue: any) =>
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  const submit = async () => {
    setDisableButton(true);

    areaSettingsApi
      .downloadImportTemplate({})
      .then((response) => response.blob())
      .then((data) => {
        const currentDateTime = getCurrentDateTimeWithTimezone(); // Use helper function
        fileDownload(data, `area import template${currentDateTime}.xls`);
        submitFunction(value);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message || 'An error occurred during file download',
            status: error.response?.status || 500, // Use error.response?.status for better error handling
          }),
        );
        setDisableButton(false); // Re-enable the button in case of an error
      });
  };
  useEffect(() => {
    const isButtonEnabled = value.fileType !== '';
    setDisableButton(!isButtonEnabled);
  }, [value.fileType]);
  return (
    <div className="modal-page">
      <div className="statistics-page rows-2">
        <div className="row">
          <SelectBoxField
            name="fileType"
            label={t('fileType')}
            id="fileType"
            value={value.fileType}
            required
            onChangeFunction={(name, changeValue) => onChange(name, changeValue.id)}
            data={selectData}
            className=""
          />
        </div>
        <div className="item span-2">
          <div className="form-buttons-right">
            <Button onClickFunction={closeModal} title={t('close')} className="btn-default" />
            <Button
              onClickFunction={submit} // Use the submit function here
              title={t('import')}
              className="btn-primary btn-bold"
              disabled={disableButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadAreaImportTemplate;
