import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import { AddIcon } from '../../assets/svgicons/svgicon';
import AddMultiCombination from './components/Add-MultiCombination';
import {
  fetchMultiPersonOpeningDoor,
  setSearchFilter,
  deleteMultiPerson,
} from '../../redux/slices/MultiPersonOpeningDoorSlices';
import multiPersonOpeningDoorApi from '../../utils/api/AccessRule/multiPersonOpeningDoor';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

interface ActionButton {
  label: string;
  function: (val: any) => void;
}

function MultiPersonOpeningDoor() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { multiPersonOpeningDoor, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.multiPersonOpeningDoor,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchMultiPersonOpeningDoor({ ...params }));
    }
  };
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const fetchEditData = async (ids: string) => {
    try {
      const response = await multiPersonOpeningDoorApi.editMultiPerson({ id: ids });
      return response.data.data;
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      return null;
    }
  };
  const handleEditOpen = async (editData: any) => {
    try {
      const response = await fetchEditData(editData.id);
      if (response) {
        const tempListData = response.tempList;
        const groupsData = tempListData.map((item: any) => ({
          groupId: item.accCombOpenPersonId,
          openerNumber: item.openerNumber,
        }));

        const updateData = {
          ...editData,
          id: editData?.id,
          name: editData?.name,
          doorId: editData?.doorId,
          groupIds: groupsData.map((g: any) => g.groupId), // Array of group IDs
          deviceName: editData?.deviceAlias,
          deviceId: editData.deviceId,
          openerNumbers: groupsData.map((g: any) => g.openerNumber), // Array of opener numbers
          doorNo: editData.doorNo,
        };
        setModalOpen(true);
        setModalView({
          title: t('edit'),
          content: <AddMultiCombination closeModal={() => setModalOpen(false)} data={updateData} />,
        });
      }
    } catch (error) {
      dispatch(
        setNotificationError({
          error: t('errorFetchingEditData'),
          status: 500,
        }),
      );
    }
  };
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('combinationName'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'deviceAlias',
      header: t('deviceName'),
    },
    {
      accessorKey: 'doorNo',
      header: t('doorNumber'),
    },
    {
      accessorKey: 'doorName',
      header: t('doorName'),
    },
    {
      accessorKey: 'verifyOneTime',
      header: t('currentPersonnelCount'),
    },
    {
      accessorKey: 'combOpenPersonId',
      header: t('multiPersonGroup'),
      Cell: ({ row }: any) => {
        const [details, setDetails] = useState([]);

        useEffect(() => {
          const fetchDetails = async () => {
            const response = await fetchEditData(row.original.id);
            const result = response?.accCombOpenPersonId
              ?.map((item: any) => item.openerNumber)
              .join(', ');
            setDetails(result);
          };

          fetchDetails();
        }, [row.original.id]);

        return <span>{details}</span>;
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const deleteRecord = (id: any) => {
    multiPersonOpeningDoorApi
      .deleteMultiPerson(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('multiPersonGroupDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteMultiPerson(id));
          setModalOpen(false);
          fetchData({
            pageNo: searchFilter.pageIndex,
            pageSize: searchFilter.pageSize,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const actionButtons: ActionButton[] = [
    {
      label: t('edit'),
      function: (val) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('multiPersonOpeningDoor'),
          },
        ]}
      />
      <CardBox
        title={t('listOfCombination')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddMultiCombination closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)} // Set selected cards
          checkBoxActive
          enablePagination
          values={multiPersonOpeningDoor} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default MultiPersonOpeningDoor;
