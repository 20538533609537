import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import InputField from '../../../shared-components/input-field/input-field';
import Label from '../../../shared-components/label/Label';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';

interface OptionProps {
  id: number | string;
  label: string;
  value: string;
}
interface ModalTypeProps {
  values: any;
  changeValue: (name: string, data: any) => void;
  auxInputs: OptionProps[];
  doorOutputs: OptionProps[];
}

function OutputPoint({ values, changeValue, auxInputs, doorOutputs }: ModalTypeProps) {
  const { t } = useTranslation();

  const [value, setValue] = useState(values);

  const onChangeValue = (name: string, data: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: data,
    }));
    changeValue(name, data);
  };

  return (
    <div className="flex-row column output-point">
      <div className="flex-row linkage-box">
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('door')} />
          </div>
          <div className="box">
            <CheckBoxInputField
              name="doorOutput"
              data={doorOutputs}
              value={value?.doorOutput}
              onChangeFunction={onChangeValue}
              alignment="column"
            />
          </div>
          <SelectBoxField
            id="doorActionType"
            label={t('actionType')}
            value={value?.doorActionType}
            name="doorActionType"
            data={[
              { label: t('close'), id: 0 },
              { label: t('open'), id: 1 },
              { label: t('normallyOpen'), id: 255 },
            ]}
            onChangeFunction={(name, val) => onChangeValue(name, val.id)}
          />
          {value?.doorActionType === 1 && (
            <InputField
              name="doorActionTime"
              label={t('actionTimeDelay')}
              id="doorActionTime"
              type="text"
              value={value.doorActionTime}
              onChangeFunction={onChangeValue}
              validation
              message="s(1-254)"
            />
          )}
        </div>
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('auxiliaryOutput')} />
          </div>
          <div className="box">
            <CheckBoxInputField
              name="outputAuxOutAddr"
              data={auxInputs}
              value={value?.outputAuxOutAddr}
              onChangeFunction={onChangeValue}
              alignment="column"
            />
          </div>
          <SelectBoxField
            id="auxoutActionType"
            label={t('actionType')}
            value={value?.auxoutActionType}
            name="auxoutActionType"
            data={[
              { label: t('close'), id: 0 },
              { label: t('open'), id: 1 },
              { label: t('normallyOpen'), id: 255 },
            ]}
            onChangeFunction={(name, val) => onChangeValue(name, val.id)}
          />
          {value?.auxoutActionType === 1 && (
            <InputField
              name="auxoutActionTime"
              label={t('actionTimeDelay')}
              id="auxoutActionTime"
              type="text"
              value={value.auxoutActionTime}
              onChangeFunction={onChangeValue}
              validation
              message="s(1-254)"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default OutputPoint;
