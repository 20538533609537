import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use own components pages
import AccessControl from './access-control';
import TimeAttendance from './time-attendance';
import ElevatorControl from './elevator-control';
import PassageSetting from './passage-setting';

// Use shared components
import TabPages from '../../../shared-components/tab-pages/tab-pages';

// Use interface
import { PermissionInterface } from '../../../interface/permissions.interface';
import FaceKiosk from './face-kiosk';
import PlateRegister from './plate-register';
import LockerSetting from './locker-setting';
import MoreCards from './more-cards';
import PersonnelDetails from './personnel-details';
// import { useAppDispatch, useAppSelector } from '../../../redux/store';
// import { fetchAuthTree } from '../../../redux/slices/PersonSlices';
import person from '../../../utils/api/person';
import { transformData } from '../../../utils/helpers';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

function Permissions({ onChangeFunction = () => {}, values }: PermissionInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [authTree, setAuthTree] = useState<any[]>([]);
  // const { authTree } = useAppSelector((state: any) => state.person);

  useEffect(() => {
    person
      .authTree()
      .then((response: any) => {
        const transformedData: any = transformData(response?.data);
        setAuthTree(transformedData);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  }, []);

  const tabPages = [
    {
      key: 'accessControl',
      title: t('accessControl'),
      content: <AccessControl onChangeFunction={onChangeFunction} values={values} />,
    },
    {
      key: 'timeAttendance',
      title: t('timeAttendance'),
      content: (
        <TimeAttendance onChangeFunction={onChangeFunction} values={values} areaList={authTree} />
      ),
    },
    {
      key: 'elevatorControl',
      title: t('elevatorControl'),
      content: <ElevatorControl onChangeFunction={onChangeFunction} values={values} />,
    },
    {
      key: 'plateRegister',
      title: t('plateRegister'),
      content: <PlateRegister onChangeFunction={onChangeFunction} values={values} />,
    },
    {
      key: 'passageSetting',
      title: t('passageSetting'),
      content: <PassageSetting onChangeFunction={onChangeFunction} values={values} />,
    },
    {
      key: 'faceKiosk',
      title: t('faceKiosk'),
      content: <FaceKiosk onChangeFunction={onChangeFunction} values={values} />,
    },
    {
      key: 'lockerSetting',
      title: t('lockerSetting'),
      content: <LockerSetting onChangeFunction={onChangeFunction} values={values} />,
    },
    {
      key: 'moreCards',
      title: t('moreCards'),
      content: <MoreCards onChangeFunction={onChangeFunction} values={values} />,
    },
    {
      key: 'personnelDetails',
      title: t('personnelDetails'),
      content: <PersonnelDetails onChangeFunction={onChangeFunction} values={values} />,
    },
  ];
  useEffect(() => {}, [values]);
  return <TabPages tabs={tabPages} defaultNumber={0} />;
}

export default Permissions;
