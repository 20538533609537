import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import SwapTable from '../../../shared-components/swapTables/swapTable';
import map from '../../../utils/api/Devices/map';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { AddDoorModalType, MapDoorInterface } from '../../../interface/map.interface';

function AddDoor({ closeModal, mapId, saveDoor }: AddDoorModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [doors, setDoors] = useState<any[]>([]); // Available doors
  const [selectedDoors, setSelectedDoors] = useState<MapDoorInterface[]>([]); // Selected doors
  const [search, setSearch] = useState('');
  const tableColumns = [
    { label: t('doorName'), id: 'doorName' },
    { label: t('ownedDevice'), id: 'ownedDevice' },
    { label: t('serialNumber'), id: 'deviceSn' },
  ];

  // Function to handle table swapping
  const onTableSwap = (
    newAvailableData: any[], // temp
    newSelectedData: any[], // temp
  ) => {
    setDoors(newAvailableData); // Update available data
    setSelectedDoors(newSelectedData); // Update selected data
  };

  const getMapDoors = async (params: any) => {
    try {
      const response = await map.mapSelectDoor(params);
      if (response) {
        setDoors(response?.data?.data?.data);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const submit = async () => {
    const ids = selectedDoors?.map((item) => item.id).join(',');
    const request = {
      entityIds: ids,
      entityType: 'AccDoor',
      logMethod: 'addDoorToMap',
      mapId,
      width: '40',
    };
    saveDoor(request);
  };

  useEffect(() => {
    getMapDoors({
      mapId,
      type: 'noSelected',
    });
  }, [mapId]);

  return (
    <div className="map-add-door">
      <SwapTable
        searchValue={search}
        changeSearch={(name: string, val: any) => setSearch(val)}
        tableColumns={tableColumns}
        tableData={doors}
        selectedTableData={selectedDoors}
        onTableSwap={onTableSwap}
      />
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={selectedDoors?.length === 0}
        />
      </div>
    </div>
  );
}

export default AddDoor;
