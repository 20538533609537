/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Assuming you have a notification slice for errors
import { setNotificationError } from './NotificationSlices';
import verificationModeGroup from '../../utils/api/AccessRule/verificationModeGroup';
import auth from '../../utils/api/auth';
// import { treeDataFunction } from '../../utils/helpers';

// Thunk for fetching the department list
export const fetchVerifyModeGroup = createAsyncThunk(
  'fetchVerifyModeGroup',
  async (data: any, { dispatch }) => {
    try {
      const response = await verificationModeGroup.getVerifyModeGroupList(data);
      return {
        ...response.data,
        data: {
          list: response.data.data.data,
          size: response.data.data.total,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
      return error;
    }
  },
);

export const fetchPersonsList = createAsyncThunk(
  'fetchPersonsList',
  async (data: any, { dispatch }) => {
    try {
      const response = await verificationModeGroup.getPersonList(data);
      return {
        ...response.data,
        data: {
          list: [...new Set(response.data.data)],
          size: response.data.data.length,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
      return error;
    }
  },
);

export const fetchAreaList = createAsyncThunk(
  'area/fetchAreaList',
  async (data: any, { dispatch }) => {
    const apiResponse = await auth
      .getAuthArea(data)
      .then((response) => {
        return response?.data?.data?.list;
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return apiResponse;
  },
);

interface Rule {
  equals: boolean;
  id: string;
  name: string;
  timeSegName: string;
}

interface LevelProps {
  equals: boolean;
  id: string;
  levelName: string;
  timeSegName: string;
}
// Initial state for the slice
interface StateProps {
  verifyModeGroup: Rule[]; // List of issued cards
  status: string;
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: any;
  persons: LevelProps[];
  personStatus: string;
  personTotalDataCount: number;
  personCurrentPageNo: number;
  personSearchFilter: any;
  timeSegmentList: any[];
  doorVerifyList: any[];
  areaTreeList: [];
}

const initialState: StateProps = {
  verifyModeGroup: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  persons: [],
  personStatus: 'success',
  personTotalDataCount: 0,
  personCurrentPageNo: 0,
  personSearchFilter: {},
  timeSegmentList: [],
  doorVerifyList: [],
  areaTreeList: [],
};

// Create the slice
export const VerifyModeGroupSlice = createSlice({
  name: 'verifyModeGroup',
  initialState,
  reducers: {
    // Reducer to set search filter
    setSearchFilter: (state, action: PayloadAction<any>) => {
      state.searchFilter = action.payload;
    },
    deletePersons: (state, action: PayloadAction<any>) => {
      state.persons = state.persons.filter((val: any) => val.id !== action.payload);
      state.personTotalDataCount -= 1;
    },
    setDoorSearchFilter: (state, action: PayloadAction<any>) => {
      state.personSearchFilter = action.payload;
    },
    resetPersons: (state) => {
      state.persons = [];
    },
  },
  extraReducers: (builder) => {
    // Handle fetchVerifyModeGroup actions
    builder
      .addCase(fetchVerifyModeGroup.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVerifyModeGroup.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.verifyModeGroup = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchVerifyModeGroup.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchPersonsList.pending, (state) => {
        state.personStatus = 'loading';
      })
      .addCase(fetchPersonsList.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.personTotalDataCount = action.payload.data.size;
          state.personCurrentPageNo = action.payload.data.pageNo;

          // For pagination: append data if not the first page
          state.persons = action.payload.data.list;
        }
        state.personStatus = 'success';
      })
      .addCase(fetchPersonsList.rejected, (state) => {
        state.personStatus = 'failed';
      });
    builder.addCase(fetchAreaList.fulfilled, (state: any, action: any) => {
      // Add mapArea to the state array
      state.areaTreeList = action?.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, setDoorSearchFilter, resetPersons, deletePersons } =
  VerifyModeGroupSlice.actions;

export default VerifyModeGroupSlice.reducer;
