import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ParameterInterface,
  ParameterReduxInterface,
} from '../../../../../interface/parameter.interface';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';
import { useAppSelector, useAppDispatch } from '../../../../../redux/store';
import SelectBoxField from '../../../../../shared-components/selectbox-field/selectbox-field';
import { AttentionIcon } from '../../../../../assets/svgicons/svgicon';
import Button from '../../../../../shared-components/button/button';
import dataCleaning from '../../../../../utils/api/SystemManagement/dataCleaning';
import ModalBox from '../../../../../shared-components/modal-box/modal-box';

function System({ onChangeFunction }: ParameterInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { parameters }: ParameterReduxInterface = useAppSelector((state: any) => state.parameters);

  const [localValues, setLocalValues] = useState({
    system: {
      number: '6',
      startTime: '03:00:00',
    },
    Database: {
      number: '6',
      startTime: '04:00:00',
    },
    device: {
      number: '6',
      startTime: '02:00:00',
    },
    ...parameters,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedSection, setSelectedSection] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const executionTimeOptions = [
    { label: '00:00:00', id: '00:00:00' },
    { label: '01:00:00', id: '01:00:00' },
    { label: '02:00:00', id: '02:00:00' },
    { label: '03:00:00', id: '03:00:00' },
    { label: '04:00:00', id: '04:00:00' },
  ];

  const sections = [
    {
      title: 'System Operation Log',
      key: 'system',
      retentionName: 'systemDataCleanOpLogKeptMonth',
      timeName: 'systemDataCleanOpLogTime',
      hasWarning: true,
    },
    {
      title: 'Database Backup File',
      key: 'Database',
      retentionName: 'systemDataCleanDbBakKeptMonth',
      timeName: 'systemDataCleanDbBakTime',
      hasCleanup: true,
    },
    {
      title: 'Device Commands',
      key: 'device',
      retentionName: 'systemDataCleanCmdDataKeptMonth',
      timeName: 'systemDataCleanCmdDataTime',
      hasCleanup: true,
    },
  ];

  const retentionOptions = [
    { label: t('1'), id: '1' },
    { label: t('2'), id: '2' },
    { label: t('3'), id: '3' },
    { label: t('6'), id: '6' },
  ];

  const onChange = (section: string, field: string, changeValue: string | number) => {
    setLocalValues((prev: any) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: changeValue,
      },
    }));
  };

  const handleConfirmCleanup = async () => {
    try {
      setIsLoading(true);
      let name = '';
      if (selectedSection === 'device') {
        name = 'AdmsDevCmd';
      } else if (selectedSection === 'Database') {
        name = 'BaseDbBackup';
      }
      if (name) {
        const requestData: any = {
          keptMonth: '6',
          name,
        };

        if (selectedSection === 'device') {
          requestData.columnName = 'commitTime';
        }

        const response = await dataCleaning.immediateClean(requestData);

        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('areYouSureYouWantToCleanUpDeviceCommandsBefore6Months?'),
              status: 200,
            }),
          );
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
    }
  };
  const handleImmediateCleanup = (section: string) => {
    setSelectedSection(section);
    const messageKey =
      section === 'device'
        ? 'areYouSureYouWantToCleanUpDeviceCommandsBefore6Months?'
        : 'Database Backup Cleanup Confirmation';

    setModalView({
      title: t('Confirm Cleanup'),
      content: (
        <>
          <p>{t(messageKey)}</p>
          <div className="modal-buttons">
            <div className="form-buttons-right">
              <Button
                onClickFunction={() => setModalOpen(false)}
                title={t('cancel')}
                className="btn-default"
                disabled={isLoading}
              />
              <Button
                onClickFunction={handleConfirmCleanup}
                title={t('done')}
                className="btn-primary"
                disabled={isLoading}
              />
            </div>
          </div>
        </>
      ),
    });
    setModalOpen(true);
  };

  useEffect(() => {
    const transformedValues = sections.reduce((acc, section) => {
      return {
        ...acc,
        [section.retentionName]: localValues?.[section.key]?.number || '6',
        [section.timeName]: localValues?.[section.key]?.startTime || '00:00:00',
      };
    }, {});

    onChangeFunction(transformedValues);
  }, [localValues, onChangeFunction]);

  return (
    <div className="data-setting">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />

      <div className="data-setting-form">
        <div className="column">
          {sections.slice(0, 2).map((section) => (
            <div key={section.key} className="item item-1">
              <div className="record-title">{t(section.title)}</div>
              <div className="sub-title">{t('Retains the Recent')}</div>
              <div className="main-container">
                <SelectBoxField
                  id="number"
                  name="number"
                  value={localValues?.[section.key]?.number}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, name, selectBoxValue.id)
                  }
                  data={retentionOptions}
                />
              </div>
              <div className="form-start">
                <div className="sub-title">{t('Execution Time')}</div>
                <SelectBoxField
                  id="startTime"
                  name="startTime"
                  value={localValues?.[section.key]?.startTime}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, name, selectBoxValue.id)
                  }
                  data={executionTimeOptions}
                />
                {section.hasCleanup && (
                  <div className="cleanup-button">
                    <Button
                      title={t('Immediately Clean Up')}
                      className="btn-primary"
                      onClickFunction={() => handleImmediateCleanup(section.key)}
                      disabled={isLoading}
                    />
                  </div>
                )}
                {section.hasWarning && (
                  <div className="item text-danger">
                    <div className="note">{t('Carefully Cleanup')}</div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="column">
          {sections.slice(2).map((section) => (
            <div key={section.key} className="item item-1">
              <div className="record-title">{t(section.title)}</div>
              <div className="sub-title">{t('Retains the Recent')}</div>
              <div className="main-container">
                <SelectBoxField
                  id="number"
                  name="number"
                  value={localValues?.[section.key]?.number}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, name, selectBoxValue.id)
                  }
                  data={retentionOptions}
                />
              </div>
              <div className="form-start">
                <div className="sub-title">{t('Execution Time')}</div>
                <SelectBoxField
                  id="startTime"
                  name="startTime"
                  value={localValues?.[section.key]?.startTime}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, name, selectBoxValue.id)
                  }
                  data={executionTimeOptions}
                />
                {section.hasCleanup && (
                  <div className="cleanup-button">
                    <Button
                      title={t('Immediately Clean Up')}
                      className="btn-primary"
                      onClickFunction={() => handleImmediateCleanup(section.key)}
                      disabled={isLoading}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}

          <div className="text-danger flex-row center">
            <AttentionIcon />
            {t(
              'Cleaning frequency is executed once every day and clean up data before the set number of reserved months. Execution Time refers to the time when the system starts to perform a data clean-up. When you click OK, the system will automatically clean expired system data according to the users settings.',
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default System;
