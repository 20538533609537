import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';
import InputField from '../../../shared-components/input-field/input-field';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import Button from '../../../shared-components/button/button';
import FormRightButton from '../../../shared-components/form-right-buttons/FormRightButton';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import AddMultiSelected from './Add-MultiSelected';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Label from '../../../shared-components/label/Label';
import multiPersonOpeningDoorApi from '../../../utils/api/AccessRule/multiPersonOpeningDoor';
import { fetchMultiPersonOpeningDoor } from '../../../redux/slices/MultiPersonOpeningDoorSlices';

interface MultiCombinationProps {
  closeModal: () => void;
  data?: Device;
  searchFilter?: {
    pageIndex: number;
    pageSize: number;
  };
}

interface Device {
  id: string;
  serialNumber: string;
  doorName?: string;
  ownedDevice: string;
  combinationName: any;
  combOpenPersonId: any;
  deviceId: string;
  doorId: string;
  deviceName: string;
  noOfDoors: number;
  name: string;
  openerNumbers: any;
  groupIds: any;
  personCount: any;
}

interface DropdownItem {
  label: string;
  id: string | number;
}

interface GroupValue {
  id: string;
  groupId: string;
  firstSelect: string;
  secondSelect: string;
  personCount: number;
  valueId: string;
}

function AddMultiCombination({ closeModal, data, searchFilter }: MultiCombinationProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [devices, setDevices] = useState<Device[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [dropdownData, setDropdownData] = useState<DropdownItem[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('accesspersonopening'),
    content: <>hi</>,
  });

  const [levelDetails, setLevelDetails] = useState(
    data || {
      combinationName: '',
      deviceId: '',
      deviceName: '',
      combOpenPersonId: '',
      noOfDoors: 0,
      id: '',
      doorId: '',
      name: '',
      groupIds: '',
      personCount: '',
    },
  );

  const [groupValues, setGroupValues] = useState<GroupValue[]>(
    data?.groupIds
      ? data.groupIds.map((groupId: string, index: number) => ({
          id: '',
          groupId: `group-${index + 1}`,
          firstSelect: groupId,
          secondSelect: '',
          personCount: data.personCount || 0,
          valueId: groupId,
        }))
      : Array(5)
          .fill(null)
          .map((_, index) => ({
            id: '',
            groupId: `group-${index + 1}`,
            firstSelect: '',
            secondSelect: '',
            personCount: 0,
            valueId: '',
          })),
  );

  const optionsData = [
    { label: '1', id: '1' },
    { label: '2', id: '2' },
    { label: '3', id: '3' },
    { label: '4', id: '4' },
    { label: '5', id: '5' },
    { label: '6', id: '6' },
  ];

  useEffect(() => {
    const allGroups = Array(5)
      .fill(null)
      .map((_, index) => ({
        id: '',
        groupId: `group-${index + 1}`,
        firstSelect: '',
        secondSelect: '',
        personCount: 0,
        valueId: '',
      }));

    if (data?.groupIds && data?.openerNumbers) {
      data.groupIds.forEach((groupId: string, index: number) => {
        if (index < 5) {
          allGroups[index] = {
            id: '',
            groupId: `group-${index + 1}`,
            firstSelect: groupId,
            secondSelect: data.openerNumbers[index].toString(),
            personCount: data.openerNumbers[index],
            valueId: groupId,
          };
        }
      });
    }

    setGroupValues(allGroups);
  }, [data]);

  useEffect(() => {
    if (data?.groupIds && data?.openerNumbers) {
      const firstGroupId = data.groupIds[0];
      const firstOpenerNumber = data.openerNumbers[0];
      const selectedItem = dropdownData.find((item) => item.id === firstGroupId);

      if (selectedItem) {
        setLevelDetails((prev) => ({
          ...prev,
          combOpenPersonId: `${selectedItem.label}(${firstOpenerNumber})`,
        }));
      }
    }
  }, [data, dropdownData]);
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await multiPersonOpeningDoorApi.getCombOpenList();
        const formattedData: DropdownItem[] = response.data.data.map(
          (item: { text: string; value: string | number }) => ({
            label: item.text,
            id: item.value,
          }),
        );
        setDropdownData(formattedData);
      } catch (error: unknown) {
        if (error instanceof Error) {
          dispatch(setNotificationError(error.message || t('errorFetchingData')));
        } else {
          dispatch(setNotificationError(t('errorFetchingData')));
        }
      }
    };
    fetchDropdownData();
  }, [dispatch, t]);

  useEffect(() => {
    if (levelDetails?.deviceId) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [levelDetails]);

  const onChangeValue = (name: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const changeSearch = (name: string, val: any) => {
    setSearch(val);
  };

  const onTableSwap = (newAvailableData: Device[], newSelectedData: Device[]) => {
    setDevices(newAvailableData);
    setSelectedDevices(newSelectedData);
  };

  const getProtocols = (id: string) => {
    multiPersonOpeningDoorApi.validBackgroundVerify(id).then(() => {});
  };

  const getSelectedDevice = (selected: any[]) => {
    setLevelDetails((prev: any) => ({
      ...prev,
      deviceId: selected[0].id,
      deviceName: selected[0].deviceAlias,
      doorId: selected[0].selectDoorIdsIn,
    }));
    getProtocols(selected[0].id);
  };

  const addDoor = () => {
    setModalView({
      title: t('selectDoor'),
      content: (
        <AddMultiSelected
          searchValue={search}
          changeSearch={changeSearch}
          tableColumns={[
            { label: t('doorName'), id: 'doorName' },
            { label: t('ownedDevice'), id: 'deviceAlias' },
            { label: t('serialNumber'), id: 'deviceSn' },
          ]}
          tableData={devices}
          selectedTableData={selectedDevices}
          onTableSwap={onTableSwap}
          getSelected={getSelectedDevice}
          closeModal={() => setModalOpen(false)}
        />
      ),
    });
    setModalOpen(true);
  };

  const handleFirstSelectChange = async (groupId: string, valueId: string) => {
    setGroupValues((prev) => {
      const updatedGroups = prev.map((group) =>
        group.groupId === groupId
          ? { ...group, firstSelect: valueId, secondSelect: '', personCount: 0, valueId }
          : group,
      );
      return updatedGroups;
    });
    const selectedItem = dropdownData.find((item) => item.id === valueId);
    if (selectedItem) {
      // Update the combOpenPersonId in levelDetails
      setLevelDetails((prev) => ({
        ...prev,
        combOpenPersonId: selectedItem.label,
      }));
    }
    try {
      const response = await multiPersonOpeningDoorApi.checkPersonCount(valueId);
      if (response.data.success) {
        const { personCount } = response.data.data;
        setGroupValues((prev) => {
          const updatedGroups = prev.map((group) =>
            group.groupId === groupId ? { ...group, personCount } : group,
          );
          return updatedGroups;
        });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(setNotificationError(error.message || t('errorFetchingData')));
      } else {
        dispatch(setNotificationError(t('errorFetchingData')));
      }
    }
  };

  const handleSecondSelectChange = (groupId: string, valueId: string) => {
    const group = groupValues.find((g) => g.groupId === groupId);
    if (group && group.personCount !== parseInt(valueId, 10)) {
      dispatch(
        setNotificationError({
          error: t('numberOfPeopleOpeningDoorMustNotBeGreaterOrLesserThanTheGivenValue', {
            count: group.personCount,
          }),
          status: 400,
        }),
      );
      return;
    }

    setGroupValues((prev) =>
      prev.map((g) => (g.groupId === groupId ? { ...g, secondSelect: valueId } : g)),
    );
  };

  const submit = async () => {
    if (
      !levelDetails.name ||
      !levelDetails.deviceId ||
      groupValues.filter((group) => group.personCount > 0).length === 0
    ) {
      dispatch(setNotificationError(t('pleaseFillAllFields')));
      return;
    }

    const validGroups = groupValues.filter((group) => group.personCount > 0);
    const groupIds = validGroups.map((group) => group.valueId).toString();
    const openerNumbers = validGroups.map((group) => group.personCount).toString();
    // Get the first valid group's selected item
    const firstValidGroup = validGroups[0];
    const selectedItem = dropdownData.find((item) => item.id === firstValidGroup.firstSelect);
    try {
      const response = await multiPersonOpeningDoorApi.saveMultiPersonOpeningDoor(
        { groupIds, openerNumbers },
        {
          id: levelDetails.id,
          doorId: levelDetails.doorId,
          deviceId: levelDetails.deviceId,
          deviceName: levelDetails.deviceName,
          name: levelDetails.name,
          combOpenPersonId: selectedItem
            ? `${selectedItem.label}(${firstValidGroup.personCount})`
            : '',
          personCount: levelDetails.personCount,
        },
      );

      if (response?.data) {
        const message = data?.id
          ? t('multiPersonGroupUpdatedSuccessfully')
          : t('multiPersonGroupAddedSuccessfully');
        dispatch(setNotificationError({ error: message, status: 200 }));
        setDisabled(false);
        dispatch(
          fetchMultiPersonOpeningDoor({
            pageNo: searchFilter?.pageIndex || 1,
            pageSize: searchFilter?.pageSize || 10,
          }),
        );
        closeModal();
      } else {
        dispatch(
          setNotificationError({ error: response.data.message, status: response.data.code }),
        );
      }
    } catch (error) {
      dispatch(setNotificationError({ error: t('errorFetchingData'), status: 500 }));
    }
  };

  return (
    <div className="multi-person">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="forms-row">
        <InputField
          id="name"
          name="name"
          label={t('combinationName')}
          type="text"
          value={levelDetails.name}
          onChangeFunction={onChangeValue}
          required
        />
      </div>

      <div className="select-device-wrapper">
        {!levelDetails?.deviceId && (
          <Button onClickFunction={addDoor} title={t('selectDevice')} className="btn-default" />
        )}
        {levelDetails?.deviceId && (
          <div
            className={`device-name ${data ? 'disabled' : ''}`}
            onClick={addDoor}
            role="presentation">
            <h4 className="label">{levelDetails?.deviceName}</h4>
          </div>
        )}
      </div>

      <div className="group-select-boxes">
        {groupValues.map((group) => (
          <div key={group.groupId} className="group">
            <Label title={`Group ${group.groupId.split('-')[1]}`} />
            <SelectBoxField
              id={`firstSelect${group.groupId}`}
              name={`firstSelect${group.groupId}`}
              label=""
              value={group.firstSelect}
              onChangeFunction={(name, value) =>
                handleFirstSelectChange(group.groupId, String(value.id))
              }
              data={dropdownData}
            />
            <SelectBoxField
              id={`secondSelect${group.groupId}`}
              name={`secondSelect${group.groupId}`}
              value={group.secondSelect}
              onChangeFunction={(name, value) =>
                handleSecondSelectChange(group.groupId, String(value.id))
              }
              data={optionsData}
              disabled={!group.firstSelect}
              readOnly
            />
            <span id={`allPersonCount${group.groupId.split('-')[1]}`}>
              ({group.personCount || 0})
            </span>
          </div>
        ))}
      </div>

      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={disabled}
      />
    </div>
  );
}

AddMultiCombination.defaultProps = {
  data: {
    combinationName: '',
    deviceId: '',
    deviceName: '',
    noOfDoors: 0,
    saveChanges: '',
    doorId: '',
  },
  searchFilter: {
    pageIndex: '',
    pageSize: '',
  },
};

export default AddMultiCombination;
