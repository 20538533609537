/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import map from '../../utils/api/Devices/map';

const transformData: any = (nodes: any[]) => {
  if (!Array.isArray(nodes)) return null; // Ensure it's an array

  return nodes.map((node) => {
    if (!node) return null; // Skip null/undefined nodes

    const { id, text, item, parent } = node;

    return {
      id,
      text,
      parent: parent ? { id: parent.id, text: parent.text } : null, // Avoid circular reference
      item: item?.length ? transformData(item) : [], // Recursively transform child nodes
    };
  });
};

export const fetchMapArea = createAsyncThunk('map/fetchMapArea', async (_, { dispatch }) => {
  const apiResponse = await map
    .getMapTree()
    .then((response) => {
      return transformData(response?.data?.item);
    })
    .catch((error) => {
      dispatch(setNotificationError(error.response.data));
      return error;
    });
  return apiResponse;
});

interface MapInterface {
  mapArea: any;
  status: 'loading' | 'success' | 'failed';
  mapId: string;
}

const initialState: MapInterface = {
  mapArea: [],
  status: 'success',
  mapId: '',
};

export const mapSlice: any = createSlice({
  name: 'timeZone',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMapArea.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchMapArea.fulfilled, (state: any, action: any) => {
        // Add mapArea to the state array
        state.mapArea = action?.payload;
        state.mapId = action?.payload?.[0]?.item?.[0]?.id;
        state.status = 'success';
      })
      .addCase(fetchMapArea.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

export const { setSearchFilter, deleteTimezone } = mapSlice.actions;

export default mapSlice.reducer;
