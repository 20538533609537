import React, { useEffect, useState } from 'react';

// Use interface
import { TabPageInterFace, ModalTabItemInterFace } from '../../interface/common.interface';

function TabPages({ tabs, defaultNumber }: TabPageInterFace) {
  const [tab, changeTab] = useState(tabs[defaultNumber]);
  const [tabIndex, setTabIndex] = useState(defaultNumber);

  const handleChange = (val: string | number) => {
    setTabIndex(val);
  };

  useEffect(() => {
    changeTab(tabs[tabIndex]);
  }, [tabIndex, tabs]);
  return (
    <div className="tab-page">
      <div className="tab-modal-content">
        <div className="tab-list-items">
          {tabs.map((tabItem: ModalTabItemInterFace, index: number) => (
            <div
              key={tabItem.key}
              className={`tab-item ${tabItem.key === tab.key && 'active'}`}
              onClick={() => {
                changeTab(tabItem);
                handleChange(index);
              }}
              role="presentation"
              title={tabItem.title}>
              {tabItem.title}
            </div>
          ))}
        </div>
        <div className="tab-body">{tab.content}</div>
      </div>
    </div>
  );
}

export default TabPages;
