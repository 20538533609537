import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
// Import shared components
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon } from '../../../assets/svgicons/svgicon';
import AddGlobalInterlock from './components/add-global-interlock';
import DeleteModalBox from '../../../shared-components/delete-modal-box/delete-modal-box';
import AddGroup from './components/add-group';
import globalInterlockApi from '../../../utils/api/AdvancedFunctions/globalInterlock';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

import {
  GlobalInterlockProps,
  InterlockGroupProps,
} from '../../../interface/globalInterlock.interface';
import {
  fetchGlobalInterlock,
  setSearchFilter,
  deleteInterlock,
  fetchDoorList,
  setInterlockGroupSearchFilter,
  deleteInterlockGroup as deleteILG,
} from '../../../redux/slices/GlobalInterlockSlices';

function GlobalInterlock() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    interlockList,
    searchFilter,
    status,
    totalDataCount,
    interlockGroup,
    interlockGroupStatus,
    interlockGroupTotalDataCount,
    interlockGroupSearchFilter,
  } = useAppSelector((state: any) => state.globalInterlock);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchGlobalInterlock({ ...params }));
    }
  };
  const [selectedPerson, setSelectPerson] = useState<string[]>([]); // Add state to hold selected cards
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]); // Add state to hold selected cards
  const handleEditOpen = (editData: any) => {
    const updateData = {
      ...editData,
      id: editData?.id,
      name: editData?.name,
      remarks: editData?.remark,
      isGroupInterlock: editData?.isGroupInterlock,
    };
    setModalOpen(true);
    setModalView({
      title: t('edit'),
      content: (
        <AddGlobalInterlock closeModal={() => setModalOpen(false)} data={{ ...updateData }} />
      ),
    });
  };
  // let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('name'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'remark',
      header: t('remarks'),
    },
  ];

  const personnelColumn = [
    {
      accessorKey: 'name',
      header: t('groupName'),
    },
    {
      accessorKey: 'remark',
      header: t('remarks'),
    },
  ];

  const addPersonnel = (val: InterlockGroupProps): void => {
    setModalView({
      title: t('addGroup'),
      content: (
        <AddGroup closeModal={() => setModalOpen(false)} groupIds={val.id} interlockId={val.id} />
      ),
    });

    setModalOpen(true);
  };

  const getFilterLevels = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  // level functions
  const fetchLevels = (params: GlobalInterlockProps) => {
    if (params.interlockId) {
      dispatch(fetchDoorList({ ...params }));
    }
  };

  const getSelected = (id: string[]) => {
    setSelectedLevel(id);
    fetchLevels({ interlockId: id?.[0], ...searchFilter.filter });
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setInterlockGroupSearchFilter(filter));
      fetchLevels({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };
  const deleteLevels = (id: any) => {
    globalInterlockApi
      .delGroupInterlock(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('globalInterlockGroupDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteILG(id));
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const deleteRecord = (id: any) => {
    globalInterlockApi
      .deleteInterlock(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('globalInterlockDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteInterlock(id));
          setModalOpen(false);
          fetchData({ pageNo: 1, pageSize: 10 }); // Refresh the list after successful deletion
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: handleEditOpen,
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('globalInterlockDeletedSuccessfully'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
    {
      label: t('addGroup'),
      function: (val: any) => addPersonnel(val),
    },
  ];

  const levelActionButtons = [
    {
      label: t('deleteFromDefaultLevels'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteLevels}
            />
          ),
        });
      },
    },
  ];

  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('advancedFunctions'),
          },
          {
            title: t('globalInterlock'),
          },
        ]}
      />
      <CardBox
        header={t('globalInterlock')}
        title={t('listOfInterlock')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddGlobalInterlock closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterLevels}
          enablePagination
          assignFilterValue={searchFilter}
          assignSelectedData={selectedLevel}
          getSelectedData={(val: any): any => getSelected(val)}
          checkBoxActive
          singleRowSelection
          values={interlockList}
          fixedColumn={['name']}
          isFetching={status === 'loading'}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="browse-table">
          <div className="header">
            <h4>Global Interlock Group</h4>
            <span className="sub-title">{t('listOfInterlockGroup')}</span>
          </div>
          <DataTable
            actionButtonStatus={!modalOpen}
            action={levelActionButtons}
            columns={personnelColumn}
            getFilterData={getFilterData}
            enablePagination
            assignFilterValue={interlockGroupSearchFilter}
            assignSelectedData={selectedPerson}
            getSelectedData={(val: any): any => setSelectPerson(val)}
            checkBoxActive
            values={interlockGroup}
            fixedColumn={['id']}
            isFetching={interlockGroupStatus === 'loading'}
            totalRecords={interlockGroupTotalDataCount}
            height={411}
          />
        </div>
      </CardBox>
    </div>
  );
}

export default GlobalInterlock;
