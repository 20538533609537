import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

// Shared components
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import DeleteModalBox from '../../../../shared-components/delete-modal-box/delete-modal-box';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';

// Redux slice actions
import {
  fetchEmailManagement,
  setSearchFilter,
  deleteLinkage as deleteEM,
} from '../../../../redux/slices/system-management/EmailManagementSlices';
import emailManagementApi from '../../../../utils/api/SystemManagement/emailManagement';

// Components
import ExportData from './components/export-data';
import { FilterProps } from '../../../../interface/common.interface';
// import OutgoingMailServerSettings from './components/OutgoingMailServerSettings';

function EmailManagement() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { emailList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.emailManagement,
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const columns = [
    { accessorKey: 'sender', header: t('sender') },
    { accessorKey: 'receiver', header: t('recipient') },
    { accessorKey: 'subject', header: t('subject') },
    { accessorKey: 'commitTime', header: t('submitTime') },
    { accessorKey: 'sendTime', header: t('sendTime') },
    { accessorKey: 'status', header: t('status') },
    { accessorKey: 'retMessage', header: t('errorMessage') },
  ];

  const fetchData = async (params: FilterProps) => {
    if (status === 'success') {
      dispatch(fetchEmailManagement({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  // const fetchNextData = (): any => {
  //   fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  // };

  const exportData = () => {
    setModalOpen(false);
  };

  const deleteRecord = (id: any) => {
    if (selectedValues.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneEmailManagement'),
          status: 400,
        }),
      );
      return;
    }

    emailManagementApi
      .delete(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('emailManagementDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteEM(id));
          setModalOpen(false);
          fetchData({ pageNo: 1 });
        } else {
          dispatch(
            setNotificationError({
              error: t('deleteFailed'),
              status: 400,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const moreActionButton = [
    // {
    //   title: t('outgoingMailServerSettings'),
    //   clickFunction: (): void => {
    //     setModalView({
    //       title: t('outgoingMailServerSettings'),
    //       content: <OutgoingMailServerSettings closeModal={() => setModalOpen(false)} />,
    //     });
    //     setModalOpen(true);
    //   },
    // },
    {
      title: t('export'),
      clickFunction: (): void => {
        setModalView({
          title: t('export'),
          content: (
            <ExportData closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
        setModalOpen(true);
      },
    },
    {
      title: t('delete'),
      clickFunction: (): void => {
        if (selectedValues.length === 0) {
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOneLinkage'),
              status: 400,
            }),
          );
          return;
        }

        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={selectedValues}
              okFunction={deleteRecord}
            />
          ),
        });
        setModalOpen(true);
      },
    },
  ];

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('system')}
        broadCram={[{ title: t('systemManagement') }, { title: t('emailManagement') }]}
      />
      <CardBox
        header=""
        title={t('listOfEmail')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
          />
        }>
        <DataTable
          actionButtonStatus={false}
          columns={columns}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          checkBoxActive
          enablePagination
          isFetching={status === 'loading'}
          values={emailList}
          fixedColumn={['eventTime']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default EmailManagement;
