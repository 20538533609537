import { fetch, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/baseMail/list`, {}, { ...data });
  },
  export: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Sys/api/baseMail/exportEmail`, 'POST', data);
  },

  delete: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/baseMail/del?ids=${id}`, {});
  },
};
