import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';

// Use interface
import { TimeAttendanceInterface } from '../../../interface/permissions.interface';
import TreeViewDropdown from '../../../shared-components/tree-view/TreeViewDropdown';

function TimeAttendance({
  onChangeFunction = () => {},
  values,
  areaList,
}: TimeAttendanceInterface) {
  const { t } = useTranslation();
  const selectData = [
    {
      label: t('value1'),
      id: 'value1',
    },
    {
      label: t('value2'),
      id: 'value2',
    },
  ];

  const [value, setValue] = useState({
    ...values,
    attendanceAreas: [],
  });

  const onChange = (name: string, changeValue: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };

  useEffect(() => onChangeFunction(value), [value]);

  return (
    <div className="permissions">
      <div className="permissions-form">
        <div className="row-form">
          <div className="item item1">
            <div className="tree-view-dropdown">
              <TreeViewDropdown
                listItems={areaList?.item?.[0]}
                onSelect={(val: any) => onChange('attendanceAreas', val)}
                onSelectValue={() => {}}
                value={value.attendanceAreas}
                multiSelect
                checkboxSelection
                isExpanded
              />
            </div>
          </div>
          <div className="item">
            <SelectBoxField
              name="attendanceMode"
              label={t('attendanceMode')}
              id="attendanceMode"
              value={value.attendanceMode}
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={[
                {
                  label: t('normalAttendance'),
                  id: 'true',
                },
                {
                  label: t('noPunchRequired'),
                  id: 'false',
                },
              ]}
            />
          </div>
          <div className="item">
            <SelectBoxField
              name="deviceOperationRole"
              label={t('deviceOperationRole')}
              id="deviceOperationRole"
              value={value.deviceOperationRole}
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={[
                {
                  label: t('employee'),
                  id: '0',
                },
                {
                  label: t('enroller'),
                  id: '2',
                },
                {
                  label: t('administrator'),
                  id: '6',
                },
                {
                  label: t('superuser'),
                  id: '14',
                },
              ]}
            />
          </div>
          <div className="item" />
          <div className="item" />
          <div className="item">
            <SelectBoxField
              name="verificationRole"
              label={t('verificationRole')}
              id="verificationRole"
              value={value.verificationRole}
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={selectData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimeAttendance;
