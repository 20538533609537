/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
// Use translation
import { useTranslation } from 'react-i18next';

// Use common modules
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

// Use own component pages
import PersonalDetails from './personal-details';
import ProfessionalDetails from './professional-details';
import Permissions from './permissions';

// Use shared components
import TopHeader from '../../../shared-components/top-header/top-header';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import WidgetForm from '../../../shared-components/widget-forms/widget-forms';
import Button from '../../../shared-components/button/button';

// Use api components
import personAddApi from '../../../utils/api/person';

// Use validation functions
// import { emailValidation, phoneNumberValidation } from '../../../shared-components/form/validation';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { PersonValue } from '../../../interface/personnel.interface';
import { fetchPersonsList, fetchSinglePerson } from '../../../redux/slices/PersonSlices';
import { fetchDepartmentList } from '../../../redux/slices/DepartmentSlices';
import { fetchAllPositionList } from '../../../redux/slices/PositionSlices';
import { emailValidation } from '../../../shared-components/form/validation';
import { base64ToFile } from '../../../utils/helpers';

function EditPerson() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id = '123' } = useParams(); // Get the ID from the URL
  const allDepartments = useAppSelector((state: any) => state.departments.departments);
  const { singlePerson } = useAppSelector((state: any) => state.persons);
  const allPositions = useAppSelector((state: any) => state.positions.allPositions); // Get all positions
  const { searchFilter } = useAppSelector((state: any) => state.persons);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView] = useState({
    title: t('addNewPerson'),
    content: <>hi</>,
  });
  const [loading, setLoading] = useState(false);

  const departMentResult = allDepartments.map((item: any) => ({
    label: item.name,
    id: item.departmentId,
  }));

  const [personValue, setPersonValue] = useState<any>({ ...singlePerson });
  const onChangeFunction = (values: Partial<PersonValue>) => {
    setPersonValue((prev: any) => ({
      ...prev,
      ...values,
    }));
  };

  const submit = () => {
    setLoading(true);
    const personPayload: any = new FormData();

    if (personValue?.birthday !== '') {
      personPayload.append('birthday', personValue?.birthday);
    }
    personPayload.append('cardNos', personValue?.cardNo);
    personPayload.append('name', personValue?.name);
    personPayload.append('lastName', personValue?.lastName);
    personPayload.append('mobilePhone', personValue?.mobilePhone);
    personPayload.append('gender', personValue?.gender);
    personPayload.append('pin', personValue?.pin);
    if (personValue?.hireDate) {
      personPayload.append('hireDate', personValue?.hireDate);
    }
    personPayload.append('whatsappMobileNo', personValue?.whatsappMobileNo);
    personPayload.append('positionId', personValue?.positionId);
    personPayload.append('deptId', personValue?.deptId);
    personPayload.append('cropPhotoDel', false);

    if (personValue?.personIdPhoto || personValue?.personPhoto) {
      const base64Image =
        personValue?.personPhoto ||
        personValue.personIdPhoto.replace(/^data:image\/[a-z]+;base64,/, '');
      personPayload.append('cropPhotoBase64', personValue?.enableAvatar && base64Image);
      personPayload.append(
        'personPhoto',
        base64ToFile(personValue?.personPhoto, personValue?.name),
      );
    }

    personPayload.append('certType', personValue?.certType);
    personPayload.append('certNumber', personValue?.certNumber);

    personPayload.append('mail', personValue?.email);
    personPayload.append('acc.personLevelIds', personValue?.levelSettings?.toString());
    personPayload.append('moduleAuth', 'acc');

    if (personValue?.personIdPhoto || personValue?.personPhoto) {
      const base64Image =
        personValue?.personPhoto ||
        personValue.personIdPhoto.replace(/^data:image\/[a-z]+;base64,/, '');
      personPayload.append('personIdPhoto', base64Image);
    }

    personPayload.append('acc.superAuth', personValue?.superUser);
    personPayload.append('acc.privilege', personValue?.deviceOperationRule);
    personPayload.append('acc.delayPassage', personValue?.extendPassage?.length > 0);
    personPayload.append('acc.disabled', personValue?.accessDisabled?.length > 0);
    personPayload.append('acc.isSetValidTime', personValue?.setValidTime?.length > 0);
    personPayload.append('acc.startTime', personValue?.startTime);
    personPayload.append('acc.endTime', personValue?.endTime);
    personPayload.append('att.personAreas', personValue?.attendanceAreas?.join(','));
    personPayload.append('att.isAttendance', personValue?.attendanceMode);
    personPayload.append('att.verifyMode', personValue?.verificationRole);

    personAddApi
      .addPerson(personPayload)
      .then((response) => {
        if (response.data.success) {
          // Handle successful response
          dispatch(
            setNotificationError({
              error: t('personEditedSuccessfully'),
              status: 200, // Success status code
            }),
          );
          setLoading(false);
          dispatch(fetchPersonsList({ pageNo: 1, pageSize: 10, ...searchFilter.filter }));
          navigate('/admin/personnel/personnel/person');
        } else {
          // Handle specific error response
          setLoading(false);
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code, // Error status code
            }),
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const back = () => {
    navigate('/admin/personnel/personnel/person');
  };

  useEffect(() => {
    if (allDepartments.length === 0) {
      dispatch(fetchDepartmentList({ pageNo: 1, pageSize: 10 }));
    }
    if (allPositions.length === 0) {
      dispatch(fetchAllPositionList({}));
    }
    if (Object.keys(singlePerson).length === 0) {
      dispatch(fetchSinglePerson(id));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(singlePerson).length > 0) {
      setPersonValue(singlePerson);
    }
  }, [singlePerson]);

  return (
    <div className="person">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('editPerson')}
        broadCram={[{ title: t('personnel') }, { title: t('person') }, { title: t('editPerson') }]}
      />
      {Object.keys(personValue).length > 0 && (
        <WidgetForm
          rightContent={
            <Button onClickFunction={back} className="btn-default btn-sm" title={t('back')} />
          }
          backFunction={() => navigate('/admin/personnel/personnel/person')}
          tabs={[
            {
              title: t('personalDetails'),
              key: 'personalDetails',
              value: 1,
              content: <PersonalDetails onChangeFunction={onChangeFunction} values={personValue} />,
            },
            {
              title: t('professionalDetails'),
              key: 'professionalDetails',
              value: 2,
              content: (
                <ProfessionalDetails
                  onChangeFunction={onChangeFunction}
                  values={personValue}
                  allDepartments={departMentResult} // Pass the departments data
                  allPositions={allPositions}
                />
              ),
            },
            {
              title: t('permissions'),
              key: 'permissions',
              value: 3,
              content: <Permissions onChangeFunction={onChangeFunction} values={personValue} />,
            },
          ]}
          loading={loading}
          submitFunction={submit}
          data={personValue}
          // after some time to need to validation apply for the professional details
          firstStepValidation={personValue?.email ? [emailValidation(personValue?.email)] : []}
          secondStepValidation={[personValue.pin, personValue.deptId, !personValue?.isExistCard]}
          thirdStepValidation={[personValue?.levelSettings?.length !== 0]}
        />
      )}
    </div>
  );
}

export default EditPerson;
