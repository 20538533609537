import React, { useEffect, useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../redux/store';

// Use redux functions
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';
import { fetchArea } from '../../../../../redux/slices/system-management/AreaSettings';
// Use shared components
import InputField from '../../../../../shared-components/input-field/input-field';
import Button from '../../../../../shared-components/button/button';
import SelectBoxField from '../../../../../shared-components/selectbox-field/selectbox-field';

// Use Interface
import areaSettingsApi from '../../../../../utils/api/SystemManagement/areaSettings';

// Use api's calls
import { nameValidation } from '../../../../../shared-components/form/validation';

interface AddDepartmentInterFace {
  closeModal: () => void; // Modal page close function
  data?: {
    code: string;
    id: string;
    name: string;
    remark?: string;
    parentAreaName: string;
    update: boolean;
  };
}
function AddArea({ closeModal, data }: AddDepartmentInterFace) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [disableButton, setDisableButton] = useState(true);
  const [timeZoneList, setTimeZoneList] = useState([]);

  const [areaValue, setAreaValue] = useState(
    data || {
      id: '',
      code: '',
      name: '',
      remark: '',
      parentAreaName: '',
      update: false,
    },
  );

  const onChangeValue = (name: string, value: any) => {
    setAreaValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    const request = {
      id: areaValue.id,
      code: areaValue.code,
      name: areaValue.name,
      remark: areaValue.remark,
      parentAreaName: areaValue.parentAreaName,
      update: areaValue.update,
    };
    areaSettingsApi
      .addArea(request)
      .then((response) => {
        if (response?.data) {
          const message = data?.id ? t('areaUpdatedSuccessfully') : t('areaAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          dispatch(fetchArea({ pageNo: 1, pageSize: 10 }));
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  const fetchTimeZoneList = async () => {
    try {
      const response = await areaSettingsApi.getList();
      const formattedData = response.data.item
        .map((item: any) => ({
          label: item.text || '',
          id: item.id || '',
        }))
        .filter((item: any) => item.label && item.id);
      setTimeZoneList(formattedData);
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };
  useEffect(() => {
    fetchTimeZoneList();
  }, []);

  useEffect(() => {
    let i = 0;
    if (
      areaValue.code === '' ||
      !nameValidation(1, 50, areaValue.code) ||
      areaValue.name === '' ||
      !nameValidation(1, 50, areaValue.name)
    ) {
      i += 1;
    }
    if (i > 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [areaValue]);

  return (
    <div className="add-department">
      <div className="add-department-form">
        <div className="flex">
          <div className="col">
            <InputField
              name="code"
              label={t('areaNumber')}
              id="code"
              type="text"
              value={areaValue.code}
              required
              readonly={!!data}
              errorStatus={areaValue.code !== '' && !nameValidation(1, 50, areaValue.code)}
              message={t('departmentNumberInvalid')}
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col">
            <InputField
              name="name"
              label={t('areaName')}
              id="name"
              type="text"
              value={areaValue.name}
              required
              innerIconStatus={false}
              errorStatus={areaValue.name !== '' && !nameValidation(1, 50, areaValue.name)}
              message={t('departmentNameInvalid')}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col">
            <SelectBoxField
              name="parentAreaName"
              label={t('parentArea')}
              id="parentAreaName"
              data={timeZoneList}
              value={areaValue.parentAreaName}
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            />
          </div>
          <div className="col">
            <InputField
              id="remark"
              name="remark"
              label={t('remarks')}
              type="text"
              value={areaValue?.remark}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col" />
          <div className="col" />
        </div>
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={submit}
            title={t('done')}
            className="btn-primary btn-bold"
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
}
AddArea.defaultProps = {
  data: '',
};
export default AddArea;
