import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import AddToDefaultLevels from './components/add-default-levels';
import {
  fetchAccessByDepartment,
  setSearchFilter,
} from '../../redux/slices/AccessByDepartmentSlices';
import {
  fetchDeptLevel,
  setSearchFilter as levelSearchFilter,
} from '../../redux/slices/AccessByLevelSlices';
import {
  AccessByDepartmentInterface,
  DeleteLevelProps,
  DepartmentParamsProps,
  LevelProps,
} from '../../interface/accessByDepartment.interface';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import accessByDepartment from '../../utils/api/AccessRule/accessByDepartment';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

function SetAccessByDepartment() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { departments, totalDataCount, searchFilter, status } = useAppSelector(
    (state: any) => state.accessByDepartment,
  );
  const levelSelector = useAppSelector((state: any) => state.accessByLevel);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedFullData, setSelectedFullData] = useState<AccessByDepartmentInterface[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]);
  const columnsField = [
    {
      accessorKey: 'code',
      header: t('departmentNumber'),
    },
    {
      accessorKey: 'name',
      header: t('departmentName'),
    },
    {
      accessorKey: 'parentDeptName',
      header: t('parentDepartment'),
    },
  ];
  const selectColumnField = [
    {
      accessorKey: 'name',
      header: t('levelName'),
    },
    {
      accessorKey: 'authAreaName',
      header: t('areaName'),
    },
    {
      accessorKey: 'timeSegName',
      header: t('timeZone'),
    },
  ];
  const fetchNextPage = () => {
    return {};
  };
  const deleteLevel = (params: DeleteLevelProps) => {
    const req = {
      immeUpdate: true,
      leftIds: params.deptId,
      rightIds: params.id,
    };
    accessByDepartment
      .deleteLevel(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('levelDeletedSuccessfully'),
              status: 200,
            }),
          );
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };
  const actionButtons = [
    {
      label: t('addToDefaultLevels'),
      function: (val: any): void => {
        setModalView({
          title: t('addToDefaultLevels'),
          content: <AddToDefaultLevels closeModal={() => setModalOpen(false)} deptId={val.id} />,
        });
        setModalOpen(true);
      },
    },
  ];
  const levelActionButtons = [
    {
      label: t('deleteFromDefaultLevels'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteDepartment'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val}
              okFunction={deleteLevel}
            />
          ),
        });
      },
    },
  ];
  const fetchDepartments = (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchAccessByDepartment({ ...params }));
    }
  };
  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchDepartments({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  // level functions
  const fetchLevels = (params: LevelProps) => {
    if (params.deptId) {
      dispatch(fetchDeptLevel({ ...params }));
    }
  };
  // department functions
  const getSelected = (id: string[]) => {
    setSelectedValues(id);
    fetchLevels({ deptId: id?.[0], pageNo: 1 });
  };
  const getFilterLevels = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(levelSearchFilter(filter));
      fetchLevels({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('setAccessByDepartment'),
          },
        ]}
      />
      <CardBox
        header={t('editDefaultLevelsForDepartment')}
        title={t('listOfDepartment')}
        rightSide={<CardRightButtonGroup buttonStatus={false} />}>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => getSelected(val)}
          getSelectedFullData={(val: AccessByDepartmentInterface[]): any =>
            setSelectedFullData(val)
          }
          showCustomActions={false}
          singleRowSelection
          enablePagination
          checkBoxActive
          isFetching={status === 'loading'} // Placeholder status
          values={departments} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="browse-table">
          <div className="header">
            <h4>
              Browse Department {selectedFullData?.[0]?.code} ({selectedFullData?.[0]?.name})
              Default Levels
            </h4>
            <span className="sub-title">{t('listOfLevels')}</span>
          </div>
          <DataTable
            actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
            action={levelActionButtons}
            columns={selectColumnField}
            getFilterData={getFilterLevels}
            assignFilterValue={levelSelector.searchFilter}
            assignSelectedData={selectedLevel}
            getSelectedData={(val: any): any => setSelectedLevel(val)}
            // getSelectedFullData={(val: any): any => setSelectedData(val)}
            checkBoxActive
            fetchNextPage={fetchNextPage}
            isFetching={levelSelector.status === 'loading'} // Placeholder status
            values={levelSelector.levels} // Use the actual data here
            fixedColumn={['id']}
            totalRecords={levelSelector.totalDataCount}
            height={411}
          />
        </div>
      </CardBox>
    </div>
  );
}

export default SetAccessByDepartment;
