import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';

function SynchronizePrompt({ closeModal, submitSync }: any) {
  const { t } = useTranslation();
  const [values, setValue] = useState({
    isSyncClearData: [],
  });

  const onChangeValue = (name: string, changeValue: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };

  return (
    <div className="conformation-box synchronize-prompt">
      <div className="message-text">
        <p>{t('Are you want to perform the Synchronize All Data to Devices operation ?')}</p>
        <div className="check-wrapper">
          <p>{t('Delete the data from the device before syncing new data to device')}</p>
          <CheckBoxInputField
            name="isSyncClearData"
            data={[
              {
                label: '',
                id: '1',
                value: '1',
              },
            ]}
            value={values.isSyncClearData}
            onChangeFunction={onChangeValue}
          />
        </div>
      </div>
      <div className="confirm-buttons">
        <Button onClickFunction={closeModal} title={t('Cancel')} className="btn-default" />
        <Button
          onClickFunction={() => {
            submitSync(values.isSyncClearData);
            closeModal();
          }}
          title={t('Ok')}
          className="btn-primary"
        />
      </div>
    </div>
  );
}

export default SynchronizePrompt;
