import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../redux/store';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';
import InputField from '../../../../../shared-components/input-field/input-field';
import FormRightButton from '../../../../../shared-components/form-right-buttons/FormRightButton';
import certificateType from '../../../../../utils/api/SystemManagement/certificateType';
import { fetchCertificateType } from '../../../../../redux/slices/system-management/CertificateTypeSlices';

interface AddCertificateProp {
  id?: any;
  dictValue: string;
  CertificatesName: string;
  code: string;
}
interface InterlockGroupType {
  closeModal: () => void;
  data?: AddCertificateProp;
}
function AddCertificate({ closeModal, data }: InterlockGroupType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<AddCertificateProp>({
    id: data?.id || '',
    dictValue: data?.dictValue || '',
    CertificatesName: data?.CertificatesName || '',
    code: data?.code || '',
  });

  const [disabled, setDisabled] = useState(false);
  const onChangeValue = (name: string, values: any) => {
    setValue((prev: AddCertificateProp) => ({
      ...prev,
      [name]: values,
    }));
  };
  const submit = () => {
    const request = {
      id: value.id,
      dictValue: value.dictValue,
      code: value.code,
    };
    certificateType
      .saveList(request)
      .then((response) => {
        if (response?.data) {
          const message = data
            ? t('certificateTypeUpdatedSuccessfully')
            : t('certificateTypeAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          setDisabled(false);
          dispatch(fetchCertificateType({ pageNo: 1 }));
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  return (
    <div className="flex-row column" style={{ width: '30vw' }}>
      <div className="flex-row" style={{ width: '40%' }}>
        <InputField
          id="dictValue"
          name="dictValue"
          label={t('certificatesName')}
          type="text"
          value={value?.dictValue}
          onChangeFunction={onChangeValue}
          required
        />
      </div>
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={disabled}
      />
    </div>
  );
}

AddCertificate.defaultProps = {
  data: {
    CertificatesName: '',
  },
};

export default AddCertificate;
