import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import InputField from '../../../shared-components/input-field/input-field';
import { AuxiliaryInputIcon, AuxInputOffline, Search } from '../../../assets/svgicons/svgicon';
import CardStatus from '../../../shared-components/card-status/cardStatus';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import realtimeMonitoring from '../../../utils/api/Devices/realtimeMonitoring';

interface AuxiliaryData {
  id: string;
  status: string;
  device: string;
  number: string;
  title: string;
  icon: any;
}

function AuxiliaryInput({ data }: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [auxiliaryInput, setAuxiliaryInput] = useState<AuxiliaryData[]>([]);

  const header = [
    {
      label: t('status'),
      key: 'status',
    },
    {
      label: t('device'),
      key: 'devAlias',
    },
    {
      label: t('number'),
      key: 'no',
    },
  ];

  const statusData = [
    {
      title: t('online'),
      count: auxiliaryInput.filter((item) => item.status === 'Online')?.length || 0,
      color: 'success',
    },
    {
      title: t('offline'),
      count: auxiliaryInput.filter((item) => item.status === 'Offline')?.length || 0,
      color: 'danger',
    },
    {
      title: t('disable'),
      count: auxiliaryInput.filter((item) => item.status === 'Disable')?.length || 0,
      color: 'warning',
    },
    {
      title: t('unknown'),
      count: auxiliaryInput.filter((item) => item.status === 'Unknown')?.length || 0,
      color: 'secondary',
    },
    {
      title: t('currentTotal'),
      count: auxiliaryInput?.length || 0,
    },
  ];

  useEffect(() => {
    realtimeMonitoring
      .getAllAuxIn()
      .then((response) => {
        const mappedData = response.data.data
          ? response.data.data.map((input: any) => {
              const matchingDevice = data.find((item: any) => item.id === input.id);
              return {
                ...input,
                sensor:
                  matchingDevice?.relay === 'acc_rtMonitor_unknown' ? 'Unknown' : input?.relay,
                title: input.name,
                image: matchingDevice?.image || null,
                status: matchingDevice?.connect === '1' ? 'Online' : 'Offline',
                icon:
                  matchingDevice?.connect === '1' ? <AuxiliaryInputIcon /> : <AuxInputOffline />,
              };
            })
          : [];

        setAuxiliaryInput(mappedData);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setAuxiliaryInput([]);
      });
  }, [dispatch]);

  return (
    <div className="rtm-door">
      <div className="search-row">
        <div className="search-input">
          <InputField
            name="search"
            label={t('search')}
            id="name"
            type="text"
            value={search}
            innerIconStatus
            InnerIcon={Search}
            onChangeFunction={(name: string, value: any) => setSearch(value)}
          />
        </div>
      </div>
      <div className="card-container">
        <div className="card-grid">
          {auxiliaryInput.map((item, index) => (
            <div className="card-item" key={item?.id}>
              <div className="card-item-icon">{item.icon}</div>
              <span>{item.title}</span>
              <div className="card-item-status">
                <CardStatus key={item.title} data={item} header={header} index={index} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="status-container">
        {statusData.map((status: any) => (
          <div className="status-wrap">
            {status?.color && <div className={`circle-dot ${status?.color}`} />}
            <span style={{ color: '#696C6F' }}>
              {status?.title} : {status?.count}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AuxiliaryInput;
