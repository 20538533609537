import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
// Use assets svg icons
import { AddIcon } from '../../assets/svgicons/svgicon';

import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import {
  FirstLevelApiProps,
  FirstDeleteLevelProps,
  FirstPersonProps,
} from '../../interface/firstPersonNormallyOpen';
// Import card slice actions
import {
  fetchFirstPersonNormallyOpen,
  fetchPersonList,
  setSearchFilter,
  setLevelSearchFilter,
  deleteFirstPerson,
  resetLevels,
  deleteLevels as deleteLevelList,
} from '../../redux/slices/FirstPersonNormallyOpenSlices';
import AddFirstPerson from './components/AddFirstPerson';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import firstPersonNormallyOpenApi from '../../utils/api/AccessRule/firstPersonNormallyOpen';
import AddPersonnel from './components/AddPersonnel';

function FirstPersonNormallyOpen() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    firstPersonNormallyOpen,
    status,
    personId,
    levels,
    totalDataCount,
    levelTotalDataCount,
    levelSearchFilter: searchFilter,
  } = useAppSelector((state: any) => state.firstPersonNormallyOpen);

  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('firstPersonNormallyOpen'),
    content: <>hi</>,
  });
  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchFirstPersonNormallyOpen({ ...params }));
    }
  };
  const [selectedCards, setSelectedCards] = useState<string[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]);

  const addPersonnel = (val: FirstPersonProps): void => {
    setModalView({
      title: t('addPersonnel'),
      content: (
        <AddPersonnel
          closeModal={() => setModalOpen(false)}
          personIds={val.id}
          firstOpenId={val.id}
        />
      ),
    });

    setModalOpen(true);
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };
  const fetchLevels = async (params: FirstLevelApiProps) => {
    if (params.accFirstOpenId) {
      dispatch(fetchPersonList({ ...params }));
    }
  };

  const handleSelectPerson = (selectedIds: string[]): void => {
    setSelectedLevel(selectedIds);
    // using at() method to get the last index data in string
    const lastSelectedId = selectedIds.at(-1);
    if (selectedIds.length === 1 && lastSelectedId) {
      fetchLevels({ accFirstOpenId: lastSelectedId, pageNo: 1 });
    } else if (selectedIds.length === 0) {
      setSelectedData([]);
      dispatch(resetLevels());
    }
  };

  const getLevelFilter = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setLevelSearchFilter(filter));
      fetchLevels({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const handleEditOpen = (editData: any) => {
    const updateData = {
      ...editData,
      id: editData?.id,
      name: editData?.doorName,
      deviceAlias: editData?.deviceAlias,
      doorId: editData.doorId,
      passageModeTimeZone: editData.timeSegId,
    };

    setModalOpen(true);
    setModalView({
      title: t('edit'),
      content: <AddFirstPerson closeModal={() => setModalOpen(false)} data={{ ...updateData }} />,
    });
  };

  const accessLevelColumnsField = [
    {
      accessorKey: 'doorName',
      header: t('doorName'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.doorName}
        </div>
      ),
    },
    {
      accessorKey: 'deviceAlias',
      header: t('deviceName'),
    },
    {
      accessorKey: 'timeSegName',
      header: t('passageModeTimeZone'),
    },
  ];

  const doorColumnsField = [
    {
      accessorKey: 'personPin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'personName',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'deptName',
      header: t('department'),
    },
  ];

  const deleteRecord = (id: any) => {
    firstPersonNormallyOpenApi
      .deleteFirstPerson(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('firstPersonDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteFirstPerson(id));
          fetchData({
            pageNo: searchFilter.pageIndex,
            pageSize: searchFilter.pageSize,
          });
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const deleteLevels = (params: FirstDeleteLevelProps) => {
    const req = {
      firstOpenId: params.linkId,
      personIds: params.id,
    };
    firstPersonNormallyOpenApi
      .delPerson(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('personDeletedSuccessfully'),

              status: 200,
            }),
          );
          dispatch(deleteLevelList(params.linkId));
          setModalOpen(false);
          fetchLevels({
            pageNo: 1,
            personId,
            accFirstOpenId: params.linkId,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,

            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };
  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
    {
      label: t('addPersonnel'),
      function: (val: any) => addPersonnel(val),
    },
  ];
  const levelActionButtons = [
    {
      label: t('deleteFromDefaultLevels'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteDepartment'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val}
              okFunction={deleteLevels}
            />
          ),
        });
      },
    },
  ];

  const selectedLevelName = selectedData.slice(selectedData.length - 1);

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('firstPersonNormallyOpen'),
          },
        ]}
      />
      <CardBox
        header={t('firstPersonnelNormalOpen')}
        title={t('listOfDoors')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddFirstPerson closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={accessLevelColumnsField}
          getFilterData={getLevelFilter}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedLevel} // Assign selected cards
          getSelectedData={(val: any): any => handleSelectPerson(val)} // Set selected cards
          getSelectedFullData={(val: any): any => setSelectedData(val)}
          checkBoxActive
          singleRowSelection
          enablePagination
          isFetching={status === 'loading'}
          values={firstPersonNormallyOpen} // Use the actual data here
          fixedColumn={['cardNo']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="header">
          <h4>Browse Personnel ({selectedLevelName?.[0]?.doorName}) From Levels</h4>
        </div>
        <div className="doorlist">
          <span className="sub-title">{t('listOfPersonnel')}</span>
        </div>
        <DataTable
          action={levelActionButtons}
          columns={doorColumnsField}
          values={levels} // Use the actual data here
          actionButtonStatus={!modalOpen}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedCards} // Assign selected cards
          getSelectedData={(val: any): any => setSelectedCards(val)} // Set selected cards
          checkBoxActive
          isFetching={status === 'loading'}
          fixedColumn={['cardNo']}
          totalRecords={levelTotalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default FirstPersonNormallyOpen;
