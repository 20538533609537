import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use interface
import { SideBarSessionInterFace, SubmenuInterFace } from '../../interface/common.interface';
import {
  AccControlReportIcon,
  AccessDeviceIcon,
  AccessRuleIcon,
  AdvancedFuncIcon,
  CardHandIcon,
  DownArrowIcon,
  PersonIcon,
  SystemIcon,
} from '../../assets/svgicons/svgicon';

// use assets image files
import logo from '../../assets/images/logo.png';
import logo1 from '../../assets/images/logo1.png';

function SidebarSession({ menus, redirect, activeValue, openDropDown }: SideBarSessionInterFace) {
  const { Icon, RightIcon, label, active, children } = menus;

  return (
    <div className={`sidebar-menu-session ${activeValue === active ? 'active' : ''}`}>
      <div
        className={`main-menu ${activeValue === active ? 'active' : ''} `}
        onClick={() => openDropDown(active)}
        role="presentation">
        <div className="menu-icon">
          <Icon color={activeValue === active ? '#078DEE' : '#3E4246'} />
        </div>
        <div className="menu-label">{label}</div>
        <div className="menu-dropdown-icon">
          <RightIcon color={activeValue === active ? '#078DEE' : '#3E4246'} />
        </div>
      </div>
      <div className={`menu-dropdown ${activeValue === active ? 'active' : ''}`}>
        {children.map((menu: SubmenuInterFace) => (
          <Link
            to={menu.url}
            key={menu.url}
            className={`sub-menu ${window.location.pathname === menu.activeURL && 'active'}`}
            onClick={() => redirect(menu.url, active)}>
            {menu.label}
          </Link>
        ))}
      </div>
      <div className={`tab-menu ${activeValue === active ? 'active' : ''}`}>
        <div className="menu-icon">
          <Icon color={activeValue === 'active' ? '#078DEE' : '#3E4246'} />
        </div>
        <div className="menus-list">
          <div className="menu-label">{label}</div>
          {children.map((menu: SubmenuInterFace) => (
            <div
              key={menu.url}
              className={`sub-menu ${window.location.pathname === menu.activeURL && 'active'}`}
              role="presentation"
              onClick={() => redirect(menu.url, active)}>
              {menu.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function Sidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const parts = currentPath.split('/');
  const slicedString = parts.slice(0, 4).join('/');

  const sideBarMenu = [
    {
      icon: PersonIcon,
      rightIcon: DownArrowIcon,
      name: t('personnel'),
      activeURL: '/admin/personnel/personnel',
      key: 'personnel',
      children: [
        {
          label: t('person'),
          url: 'personnel/personnel/person',
          activeURL: '/admin/personnel/personnel/person',
        },
        {
          label: t('department'),
          url: 'personnel/personnel/department',
          activeURL: '/admin/personnel/personnel/department',
        },
        {
          label: t('position'),
          url: 'personnel/personnel/position',
          activeURL: '/admin/personnel/personnel/position',
        },
        {
          label: t('dismissedPersonnel'),
          url: 'personnel/personnel/dismissed-personnel',
          activeURL: '/admin/personnel/personnel/dismissed-personnel',
        },
        {
          label: t('pendingReview'),
          url: 'personnel/personnel/pending-review',
          activeURL: '/admin/personnel/personnel/pending-review',
        },
        {
          label: t('customAttributes'),
          url: 'personnel/personnel/custom-attributes',
          activeURL: '/admin/personnel/personnel/custom-attributes',
        },
        {
          label: t('listLibrary'),
          url: 'personnel/personnel/list-library',
          activeURL: '/admin/personnel/personnel/list-library',
        },
        {
          label: t('parameters'),
          url: 'personnel/personnel/parameters',
          activeURL: '/admin/personnel/personnel/parameters',
        },
      ],
    },
    {
      icon: CardHandIcon,
      rightIcon: DownArrowIcon,
      name: t('cardManagement'),
      activeURL: '/admin/personnel/card-management',
      key: 'personnel',
      children: [
        {
          label: t('card'),
          url: 'personnel/card-management/card',
          activeURL: '/admin/personnel/card-management/card',
        },
        {
          label: t('wiegandFormat'),
          url: 'personnel/card-management/wiegand-format',
          activeURL: '/admin/personnel/card-management/wiegand-format',
        },
        {
          label: t('issuedCardRecord'),
          url: 'personnel/card-management/issued-card-record',
          activeURL: '/admin/personnel/card-management/issued-card-record',
        },
      ],
    },
    {
      icon: AccessDeviceIcon,
      rightIcon: DownArrowIcon,
      name: t('accessDevice'),
      activeURL: '/admin/acc/accessDevice',
      key: 'acc',
      children: [
        {
          label: t('device'),
          url: 'acc/accessDevice/device',
          activeURL: '/admin/acc/accessDevice/device',
        },
        {
          label: t('i/OBoard'),
          url: 'acc/accessDevice/ioBoard',
          activeURL: '/admin/acc/accessDevice/ioBoard',
        },
        {
          label: t('door'),
          url: 'acc/accessDevice/door',
          activeURL: '/admin/acc/accessDevice/door',
        },
        {
          label: t('reader'),
          url: 'acc/accessDevice/reader',
          activeURL: '/admin/acc/accessDevice/reader',
        },
        {
          label: t('auxiliaryInput'),
          url: 'acc/accessDevice/auxiliaryInput',
          activeURL: '/admin/acc/accessDevice/auxiliaryInput',
        },
        {
          label: t('auxiliaryOutput'),
          url: 'acc/accessDevice/auxiliaryOutput',
          activeURL: '/admin/acc/accessDevice/auxiliaryOutput',
        },
        {
          label: t('eventType'),
          url: 'acc/accessDevice/eventType',
          activeURL: '/admin/acc/accessDevice/eventType',
        },
        {
          label: t('daylightsavingtime'),
          url: 'acc/accessDevice/daylightsavingtime',
          activeURL: '/admin/acc/accessDevice/daylightsavingtime',
        },
        {
          label: t('realTimeMonitoring'),
          url: 'acc/accessDevice/realtime-monitoring',
          activeURL: '/admin/acc/accessDevice/realtime-monitoring',
        },
        {
          label: t('alarmMonitoring'),
          url: 'acc/accessDevice/alarm-monitoring',
          activeURL: '/admin/acc/accessDevice/alarm-monitoring',
        },
        {
          label: t('map'),
          url: 'acc/accessDevice/map',
          activeURL: '/admin/acc/accessDevice/map',
        },
      ],
    },
    {
      icon: AccessRuleIcon,
      rightIcon: DownArrowIcon,
      name: t('accessRule'),
      activeURL: '/admin/acc/accessRule',
      key: 'acc',
      children: [
        {
          label: t('timeZones'),
          url: 'acc/accessRule/timeZones',
          activeURL: '/admin/acc/accessRule/timeZones',
        },
        {
          label: t('holidays'),
          url: 'acc/accessRule/holidays',
          activeURL: '/admin/acc/accessRule/holidays',
        },
        {
          label: t('accessLevels'),
          url: 'acc/accessRule/accessLevels',
          activeURL: '/admin/acc/accessRule/accessLevels',
        },
        {
          label: t('setaccessByLevels'),
          url: 'acc/accessRule/setaccessByLevels',
          activeURL: '/admin/acc/accessRule/setaccessByLevels',
        },
        {
          label: t('setAccessByPerson'),
          url: 'acc/accessRule/setAccessByPerson',
          activeURL: '/admin/acc/accessRule/setAccessByPerson',
        },
        {
          label: t('setAccessByDepartment'),
          url: 'acc/accessRule/setAccessByDepartment',
          activeURL: '/admin/acc/accessRule/setAccessByDepartment',
        },
        {
          label: t('interlock'),
          url: 'acc/accessRule/interlock',
          activeURL: '/admin/acc/accessRule/interlock',
        },
        {
          label: t('linkage'),
          url: 'acc/accessRule/linkage',
          activeURL: '/admin/acc/accessRule/linkage',
        },
        {
          label: t('antiPassback'),
          url: 'acc/accessRule/antiPassback',
          activeURL: '/admin/acc/accessRule/antiPassback',
        },
        {
          label: t('firstPersonNormallyOpen'),
          url: 'acc/accessRule/firstPersonNormallyOpen',
          activeURL: '/admin/acc/accessRule/firstPersonNormallyOpen',
        },
        {
          label: t('multiPersonGroup'),
          url: 'acc/accessRule/multi-person-group',
          activeURL: '/admin/acc/accessRule/multi-person-group',
        },
        {
          label: t('multiPersonOpeningDoor'),
          url: 'acc/accessRule/multi-person-opening-door',
          activeURL: '/admin/acc/accessRule/multi-person-opening-door',
        },
        {
          label: t('verificationMode'),
          url: 'acc/accessRule/verification-mode',
          activeURL: '/admin/acc/accessRule/verification-mode',
        },
        {
          label: t('verificationModeGroup'),
          url: 'acc/accessRule/verification-mode-group',
          activeURL: '/admin/acc/accessRule/verification-mode-group',
        },
        {
          label: t('parameters'),
          url: 'acc/accessRule/parameters',
          activeURL: '/admin/acc/accessRule/parameters',
        },
      ],
    },
    {
      icon: AdvancedFuncIcon,
      rightIcon: DownArrowIcon,
      name: t('advancedFunctions'),
      activeURL: '/admin/acc/advancedFunctions',
      key: 'acc',
      children: [
        {
          label: t('zone'),
          url: 'acc/advancedFunctions/zone',
          activeURL: '/admin/acc/advancedFunctions/zone',
        },
        {
          label: t('readerDefine'),
          url: 'acc/advancedFunctions/reader-define',
          activeURL: '/admin/acc/advancedFunctions/reader-define',
        },
        {
          label: t('whoIsInside'),
          url: 'acc/advancedFunctions/areaHeadCount',
          activeURL: '/admin/acc/advancedFunctions/areaHeadCount',
        },
        {
          label: t('globalAntiPassback'),
          url: 'acc/advancedFunctions/globalAntiPassback',
          activeURL: '/admin/acc/advancedFunctions/globalAntiPassback',
        },
        {
          label: t('globalInterlock'),
          url: 'acc/advancedFunctions/globalInterlock',
          activeURL: '/admin/acc/advancedFunctions/globalInterlock',
        },
        {
          label: t('globalLinkage'),
          url: 'acc/advancedFunctions/globalLinkage',
          activeURL: '/admin/acc/advancedFunctions/globalLinkage',
        },
        {
          label: t('globalInterlockGroup'),
          url: 'acc/advancedFunctions/globalInterlockGroup',
          activeURL: '/admin/acc/advancedFunctions/globalInterlockGroup',
        },
        {
          label: t('personAvailability'),
          url: 'acc/advancedFunctions/person-Availability',
          activeURL: '/admin/acc/advancedFunctions/person-Availability',
        },
        {
          label: t('occupancyControl'),
          url: 'acc/advancedFunctions/occupancyControl',
          activeURL: '/admin/acc/advancedFunctions/occupancyControl',
        },
        {
          label: t('musterPoint'),
          url: 'acc/advancedFunctions/musterPoint',
          activeURL: '/admin/acc/advancedFunctions/musterPoint',
        },
        {
          label: t('musterPointReport'),
          url: 'acc/advancedFunctions/musterPoint-Report',
          activeURL: '/admin/acc/advancedFunctions/musterPoint-Report',
        },
      ],
    },
    {
      icon: AccControlReportIcon,
      rightIcon: DownArrowIcon,
      name: t('accControlReports'),
      activeURL: '/admin/acc/accControlReports',
      key: 'acc',
      children: [
        {
          label: t('allTransaction'),
          url: 'acc/accControlReports/allTransactions',
          activeURL: '/admin/acc/accControlReports/allTransactions',
        },
        {
          label: t('eventsFromToday'),
          url: 'acc/accControlReports/events-from-today',
          activeURL: '/admin/acc/accControlReports/events-from-today',
        },
        {
          label: t('allExceptionEvents'),
          url: 'acc/accControlReports/all-exception-events',
          activeURL: '/admin/acc/accControlReports/all-exception-events',
        },
        {
          label: t('alarmLog'),
          url: 'acc/accControlReports/alarm-log',
          activeURL: '/admin/acc/accControlReports/alarm-log',
        },
        {
          label: t('alarmProcessingHistory'),
          url: 'acc/accControlReports/alarm-processing-history',
          activeURL: '/admin/acc/accControlReports/alarm-processing-history',
        },
        {
          label: t('accessRightsByDoor'),
          url: 'acc/accControlReports/access-rights-by-door',
          activeURL: '/admin/acc/accControlReports/access-rights-by-door',
        },
        {
          label: t('accessRightsByPersonnel'),
          url: 'acc/accControlReports/access-rights-by-personnel',
          activeURL: '/admin/acc/accControlReports/access-rights-by-personnel',
        },
        {
          label: t('firstInLastOut'),
          url: 'acc/accControlReports/firstIn-and-lastOut',
          activeURL: '/admin/acc/accControlReports/firstIn-and-lastOut',
        },
      ],
    },
    {
      icon: SystemIcon,
      rightIcon: DownArrowIcon,
      name: t('system'),
      activeURL: '/admin/system/system-management',
      key: 'system',
      children: [
        {
          label: t('systemManagement'),
          url: 'system/system-management/operation-log',
          activeURL: '/admin/system/system-management/operation-log',
        },
        {
          label: t('databaseManagement'),
          url: 'system/system-management/database-management',
          activeURL: '/admin/system/system-management/database-management',
        },
        {
          label: t('areaSettings'),
          url: 'system/system-management/area-settings',
          activeURL: '/admin/system/system-management/area-settings',
        },
        {
          label: t('systemParameter'),
          url: 'system/system-management/system-Parameter',
          activeURL: '/admin/system/system-management/system-Parameter',
        },
        {
          label: t('emailManagement'),
          url: 'system/system-management/email-management',
          activeURL: '/admin/system/system-management/email-management',
        },
        {
          label: t('dictionaryManagement'),
          url: 'system/system-management/dictionary-management',
          activeURL: '/admin/system/system-management/dictionary-management',
        },
        {
          label: t('dataCleaning'),
          url: 'system/system-management/data-cleaning',
          activeURL: '/admin/system/system-management/data-cleaning',
        },
        {
          label: t('resourceFile'),
          url: 'system/system-management/resource-file',
          activeURL: '/admin/system/system-management/resource-file',
        },
        // {
        //   label: t('cloudSettings'),
        //   url: 'system/system-management/cloud-settings',
        //   activeURL: '/admin/system/system-management/cloud-settings',
        // },
        {
          label: t('certificateType'),
          url: 'system/system-management/certificate-type',
          activeURL: '/admin/system/system-management/certificate-type',
        },
      ],
    },
  ];

  const [activeValue, setActive] = useState(slicedString);

  const openDropDown = (key: string) => {
    setActive((prev: any) => (prev === key ? '' : key));
  };

  const redirect = (url: string): void => {
    navigate(url);
  };
  // Filter the sidebar items to only include those key and split words matched
  const filterMenu = sideBarMenu.filter((menu) => menu?.key === parts?.[2]);
  useEffect(() => {
    if (slicedString) {
      setActive(slicedString);
    }
  }, [slicedString]);
  return (
    <div className="sidebar-container">
      <div className="header-logo">
        <div className="image">
          <img src={logo} alt="logo_image" />
        </div>
        <div className="image">
          <img src={logo1} alt="logo_image_1" />
        </div>
      </div>
      <div className="sidebar-content">
        <div className="sidebar-menus">
          {filterMenu.map((sidebar) => (
            <SidebarSession
              key={sidebar.name}
              redirect={redirect}
              activeValue={activeValue}
              openDropDown={openDropDown}
              menus={{
                Icon: sidebar.icon,
                RightIcon: sidebar.rightIcon,
                label: sidebar.name,
                active: sidebar.activeURL,
                children: sidebar.children,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
