import { get, post } from './base/index';

export default {
  getDoors: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/door/getByPage`, {}, { ...data });
  },

  cancelAlarm: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/door/cancelAlarm?ids=${data}`, {}),

  doorSave: (data: any = {}) => post(`${process.env.REACT_APP_API_URL}Acc/api/door/edit`, data, {}),

  /**
   * @param endpoint are come from the component it should be dynamic
   * @param data = id
   * @returns
   */
  doorActionsApi: (endpoint: any = '', data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/door/${endpoint}?ids=${data}`, {}),
  remoteOpen: (data: any = {}, interval: any = {}) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/door/remoteOpenById?doorId=${data}&interval=${interval}`,
      {},
    ),
  remoteClose: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/door/remoteCloseById?doorId=${data}`, {}),

  normalOpenDoor: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/door/normalOpenDoor?ids=${data}`, {}),

  getDoorIds: (data: any = {}) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/door/getDoorIds?ids=${data.ids}&type=${data.type}`,
      {},
    ),

  getVerifyMode: (data: any = {}) =>
    get(
      `${process.env.REACT_APP_API_URL}Acc/api/door/getVerifyMode/{deviceId}?deviceId=${data}`,
      {},
    ),
  getWiegantFmtList: () =>
    get(`${process.env.REACT_APP_API_URL}Acc/api/door/getWiegandFmtList`, {}),
  doorGetById: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/door/getById/${data}`, {}),
};
