import React, { useEffect, useState } from 'react';

// Use interface
import {
  MultiSelectBoxFieldInterFace,
  SelectItemInterFace,
} from '../../interface/common.interface';

// Use assets svg icons
import {
  DownArrowIcon,
  CloseIcon,
  CheckBoxActiveIcon,
  CheckBoxIcon,
} from '../../assets/svgicons/svgicon';

function MultiSelectBoxField({
  name,
  label,
  id,
  data,
  value = [],
  className,
  disabled,
  required,
  message,
  errorStatus,
  onChangeFunction,
}: MultiSelectBoxFieldInterFace) {
  const [selectValue, setSelectValue] = useState<string>('');
  const [selectItems, setSelectItems] = useState(data);

  const onKeyup = (e: any): void => {
    setSelectValue(e.target.value);
    setSelectItems(data.filter((val) => val.label.includes(e.target.value)));
  };
  const selectItem = (item: SelectItemInterFace): void => {
    if (value?.includes(item.id)) {
      onChangeFunction(
        name,
        value.filter((val) => val !== item.id),
      );
    } else {
      onChangeFunction(name, [...value, item.id]);
    }

    setSelectValue('');
    setSelectItems(data);
  };
  const closeData = (): void => {
    console.log('afsdf');

    onChangeFunction(name, []);
    setSelectValue('');
    setSelectItems(data);
  };
  useEffect((): void => {
    const selectData = data.filter((val) => value.includes(val.id));
    if (selectData.length > 0) {
      setSelectValue(selectData.length > 1 ? `${selectData.length} items` : selectData[0].label);
    } else {
      setSelectValue('');
    }
  }, [value]);

  useEffect(() => {
    setSelectItems(data);
  }, [data]);
  return (
    <div className="form-select-item">
      <input
        type="text"
        id={id}
        className={`input_field ${className}`}
        value={selectValue}
        autoComplete="off"
        name={name}
        onChange={onKeyup}
        disabled={disabled}
      />
      <label className={`input-label ${className}`} htmlFor={id}>
        {label}
        {required && <span>*</span>}
      </label>
      <div className="input-right-close-icon" onClick={closeData} role="presentation">
        <CloseIcon />
      </div>
      <div className="input-right-icon">
        <DownArrowIcon />
      </div>
      <div className="select-items">
        {selectItems.map((item) => (
          <div className="select-item" onClick={() => selectItem(item)} role="presentation">
            {value.includes(item.id) ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}

            <span>{item.label}</span>
          </div>
        ))}
        {selectItems.length === 0 && <div className="select-item disabled">No Data Found</div>}
      </div>
      {errorStatus && <small className="input-error-message">{message}</small>}
    </div>
  );
}

export default MultiSelectBoxField;
