/* eslint-disable no-plusplus */
import React, { useEffect, useRef, useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';

// Use Interface
import { CardBatchIssueCardInterFace } from '../../../interface/card.interface';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import { fetchDeviceList } from '../../../redux/slices/DeviceSlice';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import wiegandFormat from '../../../utils/api/wiegantFormat';
import Label from '../../../shared-components/label/Label';

function BatchIssueCard({ closeModal }: CardBatchIssueCardInterFace) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { devices } = useAppSelector((state: any) => state.device);

  const [departmentValue, setDepartmentValue] = useState({
    device: '',
    autoCalculate: [],
    cardEnrollmentMethod: '',
    cardFormat: '',
    oddEventParityFormat: '',
    cardsList: [
      {
        title: t('card1'),
        name: 'card1',
        siteCode: '',
        cardNumber: '',
        orgCardNo: '',
        bitCount: '',
      },
      {
        title: t('card2'),
        name: 'card2',
        siteCode: '',
        cardNumber: '',
        orgCardNo: '',
        bitCount: '',
      },
      {
        title: t('card3'),
        name: 'card3',
        siteCode: '',
        cardNumber: '',
        orgCardNo: '',
        bitCount: '',
      },
    ],
  });

  const [count, setCount] = useState(60);
  const [disable, setDisable] = useState(false);

  const onChangeValue = (name: string, value: any) => {
    setDepartmentValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeValueCard = (name: string, value: any) => {
    const [field, index]: any = name.split('_');

    setDepartmentValue((prev) => {
      const updatedCardsList: any = [...prev.cardsList];
      updatedCardsList[index][field] = value;

      return { ...prev, cardsList: updatedCardsList };
    });
  };

  const readerCard = async () => {
    let countDown = 60;
    setCount(countDown);

    intervalRef.current = setInterval(async () => {
      if (countDown <= 0) {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        setCount(60);
        return;
      }

      countDown--;
      setCount(countDown);

      try {
        const response = await wiegandFormat.readerCard({ deviceId: departmentValue.device });
        if (response.data.success) {
          // Parse the response data to get the Wiegand bits
          const responseData = response.data.data.split(',')[0];
          // Extract the bits value and bits count from the response
          const bitsMatch = responseData.match(/bits=([01]+)/);
          const bitsCountMatch = responseData.match(/bitscount=(\d+)/);

          const orgCardNo = bitsMatch ? bitsMatch[1] : '';
          const bitCount = bitsCountMatch ? bitsCountMatch[1] : '';

          // Always update the first card (index 0)
          setDepartmentValue((prev) => ({
            ...prev,
            cardsList: prev.cardsList.map((card, index) =>
              index === 0 ? { ...card, orgCardNo, bitCount } : card,
            ),
          }));
        }
      } catch (error: any) {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      }
    }, 1000);

    timeoutRef.current = setTimeout(() => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      setCount(60);
    }, 60000);
  };

  const handleReadCard = async () => {
    try {
      const response = await wiegandFormat.startReader({
        deviceId: departmentValue.device,
      });
      if (response.data.success) {
        readerCard();
      } else {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.status || 500,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const handleClearCardInformation = () => {
    setDepartmentValue((prev) => ({
      ...prev,
      cardsList: prev.cardsList.map((card) => ({
        ...card,
        orgCardNo: '',
        cardNumber: '',
        siteCode: '',
      })),
    }));
  };

  const handleRecommendedCardFormat = async () => {
    try {
      const response = await wiegandFormat.recommendFmt({
        deviceId: departmentValue.device,
        cardNo: departmentValue.cardsList[0].cardNumber,
        orgCardNo: departmentValue.cardsList[0].orgCardNo,
        sizeCode: departmentValue.cardsList[0].siteCode,
        bitscount: departmentValue.cardsList[0].bitCount,
      });
      if (response.data.success) {
        const regex = /parityFmt=([^,]+),\s*cardFmt=([^}]+)/;
        const parity = response.data.data.match(regex);
        setDepartmentValue((prev) => ({
          ...prev,
          cardFormat: parity?.[1],
          oddEventParityFormat: parity?.[2],
        }));
      } else {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.status || 500,
          }),
        );
      }
    } catch (error: any) {
      dispatch(setNotificationError({ error: error.response.data.msg, status: error.status }));
    }
  };

  const handleSubmit = () => {
    if (departmentValue.device) {
      wiegandFormat.stopReader({ deviceId: departmentValue.device });
    }
  };

  const handleCloseModal = () => {
    // Stop the reader and clear intervals before closing
    if (departmentValue.device) {
      wiegandFormat.stopReader({ deviceId: departmentValue.device });
    }
    setCount(60); // Reset count
    closeModal();
  };

  useEffect(() => {
    dispatch(fetchDeviceList({ pageNo: 1 }));
  }, [dispatch]);

  // Add useEffect to handle cleanup
  // useEffect(() => {
  //   let cleanup: (() => void) | undefined;

  //   const handleReadCardWithCleanup = async () => {
  //     cleanup = await readerCard();
  //   };

  //   if (count !== 60) {
  //     handleReadCardWithCleanup();
  //   }

  //   // Cleanup function that runs when component unmounts
  //   return () => {
  //     // Stop any ongoing card reading
  //     if (cleanup) {
  //       cleanup();
  //     }

  //     // Always stop the reader when unmounting, regardless of cleanup status
  //     if (departmentValue.device) {
  //       wiegandFormat.stopReader({ deviceId: departmentValue.device });
  //     }
  //   };
  // }, []);

  useEffect(() => {
    // Cleanup function to stop interval and timeout on unmount or modal close
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (departmentValue.device) {
        wiegandFormat.stopReader({ deviceId: departmentValue.device });
      }
    };
  }, []);

  useEffect(() => {
    if (
      departmentValue.device &&
      departmentValue.cardFormat &&
      departmentValue.oddEventParityFormat
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [departmentValue]);

  return (
    <div className="card-format-testing">
      <div className="card-format-testing-form">
        <div className="row-form">
          <div className="col flex-1">
            <SelectBoxField
              name="device"
              label={t('device')}
              id="device"
              data={devices.map((item: any) => ({
                label: item.alias,
                id: item.id,
                value: item.id,
              }))}
              value={departmentValue.device}
              className=""
              required
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            />
          </div>
          <div className="col">
            <Button
              onClickFunction={handleReadCard}
              className="btn-primary"
              title={`${t('readCard')}${count !== 60 ? ` (${count})` : ''}`}
              disabled={!departmentValue.device || count !== 60}
            />
          </div>
          <div className="col">
            <Button
              onClickFunction={handleClearCardInformation}
              className="btn-primary"
              title={t('clearCardInformation')}
            />
          </div>
        </div>
        <div className="column-form">
          {departmentValue.cardsList.map((val, index) => (
            <div className="col">
              <div className="title">{val.title}</div>
              <div className="rows">
                <InputField
                  name={`siteCode_${index}`}
                  label={t('siteCode')}
                  id={`siteCode_${index}`}
                  type="text"
                  value={val.siteCode}
                  onChangeFunction={onChangeValueCard}
                />
                <InputField
                  name={`cardNumber_${index}`}
                  label={t('cardNumber')}
                  id={`cardNumber_${index}`}
                  type="text"
                  required
                  value={val.cardNumber}
                  onChangeFunction={onChangeValueCard}
                />
                <div className="org-card-no">
                  {val.bitCount && (
                    <Label title={`(Current card number length: ${val.bitCount} bits)`} />
                  )}
                  <InputField
                    name={`orgCardNo_${index}`}
                    label={t('originalCardNumber')}
                    id={`orgCardNo${index}`}
                    type="text"
                    value={val.orgCardNo}
                    onChangeFunction={onChangeValueCard}
                    disabled
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className="text-danger">
          {t('noteWhenYouContinueToReadANewCardPleaseManuallySwitchToTheNextCard')}
        </p>
        <div className="row-form">
          <div className="col">
            <Button
              onClickFunction={handleRecommendedCardFormat}
              className="btn-primary"
              disabled={
                !departmentValue?.cardsList?.[0]?.orgCardNo &&
                !departmentValue?.cardsList?.[0]?.cardNumber
              }
              title={t('recommendedCardFormat')}
            />
          </div>
          <div className="col">
            <CheckBoxInputField
              name="autoCalculate"
              data={[
                {
                  label: t('autoCalculateSiteCodeWhileTheSiteCodeIsLeftBlank'),
                  id: 'yes',
                  value: 'yes',
                },
              ]}
              value={departmentValue.autoCalculate}
              onChangeFunction={onChangeValue}
            />
          </div>
        </div>
        <div className="row-form">
          <InputField
            name="cardFormat"
            label={t('cardFormat')}
            id="cardFormat"
            type="text"
            required
            value={departmentValue.cardFormat}
            onChangeFunction={onChangeValue}
          />
          <InputField
            name="oddEventParityFormat"
            label={t('oddEventParityFormat')}
            id="oddEventParityFormat"
            type="text"
            required
            value={departmentValue.oddEventParityFormat}
            onChangeFunction={onChangeValue}
          />
        </div>
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button onClickFunction={handleCloseModal} title={t('back')} className="btn-default" />
          <Button
            onClickFunction={handleSubmit}
            title={t('done')}
            className="btn-primary"
            disabled={disable}
          />
        </div>
      </div>
    </div>
  );
}

export default BatchIssueCard;
