import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

// Use own component
import Records from './components/record';
import DiskSpaceCleanup from './components/diskSpaceCleanup';
import System from './components/system';

// Use redux function
import { fetchParametersList } from '../../../../redux/slices/ParameterSlices';

// Use shared components
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import TabPages from '../../../../shared-components/tab-pages/tab-pages';
import Button from '../../../../shared-components/button/button';
import dataCleaning from '../../../../utils/api/SystemManagement/dataCleaning';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';

// Use interface
import { ParameterReduxInterface } from '../../../../interface/parameter.interface';

function DataCleaning() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  let sortTime = true;
  const { parameters, status }: ParameterReduxInterface = useAppSelector(
    (state: any) => state.parameters,
  );
  const initialState = {
    accReportDataCleanKeptMonth: '',
    accReportDataCleanKeptType: '',
    accReportDataCleanTime: '',
    eleReportDataCleanKeptMonth: '',
    eleReportDataCleanKeptType: '',
    eleReportDataCleanTime: '',
    parkReportDataCleanKeptMonth: '',
    parkReportDataCleanKeptType: '',
    parkReportDataCleanTime: '',
    attReportDataCleanKeptMonth: '',
    attReportDataCleanKeptType: '',
    attReportDataCleanTime: '',
    visReportDataCleanKeptMonth: '',
    visReportDataCleanKeptType: '',
    visReportDataCleanTime: '',
    systemDataCleanOpLogKeptMonth: '',
    systemDataCleanOpLogTime: '',
    systemDataCleanCmdDataKeptMonth: '',
    systemDataCleanCmdDataTime: '',
    systemDataCleanDbBakKeptMonth: '',
    systemDataCleanDbBakTime: '',
  };
  const [parametersValue, setParameters] = useState(parameters || initialState);

  const onChangeValue = (value: any) => {
    setParameters((prev: any) => ({
      ...prev,
      ...value,
    }));
  };

  const [tabs, setTabs] = useState([
    {
      key: 'record',
      title: t('record'),
      content: <Records onChangeFunction={onChangeValue} values={parametersValue} />,
    },
  ]);

  const fetchParameterData = () => {
    if (status === 'success') {
      dispatch(fetchParametersList({}));
    }
  };

  const validateFields = () => {
    const emptyFields = Object.entries(parametersValue).filter(([value]) => value === '');
    console.log('Empty Fields:', emptyFields);
    return emptyFields.length > 0;
  };

  const submit = () => {
    if (validateFields()) {
      dispatch(
        setNotificationError({
          error: t('Please fill in all required fields'),
          status: 400,
        }),
      );
      return;
    }

    const params = {
      accReportDataCleanKeptMonth: parseInt(parametersValue.accReportDataCleanKeptMonth, 10) || 1,
      accReportDataCleanKeptType: parametersValue.accReportDataCleanKeptType || '',
      accReportDataCleanTime: parametersValue.accReportDataCleanTime || '01:00:00',
      eleReportDataCleanKeptMonth: parseInt(parametersValue.eleReportDataCleanKeptMonth, 10) || 2,
      eleReportDataCleanKeptType: parametersValue.eleReportDataCleanKeptType || '',
      eleReportDataCleanTime: parametersValue.eleReportDataCleanTime || '01:00:00',
      parkReportDataCleanKeptMonth: parseInt(parametersValue.parkReportDataCleanKeptMonth, 10) || 3,
      parkReportDataCleanKeptType: parametersValue.parkReportDataCleanKeptType || '',
      parkReportDataCleanTime: parametersValue.parkReportDataCleanTime || '02:00:00',
      attReportDataCleanKeptMonth: parseInt(parametersValue.attReportDataCleanKeptMonth, 10) || 2,
      attReportDataCleanKeptType: parametersValue.attReportDataCleanKeptType || '',
      attReportDataCleanTime: parametersValue.attReportDataCleanTime || '03:00:0',
      visReportDataCleanKeptMonth: parseInt(parametersValue.visReportDataCleanKeptMonth, 10) || 1,
      visReportDataCleanKeptType: parametersValue.visReportDataCleanKeptType || '',
      visReportDataCleanTime: parametersValue.visReportDataCleanTime || '02:00:00',
      systemDataCleanOpLogKeptMonth:
        parseInt(parametersValue.systemDataCleanOpLogKeptMonth, 10) || 6,
      systemDataCleanOpLogTime: parametersValue.systemDataCleanOpLogTime || '03:00:00',
      systemDataCleanCmdDataKeptMonth:
        parseInt(parametersValue.systemDataCleanCmdDataKeptMonth, 10) || 6,
      systemDataCleanCmdDataTime: parametersValue.systemDataCleanCmdDataTime || '02:00:00',
      systemDataCleanDbBakKeptMonth:
        parseInt(parametersValue.systemDataCleanDbBakKeptMonth, 10) || 6,
      systemDataCleanDbBakTime: parametersValue.systemDataCleanDbBakTime || '04:00:00',
    };

    dataCleaning
      .saveList(params)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('Data Cleaning Added Successfully'),
              status: 200,
            }),
          );
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    if (Object.keys(parameters).length !== 0) {
      setParameters({ ...parameters });
    }
  }, [parameters]);

  useEffect(() => {
    if (Object.keys(parametersValue).length > 0) {
      setTabs([
        {
          key: 'record',
          title: t('record'),
          content: <Records onChangeFunction={onChangeValue} values={parametersValue} />,
        },
        {
          key: 'diskSpaceCleanup',
          title: t('diskSpaceCleanup'),
          content: <DiskSpaceCleanup onChangeFunction={onChangeValue} values={parametersValue} />,
        },
        {
          key: 'system',
          title: t('system'),
          content: <System onChangeFunction={onChangeValue} values={parametersValue} />,
        },
      ]);
    }
  }, [parametersValue]);

  useEffect(() => {
    if (Object.keys(parameters).length === 0 && sortTime) {
      fetchParameterData();
      sortTime = false;
    }
  }, []);

  return (
    <div className="parameters">
      <TopHeader
        title={t('system')}
        broadCram={[{ title: t('systemManagement') }, { title: t('dataCleaning') }]}
      />
      <CardBox title={t('parameters')}>
        <TabPages tabs={tabs} defaultNumber={0} />

        <div className="parameters-buttons">
          <div className="form-buttons-right">
            <Button onClickFunction={submit} title={t('done')} className="btn-primary btn-bold" />
          </div>
        </div>
      </CardBox>
    </div>
  );
}

export default DataCleaning;
