import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppSelector, useAppDispatch } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import { AddIcon } from '../../assets/svgicons/svgicon';
import AddInterlock from './components/AddInterlock';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import interlockApi from '../../utils/api/AccessRule/interlock';
import {
  fetchInterlock,
  setSearchFilter,
  deleteInterlock,
} from '../../redux/slices/InterlockSlices';

function Interlock() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { interlock, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.interlock,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const fetchData = (params: any) => {
    if (status === 'success') {
      dispatch(fetchInterlock({ ...params }));
    }
  };
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'deviceName',
      header: t('deviceName'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.deviceName}
        </div>
      ),
    },
    {
      accessorKey: 'interlockRuleShow',
      header: t('interlockRule'),
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  handleEditOpen = (editData: any) => {
    const updateData = {
      ...editData,
      id: editData?.id,
      name: editData?.deviceName,
      deviceId: editData?.deviceId,
      interlockRuleShow: String(editData?.interlockRule),
    };

    setModalOpen(true);
    setModalView({
      title: t('edit'),
      content: <AddInterlock closeModal={() => setModalOpen(false)} data={{ ...updateData }} />,
    });
  };
  const deleteRecord = (id: any) => {
    interlockApi
      .deleteInterlock(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('interlockDeletedSuccessfully'),

              status: 200,
            }),
          );
          dispatch(deleteInterlock(id));
          fetchData({
            pageNo: searchFilter?.pageIndex,
            pageSize: searchFilter?.pageSize,
          });
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('interlock'),
          },
        ]}
      />
      <CardBox
        title={t('listOfDevices')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddInterlock closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        {' '}
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)} // Set selected cards
          checkBoxActive
          enablePagination
          // isFetching={status === 'loading'} // Placeholder status
          values={interlock} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default Interlock;
