import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import SwapTable from '../../../shared-components/swapTables/swapTable';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import FormRightButton from '../../../shared-components/form-right-buttons/FormRightButton';
import multiPersonGroup from '../../../utils/api/AccessRule/multiPersonGroup';
import firstPersonNormallyOpen from '../../../utils/api/AccessRule/firstPersonNormallyOpen';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { fetchDepartmentList } from '../../../redux/slices/DepartmentSlices';

interface PersonProps {
  equals: boolean;
  id: string;
  code: string;
  name: string;
  sortNo: number;
  createTime: number;
  initCode: string;
  departmentId: string;
  creationDate: string;
}

interface ApiParams {
  personIds: string;
  accFirstOpenId: any;
  type: string;
}
interface ModalType {
  closeModal: () => void;
  personIds: string;
  firstOpenId: string;
}
function AddPersonnel({ closeModal, personIds, firstOpenId }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const allDepartments = useAppSelector((state: any) => state.departments.departments);
  const options = [
    {
      label: t('query'),
      id: 'query',
      value: 'query',
    },
    {
      label: t('department'),
      id: 'department',
      value: 'department',
    },
  ];

  const [type, setType] = useState('query');
  const [persons, setPersons] = useState<PersonProps[]>([]); // Available persons
  const [selectedPerson, setSelectedPerson] = useState<PersonProps[]>([]); // Selected persons
  const [deptName, setDeptName] = useState('');
  const [search, setSearch] = useState('');
  const tableColumns = [
    { label: t('personnelId'), id: 'personPin' },
    { label: t('firstName'), id: 'personName' },
    { label: t('lastName'), id: 'personLastName' },
    { label: t('department'), id: 'deptName' },
  ];

  const fetchLevel = (params: ApiParams) => {
    multiPersonGroup
      .getPersonlist(params)
      .then((res) => {
        setPersons(res.data.data);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };
  // Function to handle table swapping
  const onTableSwap = (newAvailableData: PersonProps[], newSelectedData: PersonProps[]) => {
    setPersons(newAvailableData); // Update available data
    setSelectedPerson(newSelectedData); // Update selected data
  };

  const addPerson = async () => {
    const selectedIds = selectedPerson.map((item: PersonProps) => item.id).join(',');
    const req = {
      firstOpenId,
      personIds: type === 'query' ? selectedIds : '',
      deptId: type === 'query' ? '' : deptName,
    };
    firstPersonNormallyOpen
      .addPerson(req)
      .then((response) => {
        if (response.data.success) {
          const message = t('personAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code || 500,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };

  const submit = async () => {
    const departmentReq = {
      deptIds: deptName,
    };

    if (type !== 'query') {
      try {
        const response = await firstPersonNormallyOpen.getPersonCountByDept(departmentReq);
        if (response.data.success) {
          addPerson();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code || 500,
            }),
          );
        }
      } catch (error: any) {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      }
    } else {
      addPerson();
    }
  };
  useEffect(() => {
    fetchLevel({
      personIds,
      type: 'noSelected',
      accFirstOpenId: firstOpenId,
    });
    dispatch(fetchDepartmentList({ pageNo: 1, pageSize: 10 }));
  }, [personIds]);

  return (
    <div className="access-rule-add-personnel flex-row column">
      <RadioButtonField
        label=""
        name="recurring"
        alignment="row-wrap"
        data={options}
        value={type}
        onChangeFunction={(name, value: any) => setType(value)}
      />
      {type === 'department' && (
        <div className="select-input">
          <SelectBoxField
            id="deptName"
            label={t('departmentName')}
            value={deptName}
            name="deptName"
            data={allDepartments.map((item: PersonProps) => ({
              label: item.name,
              id: item.departmentId,
            }))}
            onChangeFunction={(name: any, value: any) => setDeptName(value.id)}
          />
          <p className="text-danger">Note: Importing all the personnel of selected departments</p>
        </div>
      )}
      {type === 'query' && (
        <SwapTable
          searchValue={search}
          changeSearch={(name: string, value: string) => setSearch(value)}
          tableColumns={tableColumns}
          tableData={persons}
          selectedTableData={selectedPerson}
          onTableSwap={onTableSwap}
        />
      )}
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={selectedPerson.length === 0 && deptName === ''}
      />
    </div>
  );
}

export default AddPersonnel;
