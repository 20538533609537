import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

// Use shared components
import Button from '../../../shared-components/button/button';
import MultiSelectBoxField from '../../../shared-components/multi-selectbox-field/multi-selectbox-field';

// Use interface
import { StatisticsInterface } from '../../../interface/permissions.interface';

// Use redux function
import { fetchStatistics } from '../../../redux/slices/PersonSlices';

// Use assets svg ions
import {
  FaceIcon,
  FingerPrintIcon,
  Gender1BackGroundIcon,
  Gender2BackGroundIcon,
  Gender3BackGroundIcon,
  Gender4BackGroundIcon,
  HandFaceIcon,
} from '../../../assets/svgicons/svgicon';
import { fetchDepartmentList } from '../../../redux/slices/DepartmentSlices';
import { DepartmentReduxInterface } from '../../../interface/department.interface';

function StatisticsModal({ closeModal }: StatisticsInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const statistics = useAppSelector((state: any) => state.persons.statistics);
  const { departments }: DepartmentReduxInterface = useAppSelector(
    (state: any) => state.departments,
  );

  const [selectValue, setSelectValue] = useState<any[]>([]);

  const changeDepartment = (name: string, val: any) => {
    setSelectValue(val);
    const departmentIds = departments
      .filter((depart: any) => val.includes(depart.departmentId))
      .map((depart: any) => depart.departmentId);
    const formData = new FormData();
    formData.append('deptIds', departmentIds.join(','));
    dispatch(fetchStatistics(formData));
  };

  useEffect(() => {
    if (departments.length === 0) {
      dispatch(fetchDepartmentList({ pageNo: 1, pageSize: 10 }));
    }
  }, []);

  useEffect(() => {
    if (departments.length > 0) {
      const formData = new FormData();
      formData.append('deptIds', departments[0]?.departmentId);
      setSelectValue([departments[0]?.departmentId]);
      dispatch(fetchStatistics(formData));
    }
  }, []);

  return (
    <div className="modal-page">
      <div className="statistics-page">
        <div className="item span-2">
          <MultiSelectBoxField
            name="department"
            label={t('department')}
            id="department"
            value={selectValue}
            required
            onChangeFunction={changeDepartment}
            data={departments.map((item) => ({ id: item.departmentId, label: item.name }))}
          />
        </div>
        <div className="item span-2" />
        <div className="item">
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                <div className="title">{t('male')}</div>
                <div className="sub-title">{statistics.maleCount}</div>
              </div>
              <div className="card-title-right">
                <Gender1BackGroundIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                <div className="title">{t('female')}</div>
                <div className="sub-title">{statistics.feMaleCount}</div>
              </div>
              <div className="card-title-right">
                <Gender2BackGroundIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                <div className="title">{t('unknown')}</div>
                <div className="sub-title">{statistics.unknownCount}</div>
              </div>
              <div className="card-title-right">
                <Gender3BackGroundIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                <div className="title">{t('total')}</div>
                <div className="sub-title">{statistics.personCount}</div>
              </div>
              <div className="card-title-right">
                <Gender4BackGroundIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="item span-2">
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                <div className="title">{t('fingerprint')}</div>
                <div className="sub-title">{statistics.fpCount}</div>
              </div>
              <div className="card-title-right">
                <FingerPrintIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                <div className="title">{t('palmVein')}</div>
                <div className="sub-title">{statistics.palmCount}</div>
              </div>
              <div className="card-title-right">
                <HandFaceIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                <div className="title">{t('visibleFace')}</div>
                <div className="sub-title">{statistics.faceCount}</div>
              </div>
              <div className="card-title-right">
                <FaceIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="item span-4">
          <div className="types-list">
            <div className="items">
              <div className="title">{t('statisticalType')}</div>
              <div className="title">{t('currentTotal')}</div>
            </div>
            <div className="items">
              <div className="value width-full">{t('nearInfraredFace')}</div>
              <div className="value">-</div>
              <div className="value width-full-right">{statistics.cropFaceCount}</div>
            </div>
            <div className="items">
              <div className="value width-full">{t('fingerVein')}</div>
              <div className="value">-</div>
              <div className="value width-full-right">{statistics.fvCount}</div>
            </div>
            <div className="items">
              <div className="value width-full">{t('card')}</div>
              <div className="value">-</div>
              <div className="value width-full-right">{statistics.cardCount}</div>
            </div>
            <div className="items">
              <div className="value width-full">{t('facePicture')}</div>
              <div className="value">-</div>
              <div className="value width-full-right">{statistics.faceCount}</div>
            </div>
            <div className="items">
              <div className="value width-full">{t('visibleLightPalm')}</div>
              <div className="value">-</div>
              <div className="value width-full-right">{statistics.vislightCount}</div>
            </div>
          </div>
        </div>
        <div className="item span-4">
          <div className="form-buttons-right">
            <Button
              onClickFunction={() => {
                closeModal();
              }}
              title={t('close')}
              className="btn-primary btn-bold"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatisticsModal;
