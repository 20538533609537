import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';
import InputField from '../../../shared-components/input-field/input-field';
import FormRightButton from '../../../shared-components/form-right-buttons/FormRightButton';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import multiPersonGroup from '../../../utils/api/AccessRule/multiPersonGroup';
import { fetchMultiPersonGroup } from '../../../redux/slices/MultiPersonGroupSlices';

interface AddMultiPersonProps {
  id?: any;
  name: string;
  remark: string;
}
interface ModalType {
  closeModal: () => void;
  data?: AddMultiPersonProps;
  searchFilter?: {
    pageIndex: 1;
    pageSize: 10;
  };
}

function AddMultiPersonGroup({ closeModal, data, searchFilter }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<AddMultiPersonProps>({
    id: data?.id || '',
    name: data?.name || '',
    remark: data?.remark || '',
  });

  const [checkExistName, setCheckExistName] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const onChangeValue = (name: string, values: any) => {
    setValue((prev: AddMultiPersonProps) => ({
      ...prev,
      [name]: values,
    }));
  };
  const onBlur = async (name: string, values: any) => {
    if (name === 'name' && values !== '' && values !== data?.name) {
      const responseData = await multiPersonGroup.isExistName(values);
      setCheckExistName(responseData?.data);
    }
  };
  const submit = () => {
    const request = {
      id: value.id,
      name: value.name,
      remark: value.remark,
    };
    multiPersonGroup
      .saveMultiPerson(request)
      .then((response) => {
        if (response?.data) {
          const message = data?.id
            ? t('multiPersonGroupUpdatedSuccessfully')
            : t('multiPersonGroupAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          setDisabled(false);
          dispatch(
            fetchMultiPersonGroup({
              pageNo: searchFilter?.pageIndex || 1,
              pageSize: searchFilter?.pageSize || 10,
            }),
          );
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  useEffect(() => {
    if (value?.name && checkExistName) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [value, checkExistName]);
  return (
    <div className="flex-row column" style={{ width: '38vw' }}>
      <div className="flex-row">
        <InputField
          id="name"
          name="name"
          label={t('groupName')}
          type="text"
          value={value?.name}
          onBlurFunction={onBlur}
          onChangeFunction={onChangeValue}
          errorStatus={value.name !== '' && !checkExistName}
          message={t('nameIsAlreadyExist')}
          required
        />
        <InputField
          id="remark"
          name="remark"
          label={t('remarks')}
          type="text"
          value={value?.remark}
          onChangeFunction={onChangeValue}
        />
      </div>
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={disabled}
      />
    </div>
  );
}

AddMultiPersonGroup.defaultProps = {
  data: {
    name: '',
    remark: '',
  },
  searchFilter: {
    pageIndex: '',
    pageSize: '',
  },
};

export default AddMultiPersonGroup;
