import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import auth from '../../../utils/api/auth';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import auxiliaryOutput from '../../../utils/api/Devices/auxiliaryOutput';
import { AddLevelModalType } from '../../../interface/accessLevel.interface';

function AddLevelModal({ closeModal, update, submitLevel }: AddLevelModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [levelDetails, setLevelDetails] = useState(
    update || {
      name: '',
      timeSegId: '',
      authAreaId: '',
    },
  );
  const [areaList, setAreaList] = useState([]);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const onChangeValue = (name: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getAuthAreaList = () => {
    auth
      .getAuthArea({ pageNo: 1 })
      .then((res) => {
        const authArea = res.data.data.list.map((area: any) => ({
          label: area.name,
          id: area.id,
        }));
        setAreaList(authArea);
        setLevelDetails((prev: any) => ({
          ...prev,
          area: authArea?.[0]?.id,
        }));
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  const submit = () => {
    submitLevel(levelDetails, update);
  };
  const fetchTimeZoneList = async () => {
    try {
      const response = await auxiliaryOutput.getTimeSegList();
      const formattedData = response.data.data.map(
        (item: { text: string; value: string | number }) => ({
          label: item.text,
          id: item.value,
        }),
      );

      setTimeZoneList(formattedData);
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(setNotificationError(error.message || t('errorFetchingData')));
      } else {
        dispatch(setNotificationError(t('errorFetchingData')));
      }
    }
  };

  useEffect(() => {
    getAuthAreaList();
    fetchTimeZoneList();
  }, []);

  return (
    <div className="add-level-modal flex-row column">
      <div className="form-row">
        <InputField
          id="name"
          name="name"
          label="Level Name"
          type="text"
          value={levelDetails.name}
          onChangeFunction={onChangeValue}
          required
        />
        <SelectBoxField
          id="timeSegId"
          name="timeSegId"
          label="Time Zone"
          value={levelDetails.timeSegId}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={timeZoneList}
        />
        <SelectBoxField
          id="authAreaId"
          name="authAreaId"
          label="Area"
          value={levelDetails.authAreaId}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={areaList}
          required
        />
      </div>

      <div className="form-buttons-right">
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          className="btn-primary"
          disabled={!levelDetails.name || !levelDetails.authAreaId}
        />
      </div>
    </div>
  );
}

export default AddLevelModal;
