import React, { useEffect, useState } from 'react';
// Use interface
import {
  ParameterInterface,
  ParameterReduxInterface,
} from '../../../../../interface/parameter.interface';
import { useAppSelector } from '../../../../../redux/store';

function DiskSpaceCleanup({ onChangeFunction }: ParameterInterface) {
  const { parameters }: ParameterReduxInterface = useAppSelector((state: any) => state.parameters);
  const [value, setValue] = useState(parameters);

  useEffect(() => {
    onChangeFunction(value);
  }, [value]);
  useEffect(() => {
    setValue({ ...parameters });
  }, [parameters]);
  return <div className="personnel-setting" />;
}

export default DiskSpaceCleanup;
