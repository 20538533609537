import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import InputField from '../../../shared-components/input-field/input-field';
import { AttentionIcon } from '../../../assets/svgicons/svgicon';

interface ModalTypeProps {
  values: any;
  changeValue: (name: string, data: any) => void;
}
function VideoLinkage({ values, changeValue }: ModalTypeProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState(
    values || {
      popUpVideo: [],
      record: [],
      popUpTime: '10',
      recordBeforeTime: '10',
      recordTime: '10',
      capture: [],
      capturePop: [],
      captureTime: '10',
    },
  );
  const onChangeValue = (name: string, data: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: data,
    }));
    changeValue(name, data);
  };

  useEffect(() => {
    if (!value.capture?.[0]) {
      setValue((prev: any) => ({
        ...prev,
        capturePop: [],
        monitorDisplayTime: '',
      }));
    }
  }, [value.capture]);
  return (
    <div className="flex-row column">
      <div className="flex-row">
        <CheckBoxInputField
          name="popUpVideo"
          data={[
            {
              label: t('popupVideo'),
              id: 'yes',
              value: 'yes',
            },
          ]}
          value={value?.popUpVideo}
          onChangeFunction={onChangeValue}
        />
        <CheckBoxInputField
          name="record"
          data={[
            {
              label: t('video'),
              id: 'video',
              value: 'video',
            },
          ]}
          value={value?.record}
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="row row-3">
        <InputField
          name="popUpTime"
          label={t('displayTime')}
          id="popUpTime"
          type="number"
          value={value.popUpTime}
          onChangeFunction={onChangeValue}
          validation
          message={t('s(5-50)')}
          disabled={!value?.popUpVideo || value?.popUpVideo?.length === 0}
        />
        <InputField
          name="recordBeforeTime"
          label={t('beforeEvent')}
          id="recordBeforeTime"
          type="number"
          value={value.recordBeforeTime}
          onChangeFunction={onChangeValue}
          validation
          message={t('s(10-80)')}
          disabled={!value?.record || value?.record?.length === 0}
        />
        <InputField
          name="recordTime"
          label={t('afterEvent')}
          id="recordTime"
          type="number"
          value={value.recordTime}
          onChangeFunction={onChangeValue}
          validation
          message={t('s(10-80)')}
          disabled={!value?.record || value?.record?.length === 0}
        />
      </div>
      <div className="flex-row">
        <CheckBoxInputField
          name="capture"
          data={[
            {
              label: t('capture'),
              id: 'yes',
              value: 'yes',
            },
          ]}
          value={value?.capture}
          onChangeFunction={onChangeValue}
        />
        <CheckBoxInputField
          name="capturePop"
          data={[
            {
              label: t('inTheMonitoringPageImmediatelyPopUp'),
              id: 'yes',
              value: 'yes',
              disabled: value?.capture?.length === 0 ? 'disabled' : '',
            },
          ]}
          value={value?.capturePop}
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="row row-3">
        <InputField
          name="captureTime"
          label={t('displayTime')}
          id="captureTime"
          type="number"
          value={value.captureTime}
          onChangeFunction={onChangeValue}
          validation
          message={t('s(10-60)')}
          disabled={value?.capturePop?.length === 0}
        />
      </div>
      <div className="text-danger flex-row center">
        <AttentionIcon />
        {t('videoLinkageAttentionMessage')}
      </div>
    </div>
  );
}

export default VideoLinkage;
