import React, { useState, useEffect } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';

// Use shared components
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';

// Import the API module
import PositionApis from '../../../utils/api/position';

// Use interface
import { ImportDataInterFace } from '../../../interface/common.interface';

// Use helper functions
import { fileDownload, getCurrentDateTimeWithTimezone } from '../../../utils/helpers';

// Use Redux functions
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

function ImportTemplateDownload({ closeModal }: ImportDataInterFace) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [disableButton, setDisableButton] = useState(true);
  const [importValue, setImportValue] = useState({
    fileType: 'excel',
  });

  const onChangeValue = (name: string, value: any) => {
    setImportValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = () => {
    setDisableButton(true);
    PositionApis.downloadImportFile({})
      .then((response: any) => response.blob())
      .then((data) => {
        const currentDateTime = getCurrentDateTimeWithTimezone(); // Use helper function
        fileDownload(data, `Dismission import template_${currentDateTime}.xls`);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message || 'An error occurred during file download',
            status: error.response?.status || 500, // Use error.response?.status for better error handling
          }),
        );
        setDisableButton(false); // Re-enable the button in case of an error
      });
  };

  useEffect(() => {
    let i = 0;

    if (importValue.fileType === '') {
      i += 1;
    }
    if (i > 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [importValue]);
  return (
    <div className="import-data">
      <div className="row row-1">
        <div className="col">
          <SelectBoxField
            name="fileType"
            label={t('fileType')}
            id="fileType"
            value={importValue.fileType}
            required
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              {
                label: t('excel'),
                id: 'excel',
              },
            ]}
            className=""
          />
        </div>
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={submit}
            title={t('download')}
            className="btn-primary btn-bold"
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
}

export default ImportTemplateDownload;
