import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
// import { EditDoorInterface } from '../../../interface/device.interface';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import door from '../../../utils/api/door';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import auxiliaryInput from '../../../utils/api/Devices/auxiliaryInput';
import { fetchDoorList, fetchVerifyMode } from '../../../redux/slices/DoorSlice';

function EditDoor({ closeModal, data }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [editDoorValue, setEditDoorValue] = useState(data);
  const [disable, setDisable] = useState(false);
  const [dropdownData, setDropdownData] = useState([]);
  const { verificationModes } = useAppSelector((state) => state.doors);
  const onChangeValue = (name: string, value: any) => {
    setEditDoorValue((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  const submit = () => {
    const requestData = {
      ...editDoorValue,
      isDisableAudio: editDoorValue.isDisableAudio?.length > 0,
    };
    door
      .doorSave(requestData)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('doorSavedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(fetchDoorList({ pageNo: 1, pageSize: 10 }));
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  useEffect(() => {
    if (
      editDoorValue?.name &&
      editDoorValue?.lockDelay &&
      editDoorValue?.sensorDelay &&
      editDoorValue?.activeTimeSegId
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [editDoorValue]);
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await auxiliaryInput.getTimeSegList();
        const formattedData = response.data.data.map(
          (item: { text: string; value: string | number }) => ({
            label: item.text,
            id: item.value,
          }),
        );

        setDropdownData(formattedData);
      } catch (error: unknown) {
        if (error instanceof Error) {
          dispatch(setNotificationError(error.message || t('errorFetchingData')));
        } else {
          dispatch(setNotificationError(t('errorFetchingData')));
        }
      }
    };

    fetchDropdownData();
    dispatch(fetchVerifyMode(data?.deviceId));
  }, []);

  return (
    <div className="edit-door">
      <div className="grid-row">
        <div className="col">
          <InputField
            name="deviceAlias"
            label={t('deviceName')}
            id="deviceAlias"
            type="text"
            value={editDoorValue.deviceAlias}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            disabled
            required
          />
        </div>
        <div className="col">
          <InputField
            name="doorNo"
            label={t('doorNumber')}
            id="doorNo"
            type="text"
            value={editDoorValue.doorNo}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            disabled
            required
          />
        </div>
        <div className="col">
          <InputField
            name="actionInterval"
            label={t('operateInterval')}
            id="actionInterval"
            type="text"
            value={editDoorValue.actionInterval}
            disabled
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            required
            validation
            message={t('second(0-254)')}
          />
        </div>
        <div className="col">
          <InputField
            name="inApbDuration"
            label={t('antiPassbackDurationOfEntrance')}
            id="inApbDuration"
            type="text"
            value={editDoorValue?.inApbDuration}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            disabled
            required
            validation
            message={t('minute(0-120)')}
          />
        </div>
        <div className="col">
          <InputField
            name="name"
            label={t('doorName')}
            id="name"
            type="text"
            value={editDoorValue?.name}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            required
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="hostStatus"
            label={t('hostAccessStatus')}
            id="hostStatus"
            value={editDoorValue.hostStatus?.toString()}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('entry'), id: '1' },
              { label: t('out'), id: '0' },
            ]}
            className=""
          />
        </div>
        <div className="col">
          <InputField
            name="lockDelay"
            label={t('lockOpenDuration')}
            id="lockDelay"
            type="text"
            value={editDoorValue?.lockDelay}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            required
            validation
            message={t('second(1-254)')}
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="doorSensorStatus"
            label={t('doorSensorType')}
            id="doorSensorStatus"
            value={editDoorValue.doorSensorStatus}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('none'), id: 0 },
              { label: t('normallyOpen'), id: 1 },
              { label: t('normallyClosed'), id: 2 },
            ]}
            className=""
            required
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="verifyMode"
            label={t('verificationMode')}
            id="verifyMode"
            value={String(editDoorValue?.verifyMode)}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={verificationModes}
            className=""
            required
          />
        </div>
        <div className="col">
          <InputField
            name="forcePwd"
            label={t('duressPassword')}
            id="forcePwd"
            type="password"
            value={editDoorValue?.forcePwd}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            validation
            message={t('maximum6BitInteger')}
          />
        </div>
        <div className="col">
          <InputField
            name="sensorDelay"
            label={t('doorSensorDelay')}
            id="sensorDelay"
            type="text"
            value={editDoorValue?.sensorDelay}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            required
          />
        </div>
        <div className="col">
          <InputField
            name="supperPwd"
            label={t('emergencyPassword')}
            id="supperPwd"
            type="password"
            value={editDoorValue?.supperPwd}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            validation
            message={t('8BitInteger')}
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="applyTo"
            label={t('settingsCopy')}
            id="applyTo"
            value={editDoorValue.applyTo}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[{ label: t('allDoorsAreStandAlone'), id: 'none' }]}
            className=""
            required
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="activeTimeSegId"
            label={t('activeTimeZone')}
            id="activeTimeSegId"
            value={editDoorValue.activeTimeSegId}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={dropdownData}
            className=""
            required
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="passModeTimeSegId"
            label={t('passageModeTimeZone')}
            id="passModeTimeSegId"
            value={editDoorValue?.passModeTimeSegId}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={dropdownData}
            className=""
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="slaveStatus"
            label={t('slaveOutOfState')}
            id="slaveStatus"
            value={editDoorValue.slaveStatus}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('entry'), id: '1' },
              { label: t('out'), id: '0' },
            ]}
            className=""
          />
        </div>
      </div>
      <div className="switch-wrap">
        <CheckBoxInputField
          name="isDisableAudio"
          data={[
            {
              label: t('disableAlarmSounds'),
              id: 'yes',
              value: 'yes',
            },
          ]}
          value={editDoorValue.isDisableAudio}
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default btn-bold"
        />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          disabled={disable}
          className="btn-primary btn-bold"
        />
      </div>
    </div>
  );
}

export default EditDoor;
