import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

// Use shared components
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
// Import slice actions
import {
  fetchDbMgt,
  setSearchFilter,
} from '../../../../redux/slices/system-management/DataBaseMgtSlices';
// import ExportData from './components/export-data';
import { FilterProps } from '../../../../interface/common.interface';
import BackupImmediate from './components/BackupImmediate';
import BackupSchedule from './components/BackupSchedule';
import FTPServerSettings from './components/FTPServerSettings';
import { CheckCircleIcon, CloseCircleIcon } from '../../../../assets/svgicons/svgicon';

function DatabaseManagement() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { dbList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.databaseManagement,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: 'hi',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState([]);

  const columns = [
    {
      accessorKey: 'username',
      header: t('operator'),
    },
    {
      accessorKey: 'startTime',
      header: t('startTime'),
    },
    {
      accessorKey: 'dbVersion',
      header: t('databaseVersion'),
    },
    {
      accessorKey: 'isImme',
      header: t('backupImmediately'),
      Cell: ({ row }: any) => {
        const value = row.original?.isImme ? (
          <CheckCircleIcon color="#48d726de" />
        ) : (
          <CloseCircleIcon color="#f00" />
        );
        return value;
      },
    },
    {
      accessorKey: 'backupResult',
      header: t('backupStatus'),
      Cell: ({ row }: any) => {
        let result;
        if (row.original.backupResult === 1) {
          result = <span style={{ color: '#00A76F' }}>Enable</span>;
        } else {
          result = <span className="text-danger">Disable</span>;
        }
        return result;
      },
    },
    {
      accessorKey: 'backupPath',
      header: t('backupPath'),
    },
    {
      accessorKey: 'fileType',
      header: t('fileType'),
    },
  ];

  const fetchData = async (params: FilterProps) => {
    if (status === 'success') {
      dispatch(fetchDbMgt({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const moreActionButton = [
    {
      title: t('export'),
      clickFunction: (): void => {
        setModalView({
          title: t('export'),
          content: <>hi</>,
        });
        setModalOpen(true);
      },
    },
    {
      title: t('backupImmediately'),
      clickFunction: (): void => {
        setModalView({
          title: t('backupImmediately'),
          content: <BackupImmediate closeModal={() => setModalOpen(false)} />,
        });
        setModalOpen(true);
      },
    },
    {
      title: t('backupSchedule'),
      clickFunction: (): void => {
        setModalView({
          title: t('backupSchedule'),
          content: <BackupSchedule closeModal={() => setModalOpen(false)} />,
        });
        setModalOpen(true);
      },
    },
    {
      title: t('FtpServerSettings'),
      clickFunction: (): void => {
        setModalView({
          title: t('FtpServerSettings'),
          content: <FTPServerSettings closeModal={() => setModalOpen(false)} />,
        });
        setModalOpen(true);
      },
    },
  ];

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('system')}
        broadCram={[
          {
            title: t('systemManagement'),
          },
          {
            title: t('databaseManagement'),
          },
        ]}
      />
      <CardBox
        header=""
        title={t('listOfOperator')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
          />
        }>
        <DataTable
          actionButtonStatus={false}
          columns={columns}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          checkBoxActive
          isFetching={status === 'loading'}
          values={dbList}
          fixedColumn={['eventTime']}
          totalRecords={totalDataCount}
          height={411}
          enablePagination
        />
      </CardBox>
    </div>
  );
}

export default DatabaseManagement;
