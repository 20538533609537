import { useState, useEffect, useCallback, useRef } from 'react';

/**
 * @interface UseWebSocketProps
 * @property {string} url - WebSocket server URL
 * @property {string} type - The type of message to send (e.g., "accRTMonitor/getDevState")
 * @property {Object} params - The parameters to send with the message
 * @property {function} onMessage - Callback function to handle received messages
 */

interface UseWebSocketProps {
  type: string;
  params: any;
  onMessage: (message: any) => void;
  handleGetEventData?: (message: any) => void;
}

/**
 * Custom hook for WebSocket communication using the native WebSocket API
 * @param {UseWebSocketProps} props - The WebSocket configuration properties
 * @returns {{ initializeWebSocket: () => void }} Object containing the initialization function
 */

const useWebSocket = ({
  type,
  params,
  onMessage,
  handleGetEventData,
}: UseWebSocketProps): {
  initializeWebSocket: (customParams?: any) => void;
  socketOpen: boolean;
} => {
  const [socket, setSocket] = useState<WebSocket | null>(null); // WebSocket instance
  const [socketOpen, setSocketOpen] = useState(true); // Track WebSocket open state
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  // websocket url
  const WEBSOCKET_URL: any = process.env.REACT_APP_WEBSOCKET_URL;
  /**
   * Initializes and configures the WebSocket connection
   * @function initializeWebSocket
   * @memberof useWebSocket
   * @inner
   * @description Creates a WebSocket connection, sends the message, and handles responses
   * @returns {void}
   * @throws {Error} When WebSocket connection fails
   */
  const initializeWebSocket = useCallback(
    (customParams?: any) => {
      const ws = new WebSocket(WEBSOCKET_URL);

      ws.onopen = () => {
        setSocketOpen(true); // Set WebSocket open state
        console.log('WebSocket connection established');

        // Merge default params with custom params if provided
        const finalParams = customParams ? { ...params, ...customParams } : params;
        // Send the message with type and params
        const message = {
          type,
          params: JSON.stringify(finalParams),
        };
        ws.send(JSON.stringify(message));
        // Send the message every 10 seconds
        intervalRef.current = setInterval(() => {
          ws.send(
            JSON.stringify({
              type,
              params: JSON.stringify(finalParams), // Send the same params
            }),
          );
        }, 15000); // 10 seconds interval
      };

      ws.onmessage = (event) => {
        if (event.data.startsWith('rtDevState')) {
          const responseData = event.data.replace('rtDevState', '');
          if (onMessage) onMessage(JSON.parse(responseData)); // Call the onMessage callback with the received message
        }
        if (event.data.startsWith('rtEventData: ')) {
          const responseData = event.data.replace('rtEventData: ', '');
          if (handleGetEventData) handleGetEventData(JSON.parse(responseData)); // Call the onMessage callback with the received message
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.onclose = () => {
        // Clear interval when WebSocket is closed
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        console.log('WebSocket connection closed');
        setSocketOpen(false); // Set WebSocket closed state
      };

      setSocket(ws); // Set the WebSocket instance to state
    },
    [type, params, onMessage],
  );

  useEffect(() => {
    return () => {
      socket?.close(); // Cleanup the WebSocket connection when the component unmounts
    };
  }, [socket]);

  return { initializeWebSocket, socketOpen };
};

export default useWebSocket;
