import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import AddSelectedDevice from './AddSelectedDevice';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import interlock from '../../../utils/api/AccessRule/interlock';
import { fetchInterlock } from '../../../redux/slices/InterlockSlices';
import { DeviceProps } from '../../../interface/accessByDepartment.interface';

interface AddInterlockProps {
  closeModal: () => void;
  data?: FirstPersonDevice;
  searchFilter?: {
    pageIndex: number;
    pageSize: number;
  };
}

interface FirstPersonDevice {
  id?: string;
  name?: string;
  deviceId: string;
  deviceName?: string;
  interlockRuleShow: string;
  noOfDoors?: number;
}

interface ProtocolProps {
  label: string;
  id: string;
  value?: string;
}

function AddInterlock({ data, closeModal, searchFilter }: AddInterlockProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [devices, setDevices] = useState<DeviceProps[]>();
  const [interlocks, setInterlocks] = useState('');

  const [selectedDevices, setSelectedDevices] = useState<DeviceProps[]>([]);
  const [interlockRule, setInterlockRule] = useState<ProtocolProps[]>([]);
  const [levelDetails, setLevelDetails] = useState<FirstPersonDevice>(
    data || {
      id: '',
      name: '',
      deviceId: '',
      deviceName: '',
      noOfDoors: 0,
      interlockRuleShow: '',
    },
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('accessinterlock'),
    content: <>hi</>,
  });

  const isEditMode = Object.keys(data || {}).length > 0;

  const onTableSwap = (newAvailableData: DeviceProps[], newSelectedData: DeviceProps[]) => {
    setDevices(newAvailableData);
    setSelectedDevices(newSelectedData);
  };

  const tableColumns = [
    { label: t('deviceName'), id: 'deviceName' },
    { label: t('serialNumber'), id: 'deviceSn' },
  ];

  const onChangeValue = (name: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const changeSearch = (event: any) => {
    setInterlocks(event.target.value);
  };

  const getProtocols = (id: string) => {
    if (!id) return;

    interlock
      .getRule(id)
      .then((response) => {
        const interlockList: ProtocolProps[] = response.data.data.map((item: any) => ({
          label: item.text,
          id: item.value,
        }));
        setInterlockRule(interlockList);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const getSelectedDevice = (selected: DeviceProps[]) => {
    setLevelDetails((prev) => ({
      ...prev,
      deviceId: selected[0].id,
      deviceName: selected[0].deviceName,
    }));
    getProtocols(selected[0].id);
  };

  const addDoor = () => {
    if (isEditMode) return;
    setModalOpen(true);
    setModalView({
      title: t('selectDevice'),
      content: (
        <AddSelectedDevice
          searchValue={interlocks}
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          tableData={devices}
          selectedTableData={selectedDevices}
          getSelected={getSelectedDevice}
          onTableSwap={onTableSwap}
          closeModal={() => setModalOpen(false)}
        />
      ),
    });
  };

  const submit = () => {
    const request = {
      id: levelDetails?.id,
      alias: levelDetails?.name,
      deviceId: levelDetails?.deviceId,
      deviceAlias: levelDetails?.deviceName,
      interlockRule: levelDetails?.interlockRuleShow,
    };
    interlock
      ?.saveInterlock(request)
      .then((response) => {
        if (response?.data?.success) {
          const message = isEditMode
            ? t('interlockUpdatedSuccessfully')
            : t('interlockAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
          dispatch(
            fetchInterlock({
              pageNo: searchFilter?.pageIndex || 1,
              pageSize: searchFilter?.pageSize || 10,
            }),
          );
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    if (data?.deviceId) {
      setLevelDetails((prev) => ({
        ...prev,
        deviceId: data.deviceId,
        deviceName: data.deviceName,
      }));
      getProtocols(data.deviceId);
    }
  }, [data]);

  return (
    <div className="interlock-modal">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="form-row">
        <InputField
          id="deviceName"
          name="deviceName"
          label={t('deviceName')}
          type="text"
          value={levelDetails.deviceName}
          onChangeFunction={(name, value) => onChangeValue(name, value)}
          disabled={isEditMode}
          required
        />
        <SelectBoxField
          id="interlockRuleShow"
          name="interlockRuleShow"
          label={t('antiPassbackRule')}
          value={levelDetails.interlockRuleShow}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={interlockRule}
          required
        />
      </div>

      <div className="select-device-wrapper">
        {!levelDetails.deviceId && (
          <Button onClickFunction={addDoor} title={t('selectDevice')} className="btn-default" />
        )}
        {levelDetails.deviceId && (
          <div
            className={`device-name ${isEditMode ? 'disabled' : ''}`}
            onClick={addDoor}
            role="presentation">
            <h4 className="label">{levelDetails.deviceName}</h4>
          </div>
        )}
      </div>

      <div className="form-buttons-right">
        {!isEditMode && (
          <Button onClickFunction={submit} title={t('save&New')} className="btn-primary" />
        )}
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button onClickFunction={submit} title={t('ok')} className="btn-primary btn-bold" />
      </div>
    </div>
  );
}

AddInterlock.defaultProps = {
  data: {},
  searchFilter: {},
};

export default AddInterlock;
